import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from 'redux/reducers'
import rootSaga from 'redux/sagas'
import thunk from 'redux-thunk'
import { encryptTransform } from 'redux-persist-transform-encrypt'

export const history = createBrowserHistory()

const asyncEncryptor = encryptTransform({
  secretKey: process.env.REACT_APP_SECRET_KEY
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'settings', 'roommate', 'community', 'home'],
  transforms: [asyncEncryptor]
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const configureStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware()

  const middleware = applyMiddleware(
    thunk,
    sagaMiddleware,
    routerMiddleware(history)
  )

  const store = createStore(
    persistedReducer, // new root reducer with router state
    preloadedState,
    compose(
      middleware,
      process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    ))

  if (process.env.NODE_ENV === 'development' && module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextCreateRootReducer = require('./reducers').default
      store.replaceReducer(nextCreateRootReducer(history))
    })
  }

  sagaMiddleware.run(rootSaga)

  const persistor = persistStore(store)

  return { store, persistor }
}

export default configureStore
