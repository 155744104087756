import { createContext, useCallback } from 'react'
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant'

import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection'
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed'
import useLocalTracks from './useLocalTracks/useLocalTracks'
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange'
import useRoom from './useRoom/useRoom'

export const VideoContext = createContext(null)

export function VideoProvider({ options, children, onError = () => {} }) {
  const onErrorCallback = useCallback(
    error => {
      console.log(`ERROR: ${error.message}`, error)
      onError(error)
    },
    [onError]
  )

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options)

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack
  )
  useHandleTrackPublicationFailed(room, onError)
  useRestartAudioTrackOnDeviceChange(localTracks)

  // const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video')

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
    </VideoContext.Provider>
  )
}
