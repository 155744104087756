import { Link } from 'components/Link'

import { MAIN_NAV_ID, PAGE_CONTENT_ID } from 'constants/strings'
import i18n from 'services/i18n'

import s from './AccessibilityJumpNavigation.module.scss'

export const AccessibilityJumpNavigation = () => {

  const handleClick = (id: string) =>
    () => document.getElementById(id)?.focus()

  return (
    <>
      <Link className={s.link} to={`#${MAIN_NAV_ID}`} onClick={handleClick(MAIN_NAV_ID)}>{i18n.t('default.jumpNavigation')}</Link>
      <Link className={s.link} to={`#${PAGE_CONTENT_ID}`} onClick={handleClick(PAGE_CONTENT_ID)}>{i18n.t('default.jumpContent')}</Link>
    </>
  )
};
