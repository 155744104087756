import { all, takeLatest, takeEvery } from 'redux-saga/effects'

// Home
import { homeTypes } from '../actions/HomeActions'

// Home
import {
  appleAuth,
  googleLogin,
  facebookLogin,
  getLoginMethod,
  proceedSignInSuccess,
  getInvitationPreflight,
  proceedInvitationLoggedIn
} from './HomeSagas'

// CONTENT
import { contentTypes } from '../actions/ContentActions'

// CONTENT
import {
  getMajorsContent,
  getHousingContent,
  getVerifyInfoContent
} from './ContentSagas'

// PROFILE
import { profileTypes } from '../actions/ProfileActions'

import {
  getProfile,
  getAboutMe,
  setAboutMe,
  getMajorsOptions,
  setMajorsOptions,
  getHousingOptions,
  setHousingOptions,
  getSocialAccounts,
  setSocialAccounts,
  getProfileOverview,
  uploadProfileImage
} from './ProfileSagas'

// USER
import { userTypes } from '../actions/UserActions'

// USER
import {
  deleteAccount,
  setVerifyInfo
} from './UserSagas'

// ROOM
import { roomTypes } from '../actions/RoomActions'

// ROOM
import {
  getRoomContent,
  leaveRoom,
  setRoommate,
  setKnown
} from './RoomSagas'

// SWIPE
import { swipeTypes } from '../actions/SwipeActions'

// SWIPE
import {
  getSwipeData,
  skipRoommate,
  likeRoommate,
  getSearchValues,
  setSearchValues
} from './SwipeSagas'

// ROOMMATE
import { roommateTypes } from '../actions/RoommateActions'

// ROOMMATE
import {
  denyRequest,
  sendRequest,
  cancelRequest,
  acceptRequest,
  getRoommateProfile,
  getRoommatesProfile
} from './RoommateSagas'

// CHAT
import { chatTypes } from '../actions/ChatActions'

// CHAT
import {
  markAsRead,
  logoutChat,
  getMessages,
  createMessage,
  removeMessage,
  refreshToken,
  checkUserExist,
  initializeChat,
  switchBlockUser,
  getConversations,
  handleSomeoneIsCalling,
  initializeChatListeners
} from './ChatSagas'

// COMMUNITY
import { communityTypes } from '../actions/CommunityActions'

// COMMUNITY
import {
  getCommunityContent,
  getCommunityThreads,
  getCommunityPosts,
  createCommunityThread,
  createCommunityPost
} from './CommunitySagas'

function * rootSaga () {
  yield all([
    // HOME
    takeLatest(homeTypes.FACEBOOK_LOGIN_ATTEMPT, facebookLogin),
    takeLatest(homeTypes.GOOGLE_LOGIN_ATTEMPT, googleLogin),
    takeLatest(homeTypes.APPLE_AUTH_ATTEMPT, appleAuth),
    takeLatest(homeTypes.PROCEED_INVITATION_LOGGED_IN_ATTEMPT, proceedInvitationLoggedIn),
    takeLatest(homeTypes.GET_INVITATION_PREFLIGHT_ATTEMPT, getInvitationPreflight),
    takeLatest(homeTypes.PROCEED_SIGN_IN_ATTEMPT, proceedSignInSuccess),
    takeLatest(homeTypes.GET_LOGIN_METHOD_ATTEMPT, getLoginMethod),

    // CONTENT
    takeLatest(contentTypes.GET_MAJORS_CONTENT_ATTEMPT, getMajorsContent),
    takeLatest(contentTypes.GET_HOUSING_CONTENT_ATTEMPT, getHousingContent),
    takeLatest(contentTypes.GET_VERIFY_INFO_CONTENT_ATTEMPT, getVerifyInfoContent),

    // PROFILE
    takeLatest(profileTypes.GET_PROFILE_ATTEMPT, getProfile),
    takeLatest(profileTypes.GET_PROFILE_OVERVIEW_ATTEMPT, getProfileOverview),
    takeLatest(profileTypes.GET_MAJORS_OPTIONS_ATTEMPT, getMajorsOptions),
    takeLatest(profileTypes.SET_MAJORS_OPTIONS_ATTEMPT, setMajorsOptions),
    takeLatest(profileTypes.GET_HOUSING_OPTIONS_ATTEMPT, getHousingOptions),
    takeLatest(profileTypes.SET_HOUSING_OPTIONS_ATTEMPT, setHousingOptions),
    takeLatest(profileTypes.GET_SOCIAL_ACCOUNTS_ATTEMPT, getSocialAccounts),
    takeLatest(profileTypes.SET_SOCIAL_ACCOUNTS_ATTEMPT, setSocialAccounts),
    takeLatest(profileTypes.GET_ABOUT_ME_ATTEMPT, getAboutMe),
    takeLatest(profileTypes.SET_ABOUT_ME_ATTEMPT, setAboutMe),
    takeLatest(profileTypes.UPLOAD_PROFILE_IMAGE_ATTEMPT, uploadProfileImage),

    // USER
    takeLatest(userTypes.SET_USER_VERIFY_INFO_ATTEMPT, setVerifyInfo),
    takeLatest(userTypes.DELETE_ACCOUNT_ATTEMPT, deleteAccount),

    // ROOM
    takeLatest(roomTypes.GET_ROOM_CONTENT_ATTEMPT, getRoomContent),
    takeLatest(roomTypes.LEAVE_ROOM_ATTEMPT, leaveRoom),
    takeLatest(roomTypes.SET_ROOMMATE_ATTEMPT, setRoommate),
    takeLatest(roomTypes.SET_KNOWN_ATTEMPT, setKnown),

    // SWIPE
    takeEvery(swipeTypes.GET_SWIPE_DATA_ATTEMPT, getSwipeData),
    takeEvery(swipeTypes.SKIP_ROOMMATE_ATTEMPT, skipRoommate),
    takeEvery(swipeTypes.LIKE_ROOMMATE_ATTEMPT, likeRoommate),
    takeLatest(swipeTypes.GET_SEARCH_VALUES_ATTEMPT, getSearchValues),
    takeLatest(swipeTypes.SET_SEARCH_VALUES_ATTEMPT, setSearchValues),

    // ROOMMATE
    takeEvery(roommateTypes.GET_PROFILE_ATTEMPT, getRoommateProfile),
    takeEvery(roommateTypes.DENY_REQUEST_ATTEMPT, denyRequest),
    takeEvery(roommateTypes.ACCEPT_REQUEST_ATTEMPT, acceptRequest),
    takeEvery(roommateTypes.CANCEL_REQUEST_ATTEMPT, cancelRequest),
    takeEvery(roommateTypes.SEND_REQUEST_ATTEMPT, sendRequest),
    takeEvery(roommateTypes.GET_PROFILES_ATTEMPT, getRoommatesProfile),

    // CHAT
    takeEvery(chatTypes.REMOVE_MESSAGE_ATTEMPT, removeMessage),
    takeEvery(chatTypes.MARK_AS_READ_ATTEMPT, markAsRead),
    takeLatest(chatTypes.INITIALIZE_CHAT_ATTEMPT, initializeChat),
    takeEvery(chatTypes.LOGOUT_CHAT_ATTEMPT, logoutChat),
    takeLatest(chatTypes.GET_CONVERSATIONS_ATTEMPT, getConversations),
    takeEvery(chatTypes.CREATE_MESSAGE_ATTEMPT, createMessage),
    takeLatest(chatTypes.GET_MESSAGES_ATTEMPT, getMessages),
    takeEvery(chatTypes.CHECK_USER_EXIST_ATTEMPT, checkUserExist),
    takeEvery(chatTypes.SWITCH_BLOCK_USER_ATTEMPT, switchBlockUser),
    takeLatest(chatTypes.INITIALIZE_CHAT_LISTENERS_ATTEMPT, initializeChatListeners),
    takeLatest(chatTypes.REFRESH_TOKEN_ATTEMPT, refreshToken),
    takeLatest(chatTypes.HANDLE_SOMEONE_IS_CALLING_ATTEMPT, handleSomeoneIsCalling),

    // COMMUNITY
    takeLatest(communityTypes.GET_CONTENT_ATTEMPT, getCommunityContent),
    takeLatest(communityTypes.GET_THREADS_ATTEMPT, getCommunityThreads),
    takeEvery(communityTypes.GET_POSTS_ATTEMPT, getCommunityPosts),
    takeEvery(communityTypes.CREATE_THREAD_ATTEMPT, createCommunityThread),
    takeEvery(communityTypes.CREATE_POST_ATTEMPT, createCommunityPost)
  ])
}

export default rootSaga
