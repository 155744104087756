import prepend from 'ramda/es/prepend'
import concat from 'ramda/es/concat'
import inc from 'ramda/es/inc'
import reject from 'ramda/es/reject'
import propEq from 'ramda/es/propEq'
import path from 'ramda/es/path'
import mergeDeepRight from 'ramda/es/mergeDeepRight'
import { createReducer } from 'services/reduxHelpers'
import { chatTypes } from 'redux/actions/ChatActions'
import { roommateTypes } from 'redux/actions/RoommateActions'

/* ------------- Initial State ------------- */

export const initialState = {
  isLoading: true,
  unreadCount: {},
  sids: {},
  conversations: {},
  conversationsIds: [],
  loadingStates: {},
  messages: {},
  initialConversations: {},
  sendingStates: {},
  successStates: {},
  initializationError: false,
  token: null
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [chatTypes.INITIALIZE_CHAT_ATTEMPT]: () => initialState,
  [chatTypes.INITIALIZE_CHAT_SUCCESS]: (_, payload) => ({
    initializationError: false,
    isLoading: !payload?.data?.setLoadingFalse,
  }),
  [chatTypes.INITIALIZE_CHAT_FAILURE]: () => ({
    initializationError: true,
    isLoading: false
  }),

  [chatTypes.GET_CONVERSATIONS_ATTEMPT]: ({ conversationsIds }) => ({
    isLoading: !conversationsIds.length
  }),
  [chatTypes.GET_CONVERSATIONS_SUCCESS]: () => ({
    isLoading: false
  }),
  [chatTypes.GET_CONVERSATIONS_FAILURE]: () => ({
    isLoading: false
  }),

  [chatTypes.GET_MESSAGES_ATTEMPT]: (
    { loadingStates },
    { data: { conversationId } }
  ) => ({
    loadingStates: {
      ...loadingStates,
      [conversationId]: true
    }
  }),
  [chatTypes.GET_MESSAGES_SUCCESS]: (
    state,
    { data: { conversationId, messages, hasPrevPage } }
  ) => ({
    loadingStates: mergeDeepRight(state.loadingStates, { [conversationId]: false }),
    conversations: mergeDeepRight(state.conversations, { [conversationId]: { hasPrevPage } }),
    messages: mergeDeepRight(state.messages, { [conversationId]: concat(messages, state.messages[conversationId]) })
  }),
  [chatTypes.GET_MESSAGES_FAILURE]: (
    { loadingStates },
    { data: { conversationId } }
  ) => ({
    loadingStates: {
      ...loadingStates,
      [conversationId]: false
    }
  }),

  [chatTypes.CHANNEL_ADDED_ATTEMPT]: (state, { data: { conversation, unreadCount } }) => ({
    sids: mergeDeepRight(state.sids, { [conversation.chatId]: conversation.sid }),
    unreadCount: mergeDeepRight(state.unreadCount, { [conversation.sid]: unreadCount }),
    conversations: mergeDeepRight(state.conversations, { [conversation.sid]: conversation }),
    conversationsIds: prepend(conversation.sid, state.conversationsIds)
  }),

  [chatTypes.MESSAGES_ADDED_ATTEMPT]: (state, { data: { sid, messages, inConversation } }) => ({
    unreadCount: mergeDeepRight(state.unreadCount, { [sid]: inConversation ? 0 : inc(state.unreadCount[sid] || 0) }),
    messages: mergeDeepRight(state.messages, { [sid]: concat(state.messages[sid] || [], messages) })
  }),

  [chatTypes.MESSAGE_REMOVED_ATTEMPT]: (state, { data: { sid, messageId } }) => ({
    messages: mergeDeepRight(state.messages, { [sid]: reject(propEq('_id', messageId), state.messages[sid]) })
  }),

  [roommateTypes.GET_PROFILE_SUCCESS]: (state, { data }) => {
    const { conversationId } = data

    if (!conversationId) {
      return null
    }

    return ({
      conversations: mergeDeepRight(
        state.conversations,
        {
          [conversationId]: mergeDeepRight(
            state.conversations[conversationId],
            {
              name: `${data.first_name} ${data.last_name}`,
              avatar: path(['profile_picture_urls', 'large'], data)
            }
          )
        }
      )
    })
  },

  [chatTypes.CREATE_MESSAGE_ATTEMPT]: ({ sendingStates, successStates }, { data: { chatId } }) => ({
    sendingStates: {
      ...sendingStates,
      [chatId]: true
    },
    successStates: {
      ...successStates,
      [chatId]: false
    }
  }),
  [chatTypes.CREATE_MESSAGE_SUCCESS]: ({ sendingStates, successStates }, { data: { chatId } }) => ({
    sendingStates: {
      ...sendingStates,
      [chatId]: false
    },
    successStates: {
      ...successStates,
      [chatId]: true
    }
  }),
  [chatTypes.CREATE_MESSAGE_FAILURE]: ({ successStates, sendingStates }, { data: { chatId } }) => ({
    sendingStates: {
      ...sendingStates,
      [chatId]: false
    },
    successStates: {
      ...successStates,
      [chatId]: false
    }
  }),

  [chatTypes.MARK_AS_READ_SUCCESS]: ({ unreadCount }, { data: { sid } }) => ({
    unreadCount: mergeDeepRight(unreadCount, { [sid]: 0 })
  }),

  [chatTypes.CHANNEL_UPDATED_ATTEMPT]: (state, { data: { conversation } }) => ({
    conversations: mergeDeepRight(state.conversations, { [conversation.sid]: conversation })
  }),

  [chatTypes.SET_TOKEN_ATTEMPT]: (state, { data }) => ({
    token: data
  })
})
