import { createReducer } from 'services/reduxHelpers'
import { swipeTypes } from '../actions/SwipeActions'

/* ------------- Initial State ------------- */

export const initialState = {
  queued: {
    cards: [],
    isLoading: false,
    page: 0
  },
  skipped: {
    cards: [],
    isLoading: false,
    isFinished: false,
    page: 0
  },
  isOneWayInterested: {
    cards: [],
    isLoading: false,
    isFinished: false,
    page: 0
  },
  isMutualInterested: {
    cards: [],
    isLoading: false,
    isFinished: false,
    page: 0
  },
  search: {
    majors: [],
    majorOptions: [],
    housing: [],
    housingOptions: [],
    registrationStatus: "REGISTERED_ONLY",
    firstName: null,
    lastName: null,
    isLoading: false,
    groupSizeFilter: 0,
    isRequestLoading: false,
    isSearchFetched: false,
    enableRoomSizeFilter: false
  },
  skipTickBadge: 0,
  likeTickBadge: 0,
  error: null,
  pendingCards: []
}

/* ------------- Hookup Reducers To Types ------------- */

const onActionAttempt = (state, { data: { status, item: { membership_id: userId, demo } } }) =>
  status === 'queued'
    ? ({
      [status]: {
        ...state[status],
        cards: state[status].cards.filter(card => String(card.membership_id) !== String(userId))
      },
      pendingCards: demo
        ? []
        : [
          ...state.pendingCards,
          String(userId)
        ]
    })
    : ({})

const onLikeSuccess = (state, { data: { status, item, prevStatus } }) => ({
  [status]: {
    ...state[status],
    cards: [item, ...state[status].cards.filter(card => String(card.membership_id) !== String(item.membership_id))]
  },
  [prevStatus]: {
    ...state[prevStatus],
    cards: state[prevStatus].cards.filter(card => String(card.membership_id) !== String(item.membership_id))
  },
  pendingCards: state.pendingCards.filter(id => id !== String(item.membership_id))
})

const onSkipSuccess = (state, { data: { item, prevStatus } }) => ({
  skipped: {
    ...state.skipped,
    cards: [item, ...state.skipped.cards.filter(card => String(card.membership_id) !== String(item.membership_id))]
  },
  [prevStatus]: {
    ...state[prevStatus],
    cards: state[prevStatus].cards.filter(card => String(card.membership_id) !== String(item.membership_id))
  },
  pendingCards: state.pendingCards.filter(id => id !== String(item.membership_id))
})

const onFailure = (state, { data: { prevStatus, item } }) => ({
  [prevStatus]: {
    ...state[prevStatus],
    cards: state[prevStatus].cards.filter(card => String(card.membership_id) !== String(item.membership_id))
  },
  pendingCards: state.pendingCards.filter(id => id !== String(item.membership_id))
})

export default createReducer(initialState, {
  [swipeTypes.GET_SWIPE_DATA_ATTEMPT]: (state, { data: { status } }) => ({
    [status]: {
      ...state[status],
      isLoading: true
    }
  }),
  [swipeTypes.GET_SWIPE_DATA_SUCCESS]: (state, { data: { status, cards, page } }) => ({
    [status]: {
      page,
      isLoading: false,
      isFinished: !cards.length,
      cards: +page === 1 ? cards : [...state[status].cards, ...cards]
    },
    error: status === 'queued' ? false : state.error
  }),
  [swipeTypes.GET_SWIPE_DATA_FAILURE]: (state, { data: { status, err } }) => ({
    [status]: {
      ...state[status],
      isLoading: false
    },
    error: err?.error
  }),

  [swipeTypes.RESET_SWIPE_DATA_ATTEMPT]: (state) => ({
    ...state,
    queued: initialState.queued,
    skipped: initialState.skipped,
    isOneWayInterested: initialState.isOneWayInterested,
    isMutualInterested: initialState.isMutualInterested,
  }),

  [swipeTypes.LIKE_ROOMMATE_ATTEMPT]: onActionAttempt,
  [swipeTypes.LIKE_ROOMMATE_SUCCESS]: onLikeSuccess,
  [swipeTypes.LIKE_ROOMMATE_FAILURE]: onFailure,

  [swipeTypes.SKIP_ROOMMATE_ATTEMPT]: onActionAttempt,
  [swipeTypes.SKIP_ROOMMATE_SUCCESS]: onSkipSuccess,
  [swipeTypes.SKIP_ROOMMATE_FAILURE]: onFailure,

  [swipeTypes.REMOVE_CARD_ATTEMPT]: ({
    skipped, isOneWayInterested, isMutualInterested
  }, { data: { roommateId } }) => ({
    skipped: {
      ...skipped,
      cards: skipped.cards.filter(card => String(card.membership_id) !== String(roommateId))
    },
    isOneWayInterested: {
      ...isOneWayInterested,
      cards: isOneWayInterested.cards.filter(card => String(card.membership_id) !== String(roommateId))
    },
    isMutualInterested: {
      ...isMutualInterested,
      cards: isMutualInterested.cards.filter(card => String(card.membership_id) !== String(roommateId))
    }
  }),

  [swipeTypes.GET_SEARCH_VALUES_ATTEMPT]: ({ search }) => ({
    search: {
      ...search,
      isLoading: true
    }
  }),
  [swipeTypes.GET_SEARCH_VALUES_SUCCESS]: ({ search }, { data }) => ({
    search: {
      ...search,
      ...data,
      isLoading: false,
      isSearchFetched: true
    }
  }),
  [swipeTypes.GET_SEARCH_VALUES_FAILURE]: ({ search }) => ({
    search: {
      ...search,
      isLoading: false
    }
  }),

  [swipeTypes.SET_SEARCH_VALUES_ATTEMPT]: ({ search }) => ({
    search: {
      ...search,
      isRequestLoading: true
    }
  }),
  [swipeTypes.SET_SEARCH_VALUES_SUCCESS]: ({ search }, { data }) => ({
    search: {
      ...search,
      ...data,
      isRequestLoading: false
    }
  }),
  [swipeTypes.SET_SEARCH_VALUES_FAILURE]: ({ search }) => ({
    search: {
      ...search,
      isRequestLoading: false
    }
  }),
  [swipeTypes.TICK_BADGE_ATTEMPT]: (_, { data }) => ({
    [data?.type === 'like' ? 'likeTickBadge' : 'skipTickBadge']: Math.random(),
  }),
})
