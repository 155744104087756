import { useCallback, useEffect, useState } from 'react';
import { SELECTED_AUDIO_OUTPUT_KEY } from '../../constants';
import useDevices from '../../hooks/useDevices/useDevices';

const getItem = () => {
  try {
    return window?.localStorage?.getItem(SELECTED_AUDIO_OUTPUT_KEY)
  } catch (e) {
    return null
  }
}

const setItem = (sinkId) => {
  try {
    window?.localStorage?.setItem(SELECTED_AUDIO_OUTPUT_KEY, sinkId);
  } catch (e) {
  }
}

export default function useActiveSinkId() {
  const { audioOutputDevices } = useDevices();
  const [activeSinkId, _setActiveSinkId] = useState('default');

  const setActiveSinkId = useCallback((sinkId: string) => {
    setItem(sinkId);
    _setActiveSinkId(sinkId);
  }, []);

  useEffect(() => {
    const selectedSinkId = getItem();
    const hasSelectedAudioOutputDevice = audioOutputDevices.some(
      device => selectedSinkId && device.deviceId === selectedSinkId
    );
    if (hasSelectedAudioOutputDevice) {
      _setActiveSinkId(selectedSinkId!);
    }
  }, [audioOutputDevices]);

  return [activeSinkId, setActiveSinkId] as const;
}
