import { PureComponent } from 'react'
import always from 'ramda/es/always'
import cn from 'classnames'

import { Button } from 'components/Button'
import GoogleIcon from 'static/images/googleIcon.svg'
import i18n from 'services/i18n'

import s from './GoogleLoginButton.module.scss'

export class GoogleLoginButton extends PureComponent {
  onButtonClick = () => {
    const { onClick, disabled, isLoading } = this.props

    return (disabled || isLoading)
      ? always()
      : onClick()
  }

  getLabel = () => {
    const label = this.props.isRegister
      ? i18n.t('home.register')
      : i18n.t('home.signIn')

    return i18n.t('home.withGoogle', { label })
  }

  render() {
    const { invitationLoginMethod, disabled } = this.props

    return (
      <Button
        variant="contained"
        onClick={this.onButtonClick}
        className={cn(s.container, disabled && invitationLoginMethod && s.disabled)}
      >
        <img src={GoogleIcon} className={s.icon} alt="Google" aria-hidden="true" />
        {this.getLabel()}
      </Button>
    )
  }
}
