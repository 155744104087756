import PropTypes from 'prop-types'
import cn from 'classnames'

import s from './TextArea.module.scss'

const TextArea = ({ input, placeholder, meta: { touched, error }, errorMessage, label, labelClassName, textAreaClassName, maxLength }) => {
  const isError = touched && error
  const errorId = `error-${input.name}`

  return (
    <>
      {label && (
        <label htmlFor={input.name} className={cn(s.label, labelClassName)} id={input.name}>
          {label}
        </label>
      )}
      <textarea
        {...input}
        type='text'
        maxLength={maxLength || 1500}
        className={cn(s.input, textAreaClassName)}
        placeholder={placeholder}
        aria-labelledby={input.name}
        aria-invalid={String(isError)}
        aria-describedby={isError ? errorId : undefined}
      />
      {isError && <span className={s.error} id={errorId}>{errorMessage}</span>}
    </>
  )
}

TextArea.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func
  }),
  placeholder: PropTypes.string
}

TextArea.defaultProps = {
  input: {
    value: '',
    onChange: null
  },
  placeholder: 'Type a description here'
}

export { TextArea }
