export default {
  default: {
    selected: "已选定",
    jumpNavigation: '跳转到导航',
    jumpContent: '跳转到主要内容',
    prevPage: '上一页',
    'Enter Your Basic Information': '输入基本信息',
    'Did RoomSync have any impact on your decision to sign a lease at your community?': 'RoomSync是否对您决定在社区签订租约产生了影响？',
    yes: '是的',
    no: '不',
    saved: '保存成功！',
    updated: '更新成功！',
    needAll: '请回答所有问题！',
    notAllowed: '发现不允许的词语'
  },
  home: {
    welcome: '欢迎使用',
    almost: '快好了！',
    almost2: '使用以下社交帐户注册即可开始',
    how: 'RoomSync如何使用我的社交帐户？',
    howInfo1: '我们使用该社交帐户来登录RoomSync。我们不会向该帐户发送任何电邮通知，也不会在您加入RoomSync时，自动向该社交帐户发布内容。',
    howInfo2: '只能使用一个社交帐户进行注册，注册RoomSync后，无法更换已关联的社交帐户。如有疑问，请发电邮至support@roomsync.com联系我们。',
    howInfo2NoEmail: '只能使用一个社交帐户进行注册，注册RoomSync后，无法更换已关联的社交帐户。如有疑问，请通过以下方式联系我们',
    already: '今年已注册？',
    signIn: '登录',
    register: '注册',
    noAccount: '没有帐户？',
    needHelp: '需要帮助？',
    contactUs: '联系我们！',
    agree: '注册即表示同意我们的',
    terms: '服务条款',
    privacy: '隐私政策',
    withFacebook: '通过Facebook{{label}}登录',
    withGoogle: '通过Google{{label}}登录',
    withApple: '通过Apple{{label}}登录',
    getMobile: '请下载app，更方便！',
    onlyMobile: 'RoomSync网站的功能及用户体验，暂时无法比肩RoomSync App。',
    facebookUnavailable: '无法使用Facebook登录。请检查隐私设置！',
    googleUnavailable: '无法使用Google登录',
    forgotLogin: '忘记登录？',
    enterAddress: '输入您的电子邮件地址',
    emailPlaceholder: '电子邮件地址',
    submit: '提交',
    methodInfo: '我们的记录显示您使用了 {{types}} 帐户登录。请尝试使用您能够访问的所有 {{types}} 帐户登录，即使它们与您接收RoomSync通知的电子邮件地址不同。如果您仍然无法登录，请通过support@roomsync.com与我们联系寻求帮助。',
    methodInfoNone: '此电子邮件未关联任何RoomSync帐户。',
  },
  about: {
    title: '本人信息',
    save: '保存',
    noPreferences: '无偏好',
    roommateRespond: '希望室友如何回应？',
    roommateRespond2: '希望室友如何回答上述问题？',
    saveAria: '保存按钮'
  },
  chat: {
    title: '聊天',
    noConversations: '无聊天记录',
    unavailable: '无法使用聊天',
    remove: '删除',
    cancel: '取消',
    chat: '聊天',
    last: '最新消息时间',
    you: '本人',
    removePrompt: '是否确定删除消息？',
    inputPlaceholder: '输入消息...',
    firstMessage: '第一条消息来自{{from}}，创建于{{createdAt}}',
    createdAt: '创建于{{createdAt}}',
    messageFrom: '消息来自{{from}}',
    report: '报告',
    join: '加入',
    decline: '拒绝',
    wantJoin: '是否加入？'
  },
  community: {
    title: '社区',
    infoContent: `
    RoomSync的配对期，将在此处显示的结束日美东时间晚上8:00结束。\n\n在配对期结束时，“配对”选项卡的“当前室友配对”部分中出现的人选，是住房办公室收到的申请。\n\n若未能在配对期结束前选择室友，我们建议您联系住房办公室，询问有关室友分配的规定。`,
    infoLabel: '配对结束日',
    matchingEnd: 'RoomSync配对结束时间:',
    newPost: '新建发帖',
    newMessage: '新建消息',
    postLimitError: '社区发帖必须为1至1500个字符！',
    post: '发帖',
    info: '社区信息',
    posts: '社区发帖',
    common: '常见消息',
  },
  housing: {
    title: '宿舍',
    searchPlaceholder: '输入宿舍类型',
    setHousing: '设置',
    add: '添加宿舍类型'
  },
  main: {
    community: '社区',
    chat: '聊天',
    find: '查找',
    matches: '配对',
    welcome: '入门'
  },
  majors: {
    title: '专业列表',
    searchPlaceholder: '输入一个专业',
    setMajors: '设置',
    add: '添加专业'
  },
  matches: {
    title: '室友配对',
    infoRoommate: '恭喜完成配对申请！目前宿舍楼已满，搬进及搬出的配对申请已禁用。如果您或您申请的室友离开“当前室友配对”，您将能够再次发送和接收新的申请。',
    directions: '社区指南：',
    leaveTitle: '是否确定退出“当前室友配对”？',
    leaveCurrent: '是，退出“当前室友配对”',
    no: '请忽略',
    find: '寻找室友配对',
    dontSee: '为何看不到申请？',
    current: '当前室友配对',
    currentInfo: '“当前室友配对”是指您发送或收到的已接受申请。此列表还可能包括已经配对的室友，以及社区工作人员代表您进行的配对。',
    compatible: '相配',
    likes: '{{firstName}}对您回赞！',
    view: '查看{{firstName}}个人资料',
    showProfiles: '查看已点赞的个人资料',
    noMatches: '当前没有室友配对',
    leave: '退出“当前室友配对”',
    unshare: '是否确定取消与{{name}}共用寝室？',
    share: '是否确定与{{name}}共用寝室？',
    congratulations: '恭喜！',
    incoming: '收到的申请',
    outgoing: '发出的申请',
    outgoingRequests: '发出的申请 ({{count}})',
    incomingRequests: '收到的申请 ({{count}})',
    looking: '寻找更多室友？',
    keep: '继续搜索',
    or: ' 或者 ',
    viewSaved: '查看已保存的浏览',
    noRequests: '没有申请',
    unshareLabel: '取消共用',
    openMatchesLabel: '打开“当前室友配对”描述模式',
    cancel: '取消',
    save: '保存',
    shareLabel: '本人想跟对方共用寝室',
    knowLabel: '使用Roomsync之前，本人就认识对方， 对方是本人的理想室友。',
    knowLabelStrong: '使用Roomsync之前，本人就认识<b>{{name}}</b>， <b>{{name}}</b>是本人的理想室友。'
  },
  more: {
    title: '更多',
    mobileApp: '移动应用程序',
    editProfile: '编辑个人资料',
    update: '更新基本信息',
    switch: '转换会员身份',
    contact: '联系我们',
    logout: '注销',
    signedWith: '您使用{{type}}登录',
    logoutTitle: '是否确定要注销？',
    yes: '是',
    no: '忽略',
    language: '语言',
    delete: '删除帐户',
    notification: '{{count}}条通知',
    notifications: '{{count}}条通知',
  },
  network: {
    searchPlaceholder: '请输入正确的大学名称...',
    closed: '此网络已关闭',
    info1: '我们知道您很想找到合适的室友——我们也很期待!',
    info2: '请在室友配对期间\n开始时间为',
    info3: '\n结束时间',
    info4: ' 开始\n搜寻室友。\n\n如有疑问，请联系我们！',
    contact: '如需帮助，请通过以下方式联系我们'
  },
  noNetwork: {
    title: '使用邀请链接注册',
    gotLink: '拥有邀请链接？',
    topDescription: '您只能通过特殊的邀请链接来访问RoomSync社区配对群组。根据社区的不同，该链接可能是由社区发送，也可能是由RoomSync直接发送',
    bottomDescription: '如果之前访问过社区配对群组，而现在可能是用我们无法识别的其他Facebook、Google或Apple帐户登录，如果不确定使用的是哪个帐户，请通过以下方式联系我们 ',
    forHelp: '  以获得帮助。',
    logOut: '注销',
    goBack: '返回'
  },
  passcode: {
    label: '密码',
    continue: '继续',
    invalid: '无效密码'
  },
  preview: {
    about: '本人信息',
    social: '社交帐户',
    majors: '专业',
    housing: '宿舍',
    roommates: '室友',
    questions: '问题',
    gender: '性别：',
    residentStatus: '住户身份'
  },
  profile: {
    title: '个人资料',
    matched: '已配对',
    compatible: '% 相配',
    deny: '拒绝',
    accept: '接受',
    cancelRequest: '取消申请',
    sendRequest: '申请室友',
    cancel: '取消',
    hidden: '个人资料已隐藏！',
    hiddenInfo: '隐藏个人资料无法查看此内容  :(',
    unhideInfo: '您可以随时通过在下方更新状态',
    byUpdating: '来取消隐藏个人资料：',
    here: '更新状态',
    startChat: '开始聊天',
    request: '申请室友',
    viewProfile: '查看个人资料',
    network: '网络',
    subnetwork: '子网络',
    unit: '宿舍类型',
    room: '寝室类型',
    selectMembership: '选择会员身份',
    pressEnter: '若要查看当前资料，请按回车键',
    preview: '预览个人资料',
    fullname: '您的全名{{name}}',
    chatWith: '与室友聊天',
    callWith: '致电室友',
    settings: '个人资料设置',
    details: '个人资料详情',
    removedTitle: '已删除RoomSync访问权',
    removedInfo: '物业管理员已将此用户删除，因此无权访问此配对群组。如有疑问，请联系',
    deletedTitle: '已删除您的RoomSync帐户'
  },
  questions: {
    save: '保存',
    title: '本人信息',
    alertTitle: '本人信息为必填项',
    alertDescription: '继续在RoomSync寻找室友之前，请回答“本人信息”相关的问题。',
  },
  request: {
    title: '申请',
    emptyError: '消息不能为空！',
    defaultError: '若此人加入，会导致宿舍人员过多。十分抱歉！',
    sendRequest: '发送室友申请',
    sent: '已发送申请',
    view: '查看室友申请',
    messagePlaceholder: '消息……',
    inBedroom: '愿意与您共用寝室。',
    inCommon: '愿意与您共用公共空间。',
  },
  swipes: {
    title: '我的浏览',
    skipped: '跳过',
    liked: '点赞',
    mutual: '互相点赞',
    oneWay: '单方点赞',
    noCards: '没有人选',
    noMore: '没有更多人选',
    loadMore: '加载更多',
    checkLater: '稍后再看！',
    noSearchResults: '没有搜索结果',
    skippedAll: '目前已浏览所有潜在室友。\n如有更多人加入，将出现在此处。',
    updateSearch: '更新搜索筛选器以查看更多用户',
    whyDont: '为何看不到更多的用户？',
    reviewSwipes: '查看已浏览记录',
    ohNo: '哦，不！\n未找到此人。',
    wontBeAble: '未找到此人有很多原因，比如……',
    thisPerson: '• 此人',
    notRegistered: '尚未在RoomSync',
    yet: '注册帐户',
    thisPersonHas: '• 此人的',
    apartment: '公寓类型或寝室类型',
    notCompatible: '不符合您的宿舍配置',
    both: '• 双方 ',
    renewing: '都是续居户',
    noHousing: '如果双方都是续居户，而各自的配对并未显示在RoomSync，住房办公室可以将双方进行配对。',
    thisPersonAlready: '• 此人已经是',
    fully: '一个完成配对群组的一员，',
    noSpaces: '没有空间容纳更多的室友。',
    findMore: '查找更多室友',
    checked: '如果已查看上述原因，但仍觉得能够找到此人，',
    letKnow: '请告知。',
    like: '点赞',
    skip: '跳过',
    next: '下一个',
    back: '返回',
    userSwiped: '已浏览此用户',
    userSwipedInfo: '在“我的浏览”部分，已找到具有该名称的用户',
    fullTitle: '恭喜，配对群组已满！',
    fullInfo: '由于群组内没有剩余空间，将不会看到可以浏览的新人选。',
    fullButton: '查看配对群组',
  },
  search: {
    title: '搜索',
    byPreferences: '按偏好搜索',
    byName: '按姓名搜索',
    firstName: '名',
    lastName: '姓',
    majors: '学科专业',
    housing: '宿舍类型',
    group: '群组大小',
    groupSize: '群组大小：{{groupSize}}',
    apply: '应用房间过滤器',
    byApplying: '通过应用房间过滤器，将只显示仍需要室友的用户。',
    clear: '清除所有过滤器',
    submit: '应用过滤器',
    any: '任意',
    lifestyle: '生活方式',
    room: '房间',
    results: '{{name}}的搜索结果',
    clearSearch: '清除搜索',
    clearFilters: '清除过滤器',
    editMajors: '编辑学科专业',
    editHousing: '编辑宿舍类型',
    filters: '过滤器',
    gender: '性别',
    registration: '注册状态'
  },
  social: {
    title: '社交帐户',
    heading: '添加社交帐户',
    add: '添加',
    channel: '频道',
    no: '不具有',
    invalid: '{{label}}无效',
    username: '用户名',
    invalidUsername: '用户名无效',
    noAccount: '无帐户'
  },
  thread: {
    title: '发帖',
    new: '新主题',
    by: '创建人',
    replies: '回复',
    reply: '回复',
    noPosts: '尚未发帖',
    newPost: '新帖',
    newPostPlaceholder: '输入内容……',
    sendAria: '发帖',
    postAria: '回帖。若要浏览回帖，请使用上/下箭头'
  },
  verify: {
    updateTitle: '更新基本信息',
    welcome: '欢迎来到',
    review: '查看基本信息',
    tooltip1: '该社区不允许自行修改基本信息。若发现信息不准确，请发电邮件至support@roomsync.com联系我们。',
    tooltip2: '若此字段为空，则社区发送给我们的名字，将显示给其他用户。',
    tooltip3: '若此字段为空，则社区发送给我们的姓氏，将显示给其他用户。',
    tooltip4: '若此字段为空，RoomSync的通知将发送到所在社区已存档的电邮中。若在此字段已输入有效电邮地址，RoomSync的通知将仅发送到此首选电邮地址。请注意，RoomSync的其他用户看不到电邮地址。',
    tooltip5: '通过隐藏个人资料，您将不再出现在其他用户的搜索结果中。如果有人已经点赞或跳过您的资料，该资料仍然会出现在对方的“已保存的浏览”中。 \n\n隐藏个人资料不会影响当前室友配对。若已跟某人配对，则保持配对。您将继续收到与帐户相关的新活动通知，例如新室友、新聊天消息或有人退出配对。 ',
    tooltip6: '您的电话号码将分享给{{networkName}}',
    no: '否',
    yes: '是',
    firstName: '名字',
    firstNameLimit: '名字必须为1到50个字符',
    lastName: '姓氏',
    lastNameLimit: '姓氏必须为1到50个字符',
    preferredTitle: '常用名',
    preferredInfo: '是否希望面向其他用户时，显示与上方不同的姓名？',
    preferredFN: '常用名 (可选项)',
    preferredLN: '常用姓 (可选项)',
    preferredFirstNameLimit: '常用名必须为1到50个字符',
    preferredLastNameLimit: '常用姓必须为1到50个字符',
    email: '电子邮件',
    preferredE: '常用电子邮件 (可选的)',
    emailError: '请提供正确的电邮地址',
    phoneError: '请提供正确的电话号码',
    gender: '性别',
    hide: '隐藏个人资料',
    phoneNumber: '电话号码',
    hideDescription: '是否要隐藏个人资料？\n若执行此操作，在其他用户进行\n“查找”时，您将不会出现在搜索结果中。',
    update: '更新',
    continue: '继续',
    genderRequired: '基本信息为必填项',
    genderDescription: '请回答RoomSync“基本信息”部分的所有问题',
    pronoun: '人称代词',
    pronounOptional: '人称代词（可选项）',
    pronounTooltip: '与他人分享您的人称代词。该回答可随时更新。',
    pronounError: '请联系support@roomsync.com提交您的人称代词。',
    quaranteeLabel: '本人理解室友申请和配对可能会有变化，社区工作人员无法保证需求得到满足。',
    proceed: '继续'
  },
  block: {
    title: '聊天设置',
    block: '阻止{{type}}',
    unblock: '取消阻止{{type}}',
  },
  confirmation: {
    title: '不保存更改就离开？',
    yes: '是',
    no: '忽略',
  },
  feedback: {
    title: '反馈'
  },
  gender: {
    cancel: '取消',
    notSelected: '未选择',
    pleaseSelect: '请选择性别',
    info: 'RoomSync仅出于室友配对的目的询问性别。性别将不会显示给其他用户。\n\n请选择最适合的选项。\n\n若将来需要改变性别选项，请联系RoomSync。',
  },
  invitation: {
    title: '是否为 {{firstName}} {{lastName}}本人？',
    info: '若选择继续，{{firstName}} {{lastName}}的单次邀请链接将视为已用。若非本人却使用了该链接，系统将不接受室友配对。',
    confirm: '是，本人为{{firstName}} {{lastName}}',
    notMe1: '否，此人为租房担保人',
    notMe2: '否，此人跟本人无关',
    notMe3: '不全是，本人为租房担保人',
  },
  demo: {
    skipTitle0: '不感兴趣？',
    skipDesc0: '若不感兴趣，可以暂时“跳过”。',
    skipFooter0: '跳过此人',
    likeTitle0: '改变想法？',
    likeDesc0: '这些是已跳过的用户。若向右滑动，可以改变之前的决定并点赞。',
    likeFooter0: '立即给此人点赞',
    skipTitle1: '不感兴趣？',
    skipDesc1: '若不感兴趣，可以暂时“跳过”。您可以随时改变想法。',
    skipFooter1: '立即跳过此人',
    likeTitle1: '寻找室友',
    likeDesc1: '向右滑动即可匿名“点赞”。如果对方回赞，我们会发出通知，以便双方就成为室友之事进行讨论。',
    likeFooter1: '立即给此人点赞',
    toSkip: '若要跳过，请按左箭头键',
    toLike: '若要点赞，请按右箭头键'
  },
  languages: {
    title: '语言',
    en: 'English',
    zh: '中文（简体)',
    pl: 'Polski',
    es: 'Español',
    ua: 'Українська',
    ru: 'Pусский',
    soon: '其他语言即将推出！',
  },
  notFound: {
    title: '未找到页面',
    oh: '哦，不！',
    description: '未找到页面。请发电邮至support@roomsync.com或拨打352.327.4061转2寻求帮助。'
  },
  mobile: {
    title: 'RoomSync移动应用程序',
    desc1: '请在iOS或Android设备上使用RoomSync来寻找室友。只需在App Store或Google Play中搜索RoomSync应用程序。',
    desc2: '请确保在移动应用程序上使用相同的社交帐户登录，以便访问现有帐户的所有信息。'
  },
  video: {
    join: '立即加入',
    joning: '加入会面',
    calling: '呼叫中',
    joinTitle: '加入视频通话',
    callTitle: '开始视频通话',
    call: '呼叫',
    cancel: '取消',
    reconnecting: '重连……',
    you: '(您)',
    disconnect: '断开连接'
  },
  welcome: {
    title: '欢迎',
    closed: 'RoomSync目前已关闭',
    desc1: '在RoomSync选择室友的截止日已过',
    desc1_1: '然而，您可以使用其他可能仍然开放的',
    desc1_2: '会员身份',
    desc1_3: '进行配对',
    desc1_4: '. 一旦配对期结束，就无法再查看潜在室友、接受申请或修改配对。',
    desc2: '在RoomSync找不到室友？',
    desc3: '我们建议联系住房办公室，询问如何选择室友或将自己的要求告知对方。',
    desc4: '已有一个成功配对？',
    desc6: 'RoomSync提前关闭？',
    desc7: '有时住房办公室需要比预期更早地完成匹配。若对RoomSync的截止日期有疑问，请询问社区，因为这些日期由社区办公室管理。',
    welcome: '欢迎来到RoomSync！',
    welcome1: '请于以下表格填写室友配对申请，并告知您的要求。',
    welcome1_button: '表格',
    welcome1_2: '\n社区将根据您的回答，尽力找到合适的室友。',
    welcome1_3: '还可以点击下方，访问您的其他会员身份',
    welcome1_4: '会员身份',
    welcome2: '有关填写个人资料的建议，请查看',
    welcome2_button: '“社区”选项卡。',
    welcome3: '如有配对流程或房间分配方面的疑问，请直接联系社区。在使用RoomSync方面，如需帮助请点击',
    welcome3_button: '联系我们',
    welcome3_2: '以取得联系',
  },
  delete: {
    title: '删除帐户',
    info: '是否要删除RoomSync帐户？此操作无法撤消，将永久删除提供给RoomSync的所有信息。您可以选择在下方隐藏个人资料，而无需删除帐户。',
    hideProfile: '隐藏个人资料',
    confirmTitle: '是否确定要删除帐户？',
    confirm: '是，删除帐户',
    nevermind: '忽略'
  },
  ai: {
    enterKeyword: '输入关键词',
    keyword: '关键词',
    title: 'AI个人资料助手',
    writeForMe: '帮我写这个',
    listUp: '列出最多三个关键词作为您的回答',
    cancel: '取消',
    next: '下一步',
    pickUp: '您是怎样的人？（最多选择 {{count}} 个/3）',
    back: '返回',
    generate: '生成',
    calm: '冷静',
    ambitious: '有野心的',
    friendly: '友好的',
    introverted: '内向的',
    organized: '有组织的',
    extroverted: '外向的',
    honest: '诚实的',
    creative: '富有创造力的',
    determined: '坚定的',
    curious: '好奇的',
    dependable: '可靠的',
    empathetic: '有同理心的',
    flexible: '灵活的',
    independent: '独立的',
    kind: '友善的',
  }
}
