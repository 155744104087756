import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { hide } from 'redux-modal'

import { Button } from '@material-ui/core';
import profileSelectors from 'redux/selectors/ProfileSelectors'
import chatSelectors from 'redux/selectors/ChatSelectors'
import i18n from 'services/i18n'

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

const EndCallButton = (props) => {
  const classes = useStyles();
  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext();

  const onClick = useCallback(() => {
    props.sendMessage(`${props.myName} ended a call 📞`)
    props.hideModal('videoRoomModal')
    removeLocalVideoTrack()
    removeLocalAudioTrack()
    setTimeout(() => room.disconnect(), 0)
  }, [props.sendMessage])

  useEffect(() => {
    if (props.isLastMessageEnded) {
      removeLocalVideoTrack()
      removeLocalAudioTrack()
      props.hideModal('videoRoomModal')
    }
  }, [props.isLastMessageEnded])

  useEffect(() => {
    window.addEventListener('beforeunload', onClick)

    return () => window.removeEventListener('beforeunload', onClick)
  }, [])

  return (
    <Button
      onClick={onClick}
      className={clsx(classes.button, props.className)} data-cy-disconnect
    >
      {i18n.t('video.disconnect')}
    </Button>
  )
}

const mapDispatchToProps = {
  hideModal: hide
}

const mapStateToProps = createStructuredSelector({
  myName: profileSelectors.name,
  isLastMessageEnded: chatSelectors.isLastMessageEndedSelector
})

export default connect(mapStateToProps, mapDispatchToProps)(EndCallButton)
