import { makeStyles } from '@material-ui/core/styles';
import { COLOR_SECONDARY } from 'styles/constants';

const CONTAINER_WIDTH = 500

export const useTabsStyles = makeStyles({
  root: {
    maxWidth: CONTAINER_WIDTH,
    marginBottom: 20,
    borderBottom: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: "rgba(255, 255, 255, 0.2)"
  },
  indicator: {
    backgroundColor: "#ffffff"
  },
});

export const useTabStyles = makeStyles({
  root: {
    flex: 1,
    opacity: 0.8,
    flexDirection: 'row',
    alignItems: 'center',
    color: "#ffffff",
    textTransform: "initial",
    outlineColor: `${COLOR_SECONDARY} !important`,
    '&:hover, &:focus': {
      opacity: 1,
      backgroundColor: `rgba(255, 255, 255, 0.1)`,
    },
  },
  wrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    opacity: 1,
  }
});