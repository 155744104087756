import React from 'react';

interface IProps {
  value: number;
  index: number;
}

export const TabPanel: React.FC<IProps> = props => (
  <div
    role="tabpanel"
    id={`tabpanel-${props.index}`}
    hidden={props.value !== props.index}
    aria-labelledby={`tab-${props.index}`}
  >
    {props.value === props.index && (
      <>
        {props.children}
      </>
    )}
  </div>
);
