import { createReducer } from 'services/reduxHelpers'
import isEmpty from 'ramda/es/isEmpty'
import { homeTypes } from '../actions/HomeActions'

/* ------------- Initial State ------------- */

export const initialState = {
  isAppleLoading: false,
  isFacebookLoading: false,
  isGoogleLoading: false,
  isInvitationLoading: false,
  invitationData: null,
  invitationReset: 0,
  loginType: null,
  initialRoute: null,
  isLoginMethodLoading: false,
  lastCheckedDate: null,
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [homeTypes.FACEBOOK_LOGIN_ATTEMPT]: () => ({ isFacebookLoading: true }),
  [homeTypes.FACEBOOK_LOGIN_SUCCESS]: () => ({
    ...initialState,
    loginType: 'Facebook'
  }),
  [homeTypes.FACEBOOK_LOGIN_FAILURE]: () => initialState,

  [homeTypes.GOOGLE_LOGIN_ATTEMPT]: () => ({ isGoogleLoading: true }),
  [homeTypes.GOOGLE_START_AUTH_FLOW_ATTEMPT]: () => ({ isGoogleLoading: true }),
  [homeTypes.GOOGLE_END_AUTH_FLOW_ATTEMPT]: () => ({ isGoogleLoading: false }),
  [homeTypes.GOOGLE_LOGIN_SUCCESS]: () => ({
    ...initialState,
    loginType: 'Google'
  }),
  [homeTypes.GOOGLE_LOGIN_FAILURE]: (state, { data }) => initialState,
  // prop('resetInvitation', data)
  //   ? initialState
  //   : { ...initialState, invitationData: state.invitationData },

  [homeTypes.APPLE_AUTH_ATTEMPT]: () => ({ isAppleLoading: true }),
  [homeTypes.APPLE_AUTH_SUCCESS]: () => ({
    ...initialState,
    loginType: 'Apple'
  }),
  [homeTypes.APPLE_AUTH_FAILURE]: () => initialState,

  [homeTypes.PROCEED_INVITATION_LOGGED_IN_ATTEMPT]: () => ({ isInvitationLoading: true }),
  [homeTypes.PROCEED_INVITATION_LOGGED_IN_SUCCESS]: () => initialState,
  [homeTypes.PROCEED_INVITATION_LOGGED_IN_FAILURE]: () => initialState,

  [homeTypes.GET_INVITATION_PREFLIGHT_ATTEMPT]: () => ({ isInvitationLoading: true }),
  [homeTypes.GET_INVITATION_PREFLIGHT_SUCCESS]: () => ({ isInvitationLoading: false }),
  [homeTypes.GET_INVITATION_PREFLIGHT_FAILURE]: () => ({ isInvitationLoading: false }),

  [homeTypes.SET_INVITATION_ATTEMPT]: (state, { data: { loginMethod, ...data } }) =>
    isEmpty(data)
      ? ({
        ...initialState,
        loginMethod: state.loginMethod
      })
      : ({
        loginMethod,
        invitationData: data,
        invitationReset: 0
      }),
  [homeTypes.SET_INITIAL_ROUTE_ATTEMPT]: (_, { data }) => ({
    initialRoute: data
  }),

  [homeTypes.RESET_ATTEMPT]: (state) => ({
    ...initialState,
    initialRoute: state.initialRoute,
    invitationReset: state.invitationReset + 1,
    invitationData: state.invitationReset > 2
      ? null
      : state.invitationData
  }),

  [homeTypes.GET_LOGIN_METHOD_ATTEMPT]: () => ({ isLoginMethodLoading: true }),
  [homeTypes.GET_LOGIN_METHOD_SUCCESS]: () => ({ isLoginMethodLoading: false }),
  [homeTypes.GET_LOGIN_METHOD_FAILURE]: () => ({ isLoginMethodLoading: false }),
  [homeTypes.SET_LAST_CHECKED_DATE_ATTEMPT]: (state, {data}) => ({
    ...state,
    lastCheckedDate: data
   }),
})
