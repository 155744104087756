import React from 'react';
import MUITab, { TabProps } from '@material-ui/core/Tab'

import { useTabStyles } from './styles';

export const Tab: React.FC<TabProps> = props => {
  const classes = useTabStyles();

  return (
    <MUITab
      {...props}
      disableRipple
      classes={classes}
      disableFocusRipple
    />
  );
}