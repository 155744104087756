import React from 'react';

import packageJson from '../../../package.json';

import s from './Version.module.scss'

export const Version: React.FC = () => (
  <small className={s.version}>
    v{packageJson.version}
  </small>
);
