import { createSelector } from 'reselect'

const getState = state => state.settings
const swipeFirstLook = state => getState(state).swipeFirstLook

const isSwipeFirstLookSelector = createSelector(
  swipeFirstLook,
  swipeFirstLook => swipeFirstLook
)

export default {
  isSwipeFirstLookSelector
}
