import PropTypes from 'prop-types'

import { MenuItem } from 'components'

import Person from 'static/images/person.svg'
import Settings from 'static/images/settings.svg'
import Logo from 'static/images/logoMenu.svg'
import Arrow from 'static/images/arrow.svg'
import Earth from 'static/images/earth.svg'
import Delete from 'static/images/delete-account.svg'
import HomeSelected from 'static/images/homeSelected.svg'

import { Version } from 'components/Version'
import i18n from 'services/i18n'

import s from './MoreContent.module.scss'

const items = [
  { type: 'person', icon: Person },
  { type: 'settings', icon: Settings },
  { type: 'membership', icon: HomeSelected },
  { type: 'mobile', icon: Logo },
  { type: 'earth', icon: Earth },
  { type: 'delete', icon: Delete },
  { type: 'arrow', icon: Arrow },
]

const MoreContent = ({ onItemClick, loginType, showSwitchMemberships }) => (
  <div className={s.container}>
    {items.map(({ type, icon }, idx) =>
      (!showSwitchMemberships && type === 'membership')
        ? null
        : (
          <MenuItem
            key={type}
            index={idx}
            type={type}
            Icon={icon}
            onClick={onItemClick}
          />
        )
    )}
    {loginType && <span className={s.loginType}>{i18n.t('more.signedWith', { type: loginType })}</span>}
    <Version />
  </div>
)

MoreContent.propTypes = {
  onItemClick: PropTypes.func.isRequired
}

export { MoreContent }
