import PropTypes from 'prop-types'

import { Button } from 'components/Button'
import { formatDate } from 'services/helpers'

import s from './RequestCard.module.scss'

const RequestCard = ({ name, date, avatar, onClick }) => (
  <li>
    <Button
      role="link"
      onClick={onClick}
      className={s.container}
    >
      <div className={s.content}>
        <div className={s.photo_border}>
          <img
            alt=""
            src={avatar}
            rel='noreferrer'
            aria-hidden="true"
            className={s.photo}
            referrerPolicy='no-referrer'
          />
        </div>
        <h3 className={s.name}>{name}</h3>
      </div>
      <p className={s.date} aria-label={`Request date: ${formatDate(new Date().setTime(date))}`}>
        {formatDate(new Date().setTime(date))}
      </p>
    </Button>
  </li>
)

RequestCard.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export { RequestCard }
