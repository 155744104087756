import { Field, reduxForm } from 'redux-form'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import FadeIn from 'react-fade-in'

import { swipeActions } from 'redux/actions/SwipeActions'
import swipeSelectors from 'redux/selectors/SwipeSelectors'
import { Input, RoundButton } from 'components'
import i18n from 'services/i18n'

import s from './NameSearch.module.scss'

const NameSearchCmp = ({ handleSubmit }) => (
  <FadeIn>
    <section className={s.container}>
      <Field name='firstName' label={i18n.t('search.firstName')} component={Input} />
      <Field name='lastName' label={i18n.t('search.lastName')} component={Input} />
      <RoundButton onClick={handleSubmit} className={s.button}>{i18n.t('search.title')}</RoundButton>
    </section>
  </FadeIn>
)

export const NameSearchForm = reduxForm({
  form: 'nameSearch'
})(NameSearchCmp)

const mapStateToProps = createStructuredSelector({
  initialValues: swipeSelectors.searchNameValuesSelector
})

const mapDispatchToProps = {
  onSubmit: values => dispatch => dispatch(swipeActions.setSearchValues({ ...values, fromSearchName: true }))
}

export const NameSearch = connect(mapStateToProps, mapDispatchToProps)(NameSearchForm)
