import { connect } from 'react-redux'
import { show } from 'redux-modal'

import s from './TempButton.module.scss'

const TempButtonView = ({ label, showModal, modalName, data }) => (
  <div className={s.container} onClick={() => showModal(modalName, data)}>
    <span>{label}</span>
  </div>
)

const mapDispatchToProps = {
  showModal: show
}

const TempButton = connect(null, mapDispatchToProps)(TempButtonView)

export { TempButton }
