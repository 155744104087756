import cn from 'classnames'
import PhoneInputComponent from 'react-phone-input-2'
import './bootstrap.css'
import s from './PhoneInput.module.scss'

const PhoneInput = ({
  rightAdornment,
  disabled,
  label,
  input,
  type,
  maxLength,
  meta: { touched, error },
  placeholder,
  errorMessage
}) => {
  const isResError = typeof error === 'string'
  const errorId = `error-${input.name}`
  const isError = touched && error
  const { onChange, value } = input

  return (
    <div className={cn(s.container, isError && s.errorContainer)}>
      <label className={cn(s.label, isError && s.errorLabel)} htmlFor={input.name}>
        {label}
      </label>
      <PhoneInputComponent
        country={'us'}
        value={value}
        type={type}
        maxLength={maxLength}
        id={input.name}
        disabled={disabled}
        specialLabel=""
        containerClass={s.containerClass}
        placeholder={placeholder}
        aria-invalid={String(isError)}
        onChange={onChange}
        aria-describedby={isError ? errorId : undefined}
        className={cn(isError && s.error, disabled && s.disabled, !!rightAdornment && s.paddingRight)}
      />
      {rightAdornment && (
        <div className={s.rightAdornment}>
          {rightAdornment}
        </div>
      )}
      {isError && (
        <p className={s.helperText} id={errorId}>
          {isResError ? error : errorMessage}
        </p>
      )}
    </div>
  )
}

PhoneInput.defaultProps = {
  type: 'text',
  placeholder: ''
}

export { PhoneInput }
