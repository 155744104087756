import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { communityTypes, communityActions } = createActionsStructure('community', [
  { name: 'GET_CONTENT', async: true },
  { name: 'GET_THREADS', async: true },
  { name: 'GET_POSTS', async: true },
  { name: 'CREATE_THREAD', async: true },
  { name: 'CREATE_POST', async: true }
])
