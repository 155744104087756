import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import routeSelectors from 'redux/selectors/RouteSelectors'
import { logEvent } from 'services/helpers'
import keys from 'ramda/es/keys'
import includes from 'ramda/es/includes'
import __ from 'ramda/es/__'

const ROUTES_MAP = {
  '/more': 'screen.MoreScreen',
  '/profile-overview': 'screen.MyProfileScreen',
  '/profile-preview': 'screen.ProfilePreviewScreen',
  '/about-me': 'screen.AboutMeScreen',
  '/set-majors': 'screen.MajorsScreen',
  '/housing': 'screen.HousingScreen',
  '/social-accounts': 'screen.SocialAccountsScreen',
  '/social-accounts/twitter': 'screen.SetSocialScreen.Twitter',
  '/social-accounts/instagram': 'screen.SetSocialScreen.Instagram',
  '/social-accounts/snapchat': 'screen.SetSocialScreen.Snapchat',
  '/social-accounts/youtube_channel': 'screen.SetSocialScreen.Youtube',
  '/social-accounts/pinterest': 'screen.SetSocialScreen.Pinterest',
  '/update-basic-info': 'screen.VerifyInfoScreen',
  '/matches': 'screen.MatchesScreen',
  '/find': 'screen.FindScreen',
  '/request/': 'screen.RequestScreen',
  '/saved': 'screen.SavedSwipesScreen',
  '/chat': 'screen.ChatScreen',
  '/conversation/': 'screen.ChatThreadScreen',
  '/community': 'screen.CommunityScreen',
  '/new-post': 'screen.NewThreadScreen',
  '/thread/': 'screen.ThreadScreen',
  '/no-network': 'screen.NoNetwork',
  '/profile/': 'screen.ProfileScreen',
  '/': 'screen.HomeScreen'
}

class ScreenTrackerView extends PureComponent {
  componentDidUpdate() {
    const key = keys(ROUTES_MAP).find(includes(__, this.props.route))

    if (key) {
      logEvent(ROUTES_MAP[key])
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = createStructuredSelector({
  route: routeSelectors.currentPathnameSelector
})

const ScreenTracker = connect(mapStateToProps)(ScreenTrackerView)

export { ScreenTracker }
