import { put, call, select } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import { ToastsStore } from 'react-toasts'

import userSelectors from '../selectors/UserSelectors'
import profileSelectors from '../selectors/ProfileSelectors'
import API from 'services/api'
import { THREAD } from 'constants/urls'
import { getProfanityWords } from 'services/helpers'

import s from '../../layouts/SignedInLayout/Toast.module.scss'
import { handleToastFocus } from 'helpers/handleToastFocus'

export function* getCommunityContent(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getCommunityContent, accessToken, networkId, subnetworkId)

    if (res) {
      yield put(responseSuccess(res))
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getCommunityThreads(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getCommunityThreads, accessToken, networkId, subnetworkId)

    if (res) {
      yield put(responseSuccess({
        threadsIds: res.threads.map(v => v.post_id),
        threads: res.threads.reduce((p, c) => { p[c.post_id] = c; return p }, {})
      }))
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getCommunityPosts(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getCommunityPosts, accessToken, networkId, subnetworkId, data)

    if (res) {
      const thread = {
        user: res.user,
        body: res.body,
        post_id: res.thread_id,
        date_created: res.date_created,
        post_count: res.posts.length + 1
      }

      const firstPost = {
        body: res.body,
        date_created: res.date_created,
        post_id: 'first_post',
        user: res.user
      }

      yield put(responseSuccess({
        items: [firstPost, ...res.posts],
        thread,
        ...data
      }))
    } else {
      yield put(responseFailure(data))
    }
  } catch (err) {
    yield put(responseFailure(data))
  }
}

export function* createCommunityThread(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, err } = yield call(API.createCommunityThread, accessToken, membershipId, networkId, subnetworkId, data)

    if (res) {
      yield put(replace(THREAD.replace(':threadId', res.thread_id)))
      yield put(responseSuccess())
    } else {
      const error = err.validation[0]
      const errorText = error.error_text.split('.')
      const profanityWords = getProfanityWords(error.about_me)
      const errorMessage = `${errorText[0]} "${profanityWords.join(', ')}" ${errorText[1]}`

      error.title === 'Profanity' && ToastsStore.error(errorMessage, 8000, s.toast)
      handleToastFocus()
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* createCommunityPost(action) {
  const { responseSuccess, responseFailure, data } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, err } = yield call(API.createCommunityPost, accessToken, membershipId, networkId, subnetworkId, data)
    if (res) {
      const myAvatar = yield select(profileSelectors.myAvatarSelector)
      const lastName = yield select(profileSelectors.lastNameSelector)
      const firstName = yield select(profileSelectors.firstNameSelector)
      const threadId = data.threadId

      const post = {
        user: {
          image_urls: {
            square: myAvatar
          },
          membership_id: membershipId,
          first_name: firstName,
          last_name: lastName
        },
        date_created: new Date().getTime(),
        body: data.message
      }
      yield put(responseSuccess({
        post,
        threadId
      }))
    } else {
      const error = err.validation[0]
      const errorText = error.error_text.split('.')
      const profanityWords = getProfanityWords(error.about_me)
      const errorMessage = `${errorText[0]} "${profanityWords.join(', ')}" ${errorText[1]}`

      error.title === 'Profanity' && ToastsStore.error(errorMessage, 8000, s.toast)
      handleToastFocus()
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure())
  }
}
