import { DefaultLayout } from 'layouts/DefaultLayout'
import { SignedInLayout } from 'layouts/SignedInLayout'
import { InvitationInfoModal, InfoModal, DialogModal, VideoCallPromptModal, MembershipSelectModal } from 'modals'

import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import { Home } from 'routes/Home'
import { Room } from 'routes/Room'
import { More } from 'routes/More'
import { Swipe } from 'routes/Swipe'
import { AboutMe } from 'routes/AboutMe'
import { Housing } from 'routes/Housing'
import { SetupMajors } from 'routes/SetupMajors'
import { VerifyInfo } from 'routes/VerifyInfo'
import { SocialAccounts } from 'routes/SocialAccounts'
import { SetSocialAccount } from 'routes/SetSocialAccount'
import { EditProfile } from 'routes/EditProfile'
import { ProfilePreview } from 'routes/ProfilePreview'
import { RoommateProfile } from 'routes/RoommateProfile'
import { PageNotFound } from 'routes/PageNotFound'
import { SavedSwipes } from 'routes/SavedSwipes'
import { Request } from 'routes/Request'
import { Chats } from 'routes/Chats'
import { Conversation } from 'routes/Conversation'
import { Community } from 'routes/Community'
import { NewCommunityThread } from 'routes/NewCommunityThread'
import { ThreadDetails } from 'routes/ThreadDetails'
import { Search } from 'routes/Search'
import { Filters } from 'routes/Filters'
import { NoNetwork } from 'routes/NoNetwork'
import { Questions } from 'routes/Questions'
import { Languages } from 'routes/Languages'
import { DeleteAccount } from 'routes/DeleteAccount'
import { Welcome } from 'routes/Welcome'

import { history } from 'redux/configureStore'

import {
  HOME,
  ROOM,
  MORE,
  SWIPE,
  CHATS,
  SEARCH,
  THREAD,
  REQUEST,
  HOUSING,
  FILTERS,
  WELCOME,
  NEW_POST,
  ABOUT_ME,
  COMMUNITY,
  LANGUAGES,
  QUESTIONS,
  SET_MAJORS,
  NO_NETWORK,
  VERIFY_INFO,
  SAVED_SWIPES,
  CONVERSATION,
  FORGOT_LOGIN,
  FILTERS_MAJORS,
  DELETE_ACCOUNT,
  FILTERS_HOUSING,
  PAGE_NOT_FOUND,
  SOCIAL_ACCOUNTS,
  PROFILE_PREVIEW,
  ROOMMATE_PROFILE,
  PROFILE_OVERVIEW,
  UPDATE_BASIC_INFO,
  SET_SOCIAL_ACCOUNTS,
} from 'constants/urls'
import { GlobalCss } from './styles'

import './app.module.scss'
import 'styles/core.scss'
import ForgotLogin from 'routes/ForgotLogin/components/ForgotLogin'

export const App = () => (
  <ConnectedRouter history={history}>
    <DefaultLayout>
      <Switch>
      <Route exact component={Home} path={HOME} />
      <Route exact component={ForgotLogin} path={FORGOT_LOGIN} />
        <Route exact component={NoNetwork} path={NO_NETWORK} />

        <SignedInLayout>
          <Switch>
            <Route exact component={Questions} path={QUESTIONS} />
            <Route exact component={VerifyInfo} path={VERIFY_INFO} />
            <Route exact component={EditProfile} path={PROFILE_OVERVIEW} />
            <Route exact component={Housing} path={HOUSING} />
            <Route exact component={AboutMe} path={ABOUT_ME} />
            <Route exact component={SetupMajors} path={SET_MAJORS} />
            <Route exact component={ProfilePreview} path={PROFILE_PREVIEW} />
            <Route component={Community} path={COMMUNITY} />
            <Route exact component={NewCommunityThread} path={NEW_POST} />
            <Route exact component={ThreadDetails} path={THREAD} />
            <Route exact component={Swipe} path={SWIPE} />
            <Route component={SavedSwipes} path={SAVED_SWIPES} />
            <Route exact component={Search} path={SEARCH} />
            <Route exact component={Filters} path={FILTERS} />
            <Route exact component={SetupMajors} path={FILTERS_MAJORS} />
            <Route exact component={Housing} path={FILTERS_HOUSING} />
            <Route exact component={More} path={MORE} />
            <Route component={Room} path={ROOM} />
            <Route exact component={VerifyInfo} path={UPDATE_BASIC_INFO} />
            <Route exact component={SetSocialAccount} path={SET_SOCIAL_ACCOUNTS} />
            <Route exact component={RoommateProfile} path={ROOMMATE_PROFILE} />
            <Route exact component={SocialAccounts} path={SOCIAL_ACCOUNTS} />
            <Route exact component={Request} path={REQUEST} />
            <Route exact component={Chats} path={CHATS} />
            <Route component={Conversation} path={CONVERSATION} />
            <Route exact component={Languages} path={LANGUAGES} />
            <Route exact component={Welcome} path={WELCOME} />
            <Route exact component={DeleteAccount} path={DELETE_ACCOUNT} />
            <Route component={PageNotFound} path={PAGE_NOT_FOUND} />
            <Redirect to={PAGE_NOT_FOUND} />
          </Switch>
        </SignedInLayout>

      </Switch>
      <InfoModal />
      <DialogModal />
      <VideoCallPromptModal />
      <InvitationInfoModal />
      <MembershipSelectModal />
    </DefaultLayout>
    <GlobalCss />
  </ConnectedRouter>
)
