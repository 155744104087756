import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { swipeTypes, swipeActions } = createActionsStructure('swipe', [
  { name: 'GET_SWIPE_DATA', async: true },
  { name: 'LIKE_ROOMMATE', async: true },
  { name: 'SKIP_ROOMMATE', async: true },
  { name: 'GET_SEARCH_VALUES', async: true },
  { name: 'SET_SEARCH_VALUES', async: true },
  { name: 'RESET_SWIPE_DATA' },
  { name: 'REMOVE_CARD' },
  { name: 'TICK_BADGE' },
])
