import PropTypes from 'prop-types'
import cn from 'classnames'

import s from './TextAreaInverted.module.scss'

const TextAreaInverted = ({ input, placeholder, meta: { touched, error }, errorMessage, label, labelClassName, textAreaClassName, maxLength, rightAdornment, disabled }) => {
  const isResError = typeof error === 'string'
  const isError = touched && error
  const errorId = `error-${input.name}`

  return (
    <div className={s.container}>
      <div className={cn(s.labelWrapper, isError && s.errorContainer)}>
        {label && (
          <label htmlFor={input.name} className={cn(s.label, labelClassName, isError && s.errorLabel)} id={input.name}>
            {label}
            
          </label>
        )}
        {rightAdornment && (
          <div className={cn(s.rightAdornment, isError && s.errorLabel)}>
            {rightAdornment}
          </div>
        )}
      </div>
      
      <textarea
        {...input}
        type='text'
        disabled={disabled}
        maxLength={maxLength || 1500}
        className={cn(s.input, textAreaClassName,  isError && s.errorInput, disabled && s.disabled)}
        placeholder={placeholder}
        aria-labelledby={input.name}
        aria-invalid={String(isError)}
        aria-describedby={isError ? errorId : undefined}
      />
      
      {isError && (
        <p className={s.helperText} id={errorId}>
          {isResError ? error : errorMessage}
        </p>
      )}
    </div>
  )
}

TextAreaInverted.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func
  }),
  placeholder: PropTypes.string
}

TextAreaInverted.defaultProps = {
  input: {
    value: '',
    onChange: null
  },
  placeholder: 'Type a description here'
}

export { TextAreaInverted }
