import { Map } from 'react-loadable'

export const prefetch = () =>
  import('./components/SavedSwipes')

export const SavedSwipes = Map({
  loader: {
    Module: prefetch
  },
  loading: () => null,
  render: ({ Module }, props) => <Module.default {...props} />
})
