import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './MatchesInfoModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class MatchesInfoModalView extends PureComponent {
  handleConfirm = () => {
    const { handleHide, onConfirm } = this.props
    onConfirm()
    handleHide()
  }

  render() {
    const { handleHide, show, confirmedMatchText } = this.props

    return (
      <Modal
        center
        focusTrapped
        open={show}
        onClose={handleHide}
        classNames={classNames}
      >
        <div className={s.container}>
          <h1 className={s.title}>{i18n.t('matches.current')}</h1>
          <div className={s.contentWrapper}>
            <p className={s.description}>
              {i18n.t('matches.currentInfo')}
            </p>
            <br />
            <h2 className={s.descriptionBold}>
              {i18n.t('matches.directions')}
            </h2>
            <br />
            <div className={s.description} dangerouslySetInnerHTML={{ __html: confirmedMatchText }} />
          </div>
          <div className={s.labels}>
            <Button className={s.optionLabel} onClick={handleHide}>Ok</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

MatchesInfoModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

MatchesInfoModalView.defaultProps = {
  title: 'Are you sure you want to logout?',
  confirmOption: 'Yes',
  cancelOption: 'No'
}

const MatchesInfoModal = connectModal({ name: 'matchesInfoModal' })(MatchesInfoModalView)

export { MatchesInfoModal }
