import Eye from 'static/images/eye-off.svg'
import { UPDATE_BASIC_INFO } from 'constants/urls'
import s from './HiddenDemoCard.module.scss'

import i18n from 'services/i18n'
import { Link } from 'components/Link'

const HiddenDemoCard = () => (
  <section className={s.container} tabIndex={0}>
    <img src={Eye} alt='Eye off icon' aria-hidden='true' rel='noreferrer' />
    <h2 className={s.title}>{i18n.t('profile.hidden')}</h2>
    <span className={s.desc}>{i18n.t('profile.hiddenInfo')}</span>
    <span className={s.desc}>{i18n.t('profile.unhideInfo')}</span>
    <span className={s.descNoMargin}>
      {i18n.t('profile.byUpdating')}
      <Link to={UPDATE_BASIC_INFO} aria-label={`${i18n.t('profile.unhideInfo')} ${i18n.t('profile.byUpdating')} ${i18n.t('profile.here')}`}>{i18n.t('profile.here')}</Link></span>
  </section>
)

export { HiddenDemoCard }
