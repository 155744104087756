import createTheme from '@material-ui/core/styles/createTheme'
import { MuiThemeProvider } from "@material-ui/core/styles"
import responsiveFontSizes from '@material-ui/core/styles/responsiveFontSizes'
import { useRouteMatch } from 'react-router'

import { HOME } from 'constants/urls'
import { Helmet } from 'components/Helmet'
import { NavigationSideMenu } from 'components/NavigationSideMenu'
import { BestA11yExperienceDesc } from 'components/BestA11yExperienceDesc'
import { AccessibilityJumpNavigation } from 'components/AccessibilityJumpNavigation'

import { COLOR_PRIMARY, COLOR_SECONDARY } from 'styles/constants'

import s from './defaultLayout.module.scss'

const theme = createTheme({
  typography: {
    fontFamily: ["Work Sans, sans-serif"]
  },
  palette: {
    primary: {
      main: COLOR_PRIMARY,
    },
    secondary: {
      main: COLOR_SECONDARY,
    },
  },
});

export const MUITheme = responsiveFontSizes(theme);

export const DefaultLayout = props => {
  const homePageMatch = useRouteMatch(HOME)

  return (
    <MuiThemeProvider theme={MUITheme}>
      <Helmet />
      <BestA11yExperienceDesc />
      {!homePageMatch?.isExact && (
        <AccessibilityJumpNavigation />
      )}
      <aside>
        <NavigationSideMenu />
      </aside>
      <div className={s.content}>
        {props.children}
      </div>
    </MuiThemeProvider>
  )
}