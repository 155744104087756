import { makeStyles, Typography, Grid, Button } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview'
import SettingsMenu from './SettingsMenu/SettingsMenu'
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton'
import { useAppState } from '../../../state'
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext'
import roommateSelectors from 'redux/selectors/RoommateSelectors'
import profileSelectors from 'redux/selectors/ProfileSelectors'
import chatSelectors from 'redux/selectors/ChatSelectors'
import { usePrevious } from 'hooks/usePrevious'
import i18n from 'services/i18n'

import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { hide } from 'redux-modal'
import { createStructuredSelector } from 'reselect'

import s from 'modals/VideoRoomModal/VideoRoomModal.module.scss'

const useStyles = makeStyles(() => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  deviceButton: {
    flex: 1,
    border: '2px solid #aaa',
    margin: '0.5em',
  },
  localPreviewContainer: {
    width: '100%'
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const DeviceSelectionScreen = ({
  token, hideModal, name, myName, sendMessage, show,
  isLastMessageJoined, isLastMessageDeclined, isLastMessageStarted
}) => {
  const prevIsLastMessageDeclined = usePrevious(isLastMessageDeclined)
  const classes = useStyles()
  const { isFetching } = useAppState()
  const [isPending, setIsPending] = useState(false)
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext()
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting

  const handleJoin = () => {
    if (isLastMessageStarted) {
      sendMessage(`${myName} joined a call 📞`)
      videoConnect(token)
    } else {
      sendMessage(`${myName} started a call 📞`)
      setIsPending(true)
    }
  }

  const handleCancel = () => {
    hideModal('videoRoomModal')
    removeLocalVideoTrack()
    removeLocalAudioTrack()
  }

  const handleEnd = () => {
    hideModal('videoRoomModal')
    sendMessage(`${myName} ended a call 📞`)
    removeLocalVideoTrack()
    removeLocalAudioTrack()
  }

  useEffect(() => {
    if (show && isLastMessageDeclined && prevIsLastMessageDeclined === false) {
      hideModal('videoRoomModal')
      removeLocalVideoTrack()
      removeLocalAudioTrack()
    }
  }, [isLastMessageDeclined])

  useEffect(() => {
    if (show && isPending && isLastMessageJoined) {
      videoConnect(token)
    }
  }, [isLastMessageJoined])

  if (isFetching || isConnecting || isPending) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div className={classes.loadingWrapper}>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
            {
              isLastMessageJoined
                ? i18n.t('video.joning')
                : i18n.t('video.calling')
            }
          </Typography>
          {!isLastMessageJoined && (
            <Button
              data-cy-cancel
              onClick={handleEnd}
              className={classes.deviceButton}
              disabled={disableButtons}
            >
              {i18n.t('video.cancel')}
            </Button>
          )}
        </div>
      </Grid>
    )
  }

  return (
    <div>
      <div className={s.titles}>
        <h2 id="modal-title" className={s.title}>
          {
            isLastMessageStarted
              ? i18n.t('video.joinTitle')
              : i18n.t('video.callTitle')
          }
        </h2>
        <h2 id="modal-title" className={s.title}>{name}</h2>
      </div>
      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview />
      </div>
      <Grid container direction="row" justifyContent="space-around">
        <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
        <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
      </Grid>
      <Grid container direction="row" justifyContent="space-around">
        <SettingsMenu />
      </Grid>
      <Grid container direction="row" justifyContent="space-around">
        <Button
          data-cy-cancel
          onClick={handleCancel}
          className={classes.deviceButton}
          disabled={disableButtons}
        >
          {i18n.t('video.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          data-cy-join-now
          onClick={handleJoin}
          className={classes.deviceButton}
          disabled={disableButtons}
        >
          {isLastMessageStarted ? i18n.t('video.join') : i18n.t('video.call')}
        </Button>
      </Grid>
    </div>
  )
}

const mapDispatchToProps = {
  hideModal: hide
}

const mapStateToProps = createStructuredSelector({
  isLastMessageDeclined: chatSelectors.isLastMessageDeclinedSelector,
  isLastMessageStarted: chatSelectors.isLastMessageStartedSelector,
  isLastMessageJoined: chatSelectors.isLastMessageJoinedSelector,
  name: roommateSelectors.roommateNameSelector,
  myName: profileSelectors.name,
  token: chatSelectors.twilioTokenSelector
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSelectionScreen)