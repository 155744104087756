import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from "@sentry/tracing"
import { Provider } from 'react-redux'
import Mixpanel from 'mixpanel-browser'
import TagManager from 'react-gtm-module'
import { PersistGate } from 'redux-persist/integration/react'
import createStore from 'redux/configureStore'
import { App } from 'components/App'
import GitInfo from 'react-git-info/macro'
import { ScreenTracker } from './ScreenTracker'
import { Zendesk } from './components/Zendesk'
import 'styles/_base.scss'

const initialState = window.__INITIAL_STATE__
const gitInfo = GitInfo();
const { store, persistor } = createStore(initialState)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://c422f39852d14b4d8cea013ae562e696@o227670.ingest.sentry.io/5710005",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
TagManager.initialize({gtmId: process.env.REACT_APP_GTM_ID})

Mixpanel.init(process.env.REACT_APP_MIXPANEL)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      {/* eslint-disable-next-line */}
      <div style={{ display: 'none' }}>{gitInfo.commit.hash}</div>
      <Zendesk />
      <ScreenTracker />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

export { store }
