import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
    fontFamily: "inherit"
  },
  arrow: {
    color: theme.palette.common.black,
  },
  wrapper: {
    minWidth: "auto"
  }
}));
