import React from 'react';
import cn from 'classnames';
import Switch from '@material-ui/core/Switch';
import { WrappedFieldProps } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import { COLOR_GREEN, COLOR_GREY, COLOR_RED_DARKEST, COLOR_SECONDARY, COLOR_WHITE } from 'styles/constants';

interface IProps extends WrappedFieldProps {
  label: string
  labelOn: string
  labelOff: string
  backgroundColor?: string
}

const useSwitchStyles = (backgroundColor = COLOR_WHITE) => makeStyles({
  root: {
    height: 33,
    width: 62,
    padding: 0,
    marginTop: 10,
    overflow: "visible",
  },
  track: {
    borderRadius: 57,
    opacity: 1,
    backgroundColor,
  },
  switchBase: {
    padding: 0,
    color: COLOR_WHITE,
    '&$checked': {
      color: COLOR_WHITE,
    },
    '&$checked + $track': {
      opacity: 1,
      backgroundColor,
    },
  },
  checked: {},
});

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 44,
    height: 33,
    fontSize: 15,
    fontWeight: 600,
    borderRadius: 33,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${COLOR_GREY}`,
    transition: theme.transitions.create(['background-color']),
  },
  iconOn: {
    backgroundColor: COLOR_GREEN,
  },
  iconOff: {
    backgroundColor: COLOR_RED_DARKEST,
  },
  focusVisible: {
    outline: `2px auto ${COLOR_SECONDARY}`,
  }
}));

const Icon = ({ label, className }) => (
  <div className={className}>
    {label}
  </div>
)

export const SwitchInput: React.FC<IProps> = props => {
  const switchClasses = useSwitchStyles(props.backgroundColor)()
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    props.input.onChange(event.target.checked);

  return (
    <Switch
      {...props.input}
      disableRipple
      classes={switchClasses}
      onChange={handleChange}
      checked={props.input.value}
      inputProps={{ 'aria-label': props.label }}
      focusVisibleClassName={classes.focusVisible}
      icon={<Icon label={props.labelOff} className={cn(classes.icon, classes.iconOff)} />}
      checkedIcon={<Icon label={props.labelOn} className={cn(classes.icon, classes.iconOn)} />}
    />
  );
};
