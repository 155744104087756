import { SwitchInput } from 'components/SwitchInput'
import T from 'ramda/es/T'

import s from './KnownSwitch.module.scss'

export const KnownSwitch = ({ label, value, onClick }) => (
  <div className={s.option} onClick={onClick}>
    <span className={s.optionLabel} dangerouslySetInnerHTML={{ __html: label }} />
    <SwitchInput
      labelOn='Yes'
      labelOff='No'
      backgroundColor='#eee'
      input={{
        onChange: T,
        value
      }}
    />
  </div>
)
