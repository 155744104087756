import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'

import images from 'static'
import { MatchAvatar } from 'components'
import { Button } from 'components/Button'
import { ROOMMATE_PROFILE, SAVED_SWIPES_LIKED_TAB } from 'constants/urls'
import i18n from 'services/i18n'

import s from './MatchModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class MatchModalView extends PureComponent {
  navToProfile = () => {
    const { handleHide, roommateId, history } = this.props

    handleHide()
    history.push(ROOMMATE_PROFILE.replace(':roommateId', roommateId))
  }

  navToMutual = () => {
    const { handleHide, history } = this.props

    history.replace(SAVED_SWIPES_LIKED_TAB + '#' + Math.random())

    handleHide()
  }

  render() {
    const { handleHide, show, avatar, firstName, matchValue } = this.props

    return (
      <Modal focusTrapped center open={show} onClose={handleHide} classNames={classNames}>
        <div className={s.container} tabIndex={0}>
          <img alt="" aria-hidden="true" src={images.backgroundSquares} className={s.imageBackground} />
          <MatchAvatar data={{ avatar: avatar || images.defaultAvatar, match: matchValue }} />
          <span aria-hidden="true" className={s.match}>{matchValue}%</span>
          <span aria-hidden="true" className={s.matchLabel}>{i18n.t('matches.compatible')}</span>
          <span className={s.blueInfo}>{i18n.t('matches.likes', { firstName })}</span>
          <Button className={s.viewProfile} onClick={this.navToProfile}>
            <span className={s.label}>{i18n.t('matches.view', { firstName })}</span>
          </Button>
          <Button
            className={s.keepSwiping}
            onClick={this.navToMutual}
          >
            {i18n.t('matches.showProfiles')}
          </Button>
        </div>
      </Modal>
    )
  }
}

MatchModalView.propTypes = {
  handleHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired,
  matchValue: PropTypes.string.isRequired
}

const MatchModal = connectModal({ name: 'matchModal' })(withRouter(MatchModalView))

export { MatchModal }
