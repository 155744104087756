import React from "react";

import { ExternalLink } from "./ExternalLink";
import { RouterLink } from "./RouterLink";

import { IProps } from "./types";

export const Link: React.FC<IProps> = React.memo(props => {
  if (props.to) {
    return <RouterLink {...props as any} />; // TODO: remove any
  }

  if (props.href) {
    return <ExternalLink {...props as any} />; // TODO: remove any
  }

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a {...props} />;
});
