import { PureComponent } from 'react'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import VideoApp from 'lib/twilio-video-app/src'

import s from './VideoRoomModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }
const classNamesConnected = { closeButton: s.closeButton, modal: s.modalConnected, overlay: s.modalOverlayConnected }

class VideoRoom extends PureComponent {
  state = {
    currentClassNames: classNames
  }

  setClassNames = (isDisconnected) => {
    if (isDisconnected) {
      this.setState({
        currentClassNames: classNames
      })
    } else {
      this.setState({
        currentClassNames: classNamesConnected
      })
    }
  }

  onClose = (event) => {
    if (event.type === 'keydown' && event.keyCode === 27) return

    this.props.handleHide()
  }

  render() {
    const { show } = this.props

    return (
      <Modal
        center
        open={show}
        focusTrapped
        onClose={this.onClose}
        closeOnOverlayClick={false}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        classNames={this.state.currentClassNames}
      >
        <VideoApp
          show={show}
          match={this.props.match}
          setClassNames={this.setClassNames}
          sendMessage={this.props.sendMessage}
        />
      </Modal>
    )
  }
}

const VideoRoomModal = connectModal({ name: 'videoRoomModal' })(VideoRoom)

export { VideoRoomModal }
