import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-responsive-modal'
import AvatarEditor from 'react-avatar-editor'

import { Button } from 'components/Button'

import s from './ProfileAvatarModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

export class ProfileAvatarModalView extends PureComponent {
  handleConfirm = () => {
    const { handleHide } = this.props
    const img = this.editor.getImageScaledToCanvas()

    img.toBlob(blob => {
      handleHide()

      this.props.uploadProfileImage({ image: blob })
    })
  }

  componentDidMount() {
  }

  handleRef = (ref) => {
    this.editor = ref
  }

  render() {
    const { handleHide, show, image } = this.props

    return (
      <Modal
        center
        open={show}
        focusTrapped={true}
        onClose={handleHide}
        classNames={classNames}
      >
        <div className={s.container}>
          <AvatarEditor
            ref={this.handleRef}
            image={image}
            width={250}
            height={250}
            border={25}
            borderRadius={125}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1.2}
            rotate={0}
          />
          <div className={s.viewProfile} onClick={this.handleConfirm}>
            <Button className={s.label} aria-label="Confirm avatar upload">OK</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

ProfileAvatarModalView.propTypes = {
  handleHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  avatar: PropTypes.string.isRequired
}
