import { createReducer } from 'services/reduxHelpers'
import { settingsTypes } from '../actions/SettingsActions'

/* ------------- Initial State ------------- */

export const initialState = {
  swipeFirstLook: true
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [settingsTypes.CHANGE_SWIPE_FIRST_LOOK_ATTEMPT]: () => ({
    swipeFirstLook: false
  })
}, false)
