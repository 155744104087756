export const HOME = '/'
export const ABOUT_ME = '/about-me'
export const PROFILE_OVERVIEW = `/profile-overview`
export const SET_MAJORS = '/set-majors'
export const PROFILE_PREVIEW = '/profile-preview'
export const TERMS_OF_SERVICE = `/terms-of-service`
export const SOCIAL_ACCOUNTS = '/social-accounts'
export const SET_SOCIAL_ACCOUNTS = '/social-accounts/:type'
export const HOUSING = `/housing`
export const VERIFY_INFO = `/verify-info`
export const MORE = `/more`
export const UPDATE_BASIC_INFO = `/update-basic-info`
export const PAGE_NOT_FOUND = `/page-not-found`
export const ROOM = `/matches`
export const ROOM_INCOMING_REQUETS = `/matches/incoming-requests`
export const ROOM_OUTGOING_REQUETS = `/matches/outgoing-requests`
export const SWIPE = `/find`
export const ROOMMATE_PROFILE = '/profile/:roommateId'
export const ROOMMATE_PROFILE_ROUTE = '/profile/'
export const SAVED_SWIPES = '/saved/'
export const SAVED_SWIPES_SKIPPED_TAB = '/saved/skipped'
export const SAVED_SWIPES_LIKED_TAB = '/saved/liked'
export const SAVED_SWIPES_MUTUAL_TAB = '/saved/mutual'
export const REQUEST = '/request/:roommateId'
export const REQUEST_ROUTE = '/request/'
export const CHATS = `/chat`
export const COMMUNITY = `/community`
export const COMMUNITY_INFO = `/community/community-info`
export const COMMUNITY_POSTS = `/community/community-posts`
export const CONVERSATION = '/chat/conversation/:chatId'
export const CONVERSATION_ROUTE = '/chat/conversation/'
export const CONVERSATION_ROUTE_ID = '/chat/conversation/:id'
export const NEW_POST = `/new-post`
export const THREAD = '/thread/:threadId'
export const THREAD_ROUTE = '/thread/'
export const FILTERS_MAJORS = '/filters-majors'
export const FILTERS_HOUSING = '/filters-housing'
export const SEARCH = '/search'
export const FILTERS = '/filters'
export const NO_NETWORK = '/no-network'
export const QUESTIONS = '/questions'
export const LANGUAGES = '/languages'
export const DELETE_ACCOUNT = '/delete-account'
export const WELCOME = '/welcome'
export const FORGOT_LOGIN = '/forgot-login'

export const ROUTES = [
  ABOUT_ME,
  PROFILE_OVERVIEW,
  SET_MAJORS,
  PROFILE_PREVIEW,
  TERMS_OF_SERVICE,
  SOCIAL_ACCOUNTS,
  HOUSING,
  MORE,
  UPDATE_BASIC_INFO,
  ROOM,
  SWIPE,
  SAVED_SWIPES,
  CHATS,
  COMMUNITY,
  FILTERS_MAJORS,
  FILTERS_HOUSING,
  SEARCH,
  QUESTIONS,
  WELCOME,
  FORGOT_LOGIN
]
