import { makeStyles } from '@material-ui/core/styles';

import { COLOR_BLUE_LIGHT, COLOR_WHITE, COLOR_SECONDARY, COLOR_RED, COLOR_GREY_DARK } from 'styles/constants';

export const useSelectStyles = makeStyles({
  root: {
    fontSize: 18,
    fontWeight: 500,
    color: COLOR_WHITE,
    padding: `16px 0 12px`,
    borderBottom: `2px solid ${COLOR_BLUE_LIGHT}`,
    transition: "border-color 0.3s ease-in",
    "&:focus": {
      backgroundColor: "transparent",
      borderBottomColor: COLOR_SECONDARY,
    }
  },
  icon: {
    color: COLOR_WHITE,
    "&$disabled": {
      color: COLOR_GREY_DARK,
    }
  },
  disabled: {
    color: COLOR_GREY_DARK
  }
});

export const useStyles = makeStyles({
  error: {
    borderBottomColor: COLOR_RED,
  }
});
