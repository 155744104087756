import cn from 'classnames'
import PropTypes from 'prop-types'

import s from './Input.module.scss'

const Input = ({
  rightAdornment,
  disabled,
  label,
  input,
  type,
  maxLength,
  meta: { touched, error },
  placeholder,
  errorMessage
}) => {
  const isResError = typeof error === 'string'
  const errorId = `error-${input.name}`
  const isError = touched && error

  return (
    <div className={s.container}>
      <label className={cn(s.label, isError && s.errorLabel)} htmlFor={input.name}>
        {label}
      </label>
      <input
        {...input}
        type={type}
        maxLength={maxLength}
        id={input.name}
        disabled={disabled}
        placeholder={placeholder}
        aria-invalid={String(isError)}
        aria-describedby={isError ? errorId : undefined}
        className={cn(isError && s.error, disabled && s.disabled, !!rightAdornment && s.paddingRight)}
      />
      {rightAdornment && (
        <div className={s.rightAdornment}>
          {rightAdornment}
        </div>
      )}
      {isError && (
        <p className={s.helperText} id={errorId}>
          {isResError ? error : errorMessage}
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ])
  }).isRequired,
  placeholder: PropTypes.string
}

Input.defaultProps = {
  type: 'text',
  placeholder: ''
}

export { Input }
