import { createReducer } from 'services/reduxHelpers'
import { roommateTypes } from '../actions/RoommateActions'
import mergeDeepRight from 'ramda/es/mergeDeepRight'
import is from 'ramda/es/is'
import assocPath from 'ramda/es/assocPath'

import { roomTypes } from '../actions/RoomActions'

/* ------------- Initial State ------------- */

export const initialState = {
  roommates: {},
  loadings: {},
  requestLoadings: {},
  requestErrors: {}
}

/* ------------- Hookup Reducers To Types ------------- */

const requestAttempt = ({ requestLoadings, requestErrors }, { data: { roommateId } }) => ({
  requestLoadings: {
    ...requestLoadings,
    [roommateId]: true
  },
  requestErrors: {
    ...requestErrors,
    [roommateId]: false
  }
})

const requestSuccess = ({ requestLoadings, roommates, requestErrors }, { data }) => ({
  requestLoadings: {
    ...requestLoadings,
    [data.roommateId]: false
  },
  roommates: {
    ...roommates,
    [data.roommateId]: {
      ...roommates[data.roommateId],
      ...data
    }
  },
  requestErrors: {
    ...requestErrors,
    [data.roommateId]: false
  }
})

const requestFailure = ({ requestLoadings, requestErrors }, { data: { roommateId, error } }) => ({
  requestLoadings: {
    ...requestLoadings,
    [roommateId]: false
  },
  requestErrors: {
    ...requestErrors,
    [roommateId]: error || true
  }
})

export default createReducer(initialState, {
  [roommateTypes.GET_PROFILE_ATTEMPT]: ({ loadings, roommates }, { data: { roommateId } }) => ({
    loadings: {
      ...loadings,
      [roommateId]: !roommates[roommateId]
    }
  }),
  [roommateTypes.GET_PROFILE_SUCCESS]: ({ loadings, roommates }, { data }) => ({
    loadings: {
      ...loadings,
      [data.roommateId]: false
    },
    roommates: {
      ...roommates,
      [data.roommateId]: data
    }
  }),
  [roommateTypes.GET_PROFILES_SUCCESS]: ({ loadings, roommates }, { data }) => ({
    loadings: {
      ...loadings,
      [data.roommateId]: false
    },
    roommates: mergeDeepRight(
      roommates,
      data
    )
  }),
  [roommateTypes.GET_PROFILE_FAILURE]: ({ loadings }, { data: { roommateId } }) => ({
    loadings: {
      ...loadings,
      [roommateId]: false
    }
  }),
  [roommateTypes.RESET_REQUEST_ERROR_ATTEMPT]: ({ requestErrors }, { data: { roommateId } }) => ({
    requestErrors: {
      ...requestErrors,
      [roommateId]: false
    }
  }),

  [roommateTypes.PUT_ROOMMATE_DATA_ATTEMPT]: ({ roommates }, { data }) => {
    return is(Array, data)
      ? ({
        roommates: {
          ...roommates,
          ...data.reduce((pv, cv) => ({
            ...pv,
            [cv.membership_id || cv.membershipId]: {
              ...(roommates[cv.membership_id || cv.membershipId] || {}),
              ...cv
            }
          }), {})
        }
      })
      : ({
        roommates: {
          ...roommates,
          [data.membership_id || data.membershipId]: {
            ...(roommates[data.membership_id || data.membershipId] || {}),
            ...data
          }
        }
      })
  },

  [roommateTypes.DENY_REQUEST_ATTEMPT]: requestAttempt,
  [roommateTypes.DENY_REQUEST_SUCCESS]: requestSuccess,
  [roommateTypes.DENY_REQUEST_FAILURE]: requestFailure,

  [roommateTypes.ACCEPT_REQUEST_ATTEMPT]: requestAttempt,
  [roommateTypes.ACCEPT_REQUEST_SUCCESS]: requestSuccess,
  [roommateTypes.ACCEPT_REQUEST_FAILURE]: requestFailure,

  [roommateTypes.CANCEL_REQUEST_ATTEMPT]: requestAttempt,
  [roommateTypes.CANCEL_REQUEST_SUCCESS]: requestSuccess,
  [roommateTypes.CANCEL_REQUEST_FAILURE]: requestFailure,

  [roommateTypes.SEND_REQUEST_ATTEMPT]: requestAttempt,
  [roommateTypes.SEND_REQUEST_SUCCESS]: requestSuccess,
  [roommateTypes.SEND_REQUEST_FAILURE]: requestFailure,

  [roomTypes.SET_KNOWN_ATTEMPT]: (state, { data }) => {

    if (data.rootId) {
      // update roommates array
      const index = (state.roommates[data.rootId].roommates || []).findIndex(({ membership_id }) => membership_id === data.id)

      if (index >= 0) {
        return assocPath(['roommates', data.rootId, 'roommates', index, 'previouslyKnown'], data.previouslyKnown, state)
      }

      return null
    }

    // update main roommate
    return ({
      roommates: {
        ...state.roommates,
        [data.id]: {
          ...state.roommates[data.id],
          previouslyKnown: data.previouslyKnown
        }
      },
    })
  }
})
