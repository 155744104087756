import assocPath from 'ramda/es/assocPath'
import { createReducer } from 'services/reduxHelpers'
import { roomTypes } from '../actions/RoomActions'
import { roommateTypes } from '../actions/RoommateActions'

/* ------------- Initial State ------------- */

export const initialState = {
  isLoading: true,
  isRoomLeaveLoading: false,
  title: null,
  myRoommtates: [],
  isRoommateLoading: false,
  incomingRequests: [],
  outgoingRequests: []
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [roomTypes.GET_ROOM_CONTENT_ATTEMPT]: ({ title }) => ({
    isLoading: !title
  }),
  [roomTypes.GET_ROOM_CONTENT_SUCCESS]: (_, { data }) => ({
    ...data,
    isLoading: false
  }),
  [roomTypes.GET_ROOM_CONTENT_FAILURE]: () => ({
    isLoading: false
  }),

  [roomTypes.LEAVE_ROOM_ATTEMPT]: () => ({
    isRoomLeaveLoading: true
  }),
  [roomTypes.LEAVE_ROOM_SUCCESS]: () => ({
    myRoommtates: [],
    isRoomLeaveLoading: false
  }),
  [roomTypes.LEAVE_ROOM_FAILURE]: () => ({
    isRoomLeaveLoading: false
  }),

  [roomTypes.APPEND_OUTCOMING_REQUEST_ATTEMPT]: ({ outgoingRequests = [] }, { data }) => ({
    outgoingRequests: [data, ...outgoingRequests]
  }),
  [roommateTypes.CANCEL_REQUEST_SUCCESS]: ({ outgoingRequests = [] }, { data }) => ({
    outgoingRequests: [...outgoingRequests.filter(({ membership_id: uId }) => String(uId) !== String(data.roommateId))]
  }),
  [roommateTypes.DENY_REQUEST_SUCCESS]: ({ incomingRequests = [] }, { data }) => ({
    incomingRequests: [...incomingRequests.filter(({ membership_id: uId }) => String(uId) !== String(data.roommateId))]
  }),

  [roomTypes.SET_ROOMMATE_ATTEMPT]: () => ({
    isRoommateLoading: true
  }),
  [roomTypes.SET_ROOMMATE_SUCCESS]: (_, { data }) => ({
    myRoommtates: data,
    isRoommateLoading: false
  }),
  [roomTypes.SET_ROOMMATE_FAILURE]: () => ({
    isRoommateLoading: false
  }),
  [roomTypes.SET_KNOWN_ATTEMPT]: (state, { data }) => {
    const index = (state.myRoommtates || []).findIndex(({ membership_id }) => membership_id === data.id)

    if (index >= 0) {
      return assocPath(['myRoommtates', index, 'previouslyKnown'], data.previouslyKnown, state)
    }

    return null
  }
})
