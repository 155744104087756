import React from 'react'
import path from 'ramda/es/path'
import cn from 'classnames'

import images from 'static'
import { Button } from 'components/Button'
import { formatDate } from 'services/helpers'
import i18n from 'services/i18n'

import s from './PostItem.module.scss'

export const PostItem = ({ user, body, date_created: dc, onClick, className, focus, isFirstPost }) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [focus]);

  return (
    <li
      ref={ref}
      tabIndex={focus ? 0 : -1}
      className={cn(s.container, className)}
    >
      <img
        alt=""
        aria-hidden="true"
        className={s.avatar} rel='noreferrer'
        src={path(['image_urls', 'square'], user) || images.defaultAvatar}
      />
      <div className={s.content}>
        <time className={s.date} dateTime={formatDate(new Date().setTime(dc))} aria-label={`Created at ${formatDate(new Date().setTime(dc))}`}>
          {formatDate(new Date().setTime(dc))}
        </time>
        {!isFirstPost && (
          <p className={s.message}>{body}</p>
        )}
        {isFirstPost && (
          <h2 className={s.message}>{body}</h2>
        )}
        <small className={s.by}>
          {i18n.t('thread.by')} {<Button tabIndex={focus ? 0 : -1} role="link" onClick={() => onClick(user.user_id)} className={s.name}>{user.first_name} {user.last_name}</Button>}
        </small>
      </div>
    </li>
  )

}