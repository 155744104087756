import React, { Component, cloneElement } from 'react'
import ReactDOM from 'react-dom'
import { DIRECTIONS } from './utils'

class SwipeCards extends Component {
  state = {
    index: 0,
    containerSize: { x: 0, y: 0 }
  }

  removeCard = (side, card) => {
  }

  componentDidMount () {
    this.setSize()
    window.addEventListener('resize', this.setSize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setSize)
  }

  setSize = () => {
    const container = ReactDOM.findDOMNode(this)
    if (!container) return

    const containerSize = {
      x: container.offsetWidth,
      y: container.offsetHeight
    }

    this.setState(state => ({ ...state, containerSize }))
  }

  renderChildren = () => {
    const { props: { children }, state: { containerSize, index } } = this

    return children.reduce((memo, c, i) => {
      if (index > i) return memo

      const props = {
        key: c.key,
        containerSize,
        childCount: children.length,
        index: children.length - index,
        visibleIndex: i - index + 1,
        i: i,
        ...DIRECTIONS.reduce((m, d) => ({
          ...m,
          [`onOutScreen${d}`]: () => this.removeCard(d, c)
        }), {}),
        active: index === i
      }

      return [cloneElement(c, props), ...memo]
    }, [])
  }

  render () {
    const { props: { className }, state: { containerSize } } = this

    return (
      <div className={className}>
        {containerSize.x && containerSize.y && <span id='cards'> {this.renderChildren()} </span>}
      </div>
    )
  }
}

export default SwipeCards
