const getState = state => state.home
const isFacebookLoadingSelector = state => getState(state).isFacebookLoading
const isGoogleLoadingSelector = state => getState(state).isGoogleLoading
const isAppleLoadingSelector = state => getState(state).isAppleLoading
const invitationDataSelector = state => getState(state).invitationData
const isInvitationLoadingSelector = state => getState(state).isInvitationLoading
const invitationLoginMethodSelector = state => getState(state).loginMethod
const loginTypeSelector = state => getState(state).loginType
const initialRouteSelector = state => getState(state).initialRoute
const isLoginMethodLoadingSelector = state => getState(state).isLoginMethodLoading
const lastCheckedDateSelector = state => getState(state).lastCheckedDate

export default {
  loginTypeSelector,
  isFacebookLoadingSelector,
  isGoogleLoadingSelector,
  isAppleLoadingSelector,
  isInvitationLoadingSelector,
  invitationDataSelector,
  initialRouteSelector,
  invitationLoginMethodSelector,
  isLoginMethodLoadingSelector,
  lastCheckedDateSelector
}
