import propOr from 'ramda/src/propOr'
import compose from 'ramda/src/compose'
import includes from 'ramda/src/includes'

import AboutMe from 'static/images/aboutMe.svg'
import Academic from 'static/images/academic.svg'
import Housing from 'static/images/housing.svg'
import Social from 'static/images/social-icon.svg'
import Chevron from 'static/images/chevron.svg'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './MyProfileOptionCards.module.scss'

const sources = [
  { name: 'matching_group', Icon: AboutMe },
  { name: 'majors', Icon: Academic },
  { name: 'housing', Icon: Housing },
  { name: 'social_accounts', Icon: Social }
]
const getTranslations = () => ([
  { name: 'matching_group', value: i18n.t('preview.about') },
  { name: 'majors', value: i18n.t('preview.majors') },
  { name: 'housing', value: i18n.t('preview.housing') },
  { name: 'social_accounts', value: i18n.t('preview.social') }
])

const renderIcon = (url) => {
  const { Icon } = sources.find(({ name }) => url.includes(name))

  return (
    <img src={Icon} tabIndex={-1} alt={url} />
  )
}

const sectionsFilerPredicate = compose(
  includes('omplete'),
  propOr("", "status")
)

export const MyProfileOptionCards = ({ onClick, sections }) => (
  <div className={s.container}>
    <div className={s.title}>{i18n.t('profile.settings')}</div>
    {sections.filter(sectionsFilerPredicate)
      .map(({ label, url }) => (
        <div key={url} className={s.item}>
          <Button
            role="link"
            aria-label={label}
            className={s.button}
            onClick={() => onClick(url)}
          >
            {renderIcon(url)}
            <div className={s.contentWrapper}>
              <span className={s.label}>
                {getTranslations().find(({ name }) => url.includes(name))?.value}
              </span>
              <img
                alt={label}
                tabIndex={-1}
                aria-hidden="true"
                className={s.chevron}
                src={Chevron}
              />
            </div>
          </Button>
        </div>
      ))}
  </div>
)
