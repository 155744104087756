import cn from 'classnames'
import PropTypes from 'prop-types'

import s from './ActivityIndicator.module.scss'

const ActivityIndicator = ({ blue, className }) => (
  <div
    tabIndex={0}
    role="status"
    aria-busy="true"
    aria-live="polite"
    aria-label="Loading"
  >
    <div className={cn(s.spinner, className)}>
      <div className={cn(s.dot1, blue && s.blue)}></div>
      <div className={cn(s.dot2, blue && s.blue)}></div>
    </div>
  </div>
)

ActivityIndicator.propTypes = {
  blue: PropTypes.bool,
  className: PropTypes.string
}

ActivityIndicator.defaultProps = {
  blue: false,
  className: null
}

export { ActivityIndicator }
