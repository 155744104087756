import cn from 'classnames'

import Academic from 'static/images/academic-majors.svg'
import Housing from 'static/images/bed.svg'
import Social from 'static/images/social.svg'
import People from 'static/images/people2.svg'
import i18n from 'services/i18n'

import s from './PreviewCard.module.scss'

const sources = {
  roommates: People,
  social: Social,
  majors: Academic,
  housing: Housing,
}

const renderIcon = (type) => {
  const Icon = sources[type]

  if (Icon) {
    return (
      <img
        alt=""
        src={Icon}
        aria-hidden="true"
      />
    )
  }

  return null
}

export const PreviewCard = ({ type, children, label, className }) => {
  const labels = {
    about: i18n.t('preview.about'),
    roommates: i18n.t('preview.roommates'),
    social: i18n.t('preview.social'),
    majors: i18n.t('preview.majors'),
    housing: i18n.t('preview.housing')
  }

  return  (
    <section tabIndex={0} className={cn(s.container, className, type === 'social' && s.noBottomPadding)}>
      <header className={s.headerWrapper}>
        {renderIcon(type)}
        <h2 className={s.label}>{label || labels[type]}</h2>
      </header>
      {children}
    </section>
  )
}
