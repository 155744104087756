import { PureComponent } from 'react'
import always from 'ramda/es/always'
import cn from 'classnames'

import AppleIcon from 'static/images/appleIcon.svg'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './AppleLoginButton.module.scss'

export class AppleLoginButton extends PureComponent {
  onButtonClick = () => {
    const { onClick, disabled, isLoading } = this.props

    return (disabled || isLoading)
      ? always()
      : onClick()
  }

  getLabel = () => {
    const label = this.props.isRegister
      ? i18n.t('home.register')
      : i18n.t('home.signIn')

    return i18n.t('home.withApple', { label })
  }

  render() {
    const { invitationLoginMethod, disabled } = this.props

    return (
      <Button
        variant="contained"
        className={cn(s.container, disabled && invitationLoginMethod && s.disabled)}
        onClick={this.onButtonClick}
      >
        <img src={AppleIcon} className={s.icon} aria-hidden="true" alt="" />
        {this.getLabel()}
      </Button>
    )
  }
}
