import { useState, useEffect } from 'react'
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen'
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'

const PreJoin = ({ sendMessage, match, show }) => {
  const { getAudioAndVideoTracks } = useVideoContext()
  const [mediaError, setMediaError] = useState()

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:')
        console.dir(error)
        setMediaError(error)
      })
    }
  }, [mediaError])

  return (
    <>
      <MediaErrorSnackbar error={mediaError} />
      <DeviceSelectionScreen
        show={show}
        match={match}
        sendMessage={sendMessage}
      />
    </>
  )
}

export default PreJoin
