import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectModal, show } from 'redux-modal'
import { push } from 'connected-react-router'

import { ProfileAvatarModalView } from './ProfileAvatarModal'

import { profileActions } from 'redux/actions/ProfileActions'

const mapDispatchToProps = {
  push,
  showModal: show,
  uploadProfileImage: profileActions.uploadProfileImage
}

const enhance = compose(
  connect(null, mapDispatchToProps),
  connectModal({ name: 'profileAvatarModal' })
)

export const ProfileAvatarModal = enhance(ProfileAvatarModalView)
