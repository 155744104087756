import PropTypes from 'prop-types'
import { ZendeskAPI } from "react-zendesk/src"

import i18n from 'services/i18n'
import { RoundButton } from '../RoundButton'
import config from 'config.json'
import s from './Footer.module.scss'

export const Footer = ({ footerText, contactUs, email }) => (
  <footer className={s.container}>
    <p className={s.footerText}>
      {footerText}
    </p>
    <div className={s.contactUs}>
      <p>{contactUs}</p>
      <RoundButton onClick={() => ZendeskAPI('messenger', 'open')}>
        {i18n.t('verify.supportButton')}
      </RoundButton>
    </div>
  </footer>
)

Footer.propTypes = {
  footerText: PropTypes.string,
  contactUs: PropTypes.string,
  email: PropTypes.string
}

Footer.defaultProps = {
  contactUs: '',
  footerText: '',
  email: config.site.supportEmail
}
