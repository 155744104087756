import { createReducer } from 'services/reduxHelpers'
import { communityTypes } from '../actions/CommunityActions'

/* ------------- Initial State ------------- */

export const initialState = {
  isContentLoading: true,
  isThreadsLoading: true,
  isCreateThreadLoading: false,
  created: false,
  content: {},
  threadsIds: [],
  threads: {},
  posts: {},
  loadingStates: {}
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [communityTypes.GET_CONTENT_ATTEMPT]: () => ({
    isContentLoading: true
  }),
  [communityTypes.GET_CONTENT_SUCCESS]: (_, { data }) => ({
    content: data,
    isContentLoading: false
  }),
  [communityTypes.GET_CONTENT_FAILURE]: () => ({
    isContentLoading: false
  }),

  [communityTypes.GET_THREADS_ATTEMPT]: ({ threads }) => ({
    isThreadsLoading: !threads.length
  }),
  [communityTypes.GET_THREADS_SUCCESS]: (_, { data }) => ({
    threads: data.threads,
    threadsIds: data.threadsIds,
    isThreadsLoading: false
  }),
  [communityTypes.GET_THREADS_FAILURE]: () => ({
    isThreadsLoading: false
  }),

  [communityTypes.GET_POSTS_ATTEMPT]: ({ posts, loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      [data.threadId]: !(posts[data.threadId] || []).length
    }
  }),
  [communityTypes.GET_POSTS_SUCCESS]: ({ loadingStates, posts, threads }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      [data.threadId]: false
    },
    posts: {
      ...posts,
      [data.threadId]: data.items
    },
    threads: {
      ...threads,
      [data.threadId]: data.thread
    }
  }),
  [communityTypes.GET_POSTS_FAILURE]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      [data.threadId]: false
    }
  }),

  [communityTypes.CREATE_THREAD_ATTEMPT]: () => ({
    isCreateThreadLoading: true
  }),
  [communityTypes.CREATE_THREAD_SUCCESS]: () => ({
    isCreateThreadLoading: false
  }),
  [communityTypes.CREATE_THREAD_FAILURE]: () => ({
    isCreateThreadLoading: false
  }),

  [communityTypes.CREATE_POST_ATTEMPT]: () => ({
    created: false
  }),
  [communityTypes.CREATE_POST_SUCCESS]: ({ threads, posts }, { data }) => ({
    created: true,
    threads: {
      ...threads,
      [data.threadId]: {
        ...threads[data.threadId],
        post_count: +threads[data.threadId].post_count + 1,
        updatedAt: new Date()
      }
    },
    posts: {
      ...posts,
      [data.threadId]: [...(posts[data.threadId] || []), data.post]
    }
  }),
  [communityTypes.CREATE_POST_FAILURE]: () => ({
    created: false
  }),
})
