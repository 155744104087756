import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { show } from 'redux-modal'

import { SocialButtonsBase } from './SocialButtons'

import { homeActions } from 'redux/actions/HomeActions'
import homeSelectors from 'redux/selectors/HomeSelectors'

const mapStateToProps = createStructuredSelector({
  isAppleLoading: homeSelectors.isAppleLoadingSelector,
  isGoogleLoading: homeSelectors.isGoogleLoadingSelector,
  isFacebookLoading: homeSelectors.isFacebookLoadingSelector,
})

const mapDispatchToProps = {
  showModal: show,
  facebookLogin: homeActions.facebookLogin,
  googleLogin: homeActions.googleLogin,
  googleStartAuthFlow: homeActions.googleStartAuthFlow,
  googleEndAuthFlow: homeActions.googleEndAuthFlow
}

export const SocialButtons = connect(mapStateToProps, mapDispatchToProps)(SocialButtonsBase)
