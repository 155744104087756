import PropTypes from 'prop-types'
import cn from 'classnames'

import { Button } from 'components/Button'

import styles from './RoundButton.module.scss'

export const RoundButton = ({ type, onClick, children, className, ...other }) => (
  <Button
    {...other}
    type={type}
    onClick={onClick}
    className={cn(styles.btn, className)}
  >
    {children}
  </Button>
)

RoundButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
}

RoundButton.defaultProps = {
  onClick: null,
  className: null,
  disabled: false,
  isPreview: false
}
