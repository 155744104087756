import { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import LeftRed from 'static/images/arrow-right-red-2.svg'
import RightGreen from 'static/images/arrow-left-green-2.svg'
import i18n from 'services/i18n'

import s from './SwipeActionButtonAnimated.module.scss'

const getLabels = () => ({
  skip: i18n.t('swipes.skip'),
  like: i18n.t('swipes.like')
})

const animClass = {
  skip: s.withAnimationRed,
  like: s.withAnimationGreen
}

class SwipeActionButtonAnimated extends PureComponent {
  state = {
    animate: false
  }
  timeout = null

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  animate = () => {
    if (!this.state.animate) {
      this.setState({ animate: true })
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(() => this.setState({ animate: false }), 1000)
    } else {
      this.setState({ animate: false })
      this.timeout && clearTimeout(this.timeout)
      this.timeout = setTimeout(this.animate, 0)
    }
  }

  onClick = () => {
    this.animate()
    this.props.onClick()
  }

  render() {
    const { animate } = this.state
    const { type, className } = this.props

    return (
      <button
        aria-label={type ==='skip'? i18n.t('swipes.skip') : i18n.t('swipes.like') }
        onClick={this.onClick}
        className={cn(s.container, className, animate && animClass[type])}
      >
        {type === 'skip'
          ? <img aria-hidden="true" alt="" src={LeftRed} />
          : <img aria-hidden="true" alt="" src={RightGreen} />
        }
        <span className={cn(
          s.label,
          type === 'like' && s.like,
          type === 'skip' && s.skip
        )} aria-hidden="true">{getLabels()[type]}</span>
      </button>
    )
  }
}

SwipeActionButtonAnimated.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string
}

export { SwipeActionButtonAnimated }
