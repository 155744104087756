// uncomment for complex selectors
import { createSelector } from 'reselect'
import path from 'ramda/es/path'
import prop from 'ramda/es/prop'

const getState = state => state.community
const isContentLoadingSelector = state => getState(state).isContentLoading
const contentSelector = state => getState(state).content
const threadsSelector = state => getState(state).threadsIds
const threadsContentSelector = state => getState(state).threads
const isCreateThreadLoadingSelector = state => getState(state).isCreateThreadLoading
const isThreadsLoadingSelector = state => getState(state).isThreadsLoading
const threadIdItemSelector = (_, { threadId }) => threadId
const loadingStates = state => getState(state).loadingStates
const postsState = state => getState(state).posts
const isCreatedSelector = state => getState(state).created

const genderSettingsSelector = createSelector(
  contentSelector,
  prop('genderSettings')
)

const threadIdPageSelector = (state) => {
  const pathname = path(['router', 'location', 'pathname'], state)
  try {
    return +pathname.split('/')[2]
  } catch (e) {
    return null
  }
}

const threadDetailsSelector = createSelector(
  threadsContentSelector,
  threadIdItemSelector,
  (threads, threadId) => threads[threadId]
)

const isGetThreadLoadingSelector = createSelector(
  loadingStates,
  threadIdPageSelector,
  (lodaingStates, threadId) => lodaingStates[threadId]
)

const postsSelector = createSelector(
  postsState,
  threadIdPageSelector,
  (posts, threadId) => posts[threadId] || []
)

const genderTitleSelector = createSelector(
  genderSettingsSelector,
  prop('genderSelectionTitle')
)

const personalPronounsEnabledSelector = createSelector(
  contentSelector,
  prop('personalProNounsEnabled')
)

const genderExplainerSelector = createSelector(
  genderSettingsSelector,
  prop('genderSelectionExplainer')
)

const previouslyKnownEnabledSelector = createSelector(
  contentSelector,
  prop('previouslyKnownEnabled')
)

const phoneNumberEnabledSelector = createSelector(
  contentSelector,
  prop('phoneNumberEnabled')
)

const matchingRoundStatusSelector = createSelector(
  contentSelector,
  ({ matchingRoundStatus }) => {
    return matchingRoundStatus
  }
)

const isNetworkClosedSelector = createSelector(
  contentSelector,
  ({ networkOpened }) => {
    return !networkOpened
  }
)



const areEntranceDatesDifferentSelector = createSelector(
  contentSelector,
  ({ deadline_end_date: endDate, deadline_start_date: startDate }) => startDate !== endDate
)

export default {
  isNetworkClosedSelector,
  areEntranceDatesDifferentSelector,
  postsSelector,
  contentSelector,
  threadsSelector,
  genderTitleSelector,
  threadIdPageSelector,
  threadDetailsSelector,
  genderSettingsSelector,
  genderExplainerSelector,
  isContentLoadingSelector,
  isGetThreadLoadingSelector,
  matchingRoundStatusSelector,
  phoneNumberEnabledSelector,
  isCreateThreadLoadingSelector,
  previouslyKnownEnabledSelector,
  personalPronounsEnabledSelector,
  isThreadsLoadingSelector,
  isCreatedSelector,
}
