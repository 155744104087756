import React, { useMemo, Fragment } from 'react'
import values from 'ramda/es/values'
import map from 'ramda/es/map'
import prop from 'ramda/es/prop'
import pathOr from 'ramda/es/pathOr'
import path from 'ramda/es/path'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import images from 'static'
import roommateSelectors from 'redux/selectors/RoommateSelectors'

import { LastMessageDetails } from './LastMessageDetails'
import s from './ConversationItem.module.scss'

const AVATAR_SHIFT = 40

const getFullName = data => `${path([0, 'first_name'], data)} ${path([0, 'last_name'], data)}`

const renderAvatar = (avatar, index) => (
  <div
    key={String(index)}
    style={{ marginLeft: (index ? -AVATAR_SHIFT : 0) - index * 2 }}
  >
    <img aria-hidden="true" alt="" src={avatar || images.defaultAvatar} className={s.avatar} rel='noreferrer' />
  </div>
)

const Content = ({ roommates, sid }) => {
  const { name, avatars } = useMemo(() => {
    const data = values(roommates)

    return ({
      name: data.length === 1
        ? getFullName(data)
        : map(prop('first_name'), data).join(', '),
      avatars: map(pathOr(' ', ['profile_picture_urls', 'large']), data)
    })
  }, [roommates.length])

  return (
    <Fragment>
      {avatars.map(renderAvatar)}
      <LastMessageDetails
        name={name}
        roommates={roommates}
        conversationId={sid}
      />
    </Fragment>
  )
}

const mapStateToProps = createStructuredSelector({
  roommates: roommateSelectors.usersByUserIdSelector
})

export default connect(mapStateToProps)(Content)
