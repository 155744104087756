import React from 'react';
import ReactZendesk, { ZendeskAPI } from "react-zendesk/src"

const ZENDESK_KEY = "484f581b-ca3a-4f79-bec7-b955a3a0ff9b"

export const Zendesk = () => {
  const [lastFocusEl, setLastFocusEl] = React.useState(null);

  const handleZendeskOpen = React.useCallback(() => {
    const intervalId = setInterval(() => {
      const embedEl = document.getElementById("webWidget")?.contentWindow?.document.getElementById("Embed")

      if (!embedEl) {
        return
      }

      setLastFocusEl(document.activeElement)

      embedEl.setAttribute("tabindex", "-1")
      embedEl.setAttribute("aria-label", "Contact Us widget popup")
      embedEl.focus()

      clearInterval(intervalId)
    }, 100)
  }, [setLastFocusEl])

  const handleZendeskClose = React.useCallback(() => {
    if (!lastFocusEl) {
      return
    }

    // After iframe is closed zendesk automaticaly move focus to iframe launcher. To overwrite it we need setTimeout
    setTimeout(() => {
      lastFocusEl.focus()
      setLastFocusEl(null)
    }, 100)
  }, [lastFocusEl])

  const onLoaded = React.useCallback(() => {
    ZendeskAPI('messenger:on', 'open', handleZendeskOpen);
  }, [handleZendeskOpen])

  React.useEffect(() => {
    ZendeskAPI('messenger:on', 'close', handleZendeskClose);
  }, [handleZendeskClose])

  return (
    <ReactZendesk
      async
      id="zendesk"
      onLoaded={onLoaded}
      zendeskKey={ZENDESK_KEY}
    />
  )
}