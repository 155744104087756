import { styled } from '@material-ui/core/styles'
import { useEffect } from 'react'

import MenuBar from './components/MenuBar/MenuBar'
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens'
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification'
import Room from './components/Room/Room'

import useRoomState from './hooks/useRoomState/useRoomState'

const Container = styled('div')({
  display: 'grid',
  width: '100%',
  height: '100%',
  gridTemplateRows: '1fr auto'
})

const Main = styled('main')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: 'black',
  height: '100vh'
}))

export default function App({ setClassNames, sendMessage, match, show }) {
  const roomState = useRoomState()
  const isDisconnected = roomState === 'disconnected'

  useEffect(() => {
    setClassNames(isDisconnected)

    const el = document.getElementById("launcher")
    if (el) {
      el.style.right = isDisconnected ? "0px" : "-1000000px"
    }
  }, [isDisconnected])

  return (
    <Container>
      {roomState === 'disconnected' ? (
        <PreJoinScreens
          show={show}
          match={match}
          sendMessage={sendMessage}
        />
      ) : (
        <Main>
          <ReconnectingNotification />
          <Room />
          <MenuBar
            match={match}
            sendMessage={sendMessage}
          />
        </Main>
      )}
    </Container>
  )
}
