import { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Redirect } from 'react-router'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts'
import SmartBanner from 'react-smartbanner'
import 'react-smartbanner/dist/main.css'

import { MatchModal } from 'modals'
import images from 'static'
import { HOME, NO_NETWORK, WELCOME, ROOMMATE_PROFILE_ROUTE } from 'constants/urls'
import { contentActions } from 'redux/actions/ContentActions'
import { roomActions } from 'redux/actions/RoomActions'
import { profileActions } from 'redux/actions/ProfileActions'
import { chatActions } from 'redux/actions/ChatActions'
import routeSelectors from 'redux/selectors/RouteSelectors'
import userSelectors from 'redux/selectors/UserSelectors'
import profileSelectors from 'redux/selectors/ProfileSelectors'
import communitySelectors from 'redux/selectors/CommunitySelectors'

import s from './SignedInLayout.module.scss'


class SignedInLayout extends PureComponent {
  componentDidMount() {
    const {
      isAuthenticated, initializeChat, isRegistrationFlow,
      getMyRoom, getBasicinfo, getProfile
    } = this.props

    if (!isRegistrationFlow && isAuthenticated) {
      initializeChat()
    }

    getBasicinfo()
    getMyRoom()
    getProfile()
  }

  componentDidUpdate(prevProps) {
    const { logoutChat, initializeChat, isRegistrationFlow } = this.props

    if (prevProps.isRegistrationFlow && !isRegistrationFlow) {
      initializeChat()
    } else if (!prevProps.isRegistrationFlow && isRegistrationFlow) {
      logoutChat()
    }
  }

  render() {
    const {
      children, isRegistrationFlow, isAuthenticated, networkId, isNetworkClosed, isClosedFlow, location
    } = this.props

    if (!isAuthenticated) {
      return <Redirect to={HOME} />
    }

    if (!networkId) {
      return <Redirect to={NO_NETWORK} />
    }

    // if (isNetworkClosed && !isRegistrationFlow && !isClosedFlow && !location.pathname.includes(ROOMMATE_PROFILE_ROUTE)) {
    //   return <Redirect to={WELCOME} />
    // }

    return (
      <Fragment>
        <SmartBanner
          title='RoomSync'
          button='Open App'
          position='top'
          daysHidden={1}
          daysReminder={1}
          ignoreIosVersion
          author='U-Match, LLC'
          url={{
            ios: process.env.REACT_APP_BASE_MOBILE_BANNER_LINK,
            android: process.env.REACT_APP_BASE_MOBILE_BANNER_LINK
          }}
        />

        <div className={s.content}>
          <div className={s.content}>
            {children}
            {isRegistrationFlow &&
              <img src={images.registerPlaceholder} className={s.placeholder} />
            }
          </div>
        </div>
        <MatchModal />
        <ToastsContainer
          lightBackground
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  networkId: userSelectors.networkIdSelector,
  isAuthenticated: userSelectors.isAuthenticatedSelector,
  aboutMeHasAllAnswers: profileSelectors.aboutMeHasAllAnswersSelector,
  isRegistrationFlow: routeSelectors.isRegistrationFlow,
  isClosedFlow: routeSelectors.isClosedFlowSelector,
  isNetworkClosed: communitySelectors.isNetworkClosedSelector,
})

const mapDispatchToProps = {
  logoutChat: chatActions.logoutChat,
  initializeChat: chatActions.initializeChat,
  getMyRoom: roomActions.getRoomContent,
  getProfile: profileActions.getProfile,
  getBasicinfo: contentActions.getVerifyInfoContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLayout)
