import React, { useMemo } from 'react';
import always from 'ramda/src/always'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, withStyles, makeStyles } from '@material-ui/core/styles';
import { COLOR_BLUE_PRIMARY, COLOR_SECONDARY, COLOR_WHITE } from 'styles/constants';
import { setToUpperCase } from 'services/helpers'

const CustomInput = withStyles((theme) =>
  createStyles({
    input: {
      minWidth: 90,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 30,
      backgroundColor: COLOR_WHITE,
      border: '1px solid #ced4da',
      fontSize: 14,
      color: COLOR_BLUE_PRIMARY,
      paddingLeft: 24,
      fontWeight: 500,
      '&:focus': {
        borderRadius: 30,
        outline: `2px auto ${COLOR_SECONDARY}`,
        backgroundColor: COLOR_WHITE,
      }
    },
  }),
)(InputBase);

const useFormControlStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minWidth: 90,
      marginTop: 10,
    },
  }),
);

export const GenderSelectInput = props => {
  const formControlClasses = useFormControlStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.input.onChange(String(event.target.value))
  }

  const options = useMemo(() => {

    return Object.keys(props.sexOptions).map(key => ({
      value: props.sexOptions[key],
      label: setToUpperCase(key)
    }))
  }, [props.sexOptions])

  return (
    <FormControl classes={formControlClasses}>
      <Select
        {...props.input}
        onChange={handleChange}
        input={<CustomInput />}
        IconComponent={always(null)}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
