import { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import { ZendeskAPI } from "react-zendesk/src"

import { userActions } from 'redux/actions/UserActions'
import { chatActions } from 'redux/actions/ChatActions'
import { homeActions } from 'redux/actions/HomeActions'

import { Button } from 'components/Button'

import s from './InfoModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class InfoModalView extends PureComponent {
  onClose = () => {
    const { handleHide, logoutOnHide, memberships, proceedSignIn } = this.props

    handleHide()

    if (memberships) {
      proceedSignIn({ res: { memberships } })
    }

    if (logoutOnHide) {
      const { resetStore, logoutChat } = this.props
      ZendeskAPI('messenger', 'reset')
      ZendeskAPI('messenger', 'prefill', {
        name: {
          value: '',
          readOnly: false // optional
        },
        'key:360042833871': {
          value: '',
          readOnly: false // optional
        },
        email: {
          value: '',
          readOnly: false // optional
        },
      })
      logoutChat()
      resetStore()
    }
  }

  render() {
    const { show, label, description, socialUse, showMail } = this.props

    return (
      <Modal
        center
        open={show}
        focusTrapped
        onClose={this.onClose}
        classNames={classNames}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={s.container} tabIndex={0}>
          <h2 id="modal-title" className={s.title}>{label}</h2>
          {description && (
            <div id="modal-description" className={s.description}>
              {description}
              {showMail && (
                <a href='email:support@roomsync.com'>support@roomsync.com</a>
              )}
            </div>
          )}
          {socialUse && (
            <Fragment>
              <p>
                The RoomSync app uses your current profile photo on Facebook or
                Google as your RoomSync profile photo visible to other users.
              </p>
              <p>
                You can update your photo by updating it on Facebook or
                Google and logging back into RoomSync. If you register with Facebook,
                RoomSync will also display shared interests you have with other users.
              </p>
              <p>
                We don’t automatically post anything to your Facebook or Google account
                when you join RoomSync. If you have questions, you can always contact us at
                <a href='email:support@roomsync.com'>support@roomsync.com</a>.
              </p>
            </Fragment>
          )}
          <Button className={s.optionLabel} onClick={this.onClose}>Ok</Button>
        </div>
      </Modal>
    )
  }
}

InfoModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

InfoModalView.defaultProps = {
  label: ''
}

const InfoModalConnected = connectModal({ name: 'infoModal' })(InfoModalView)

const InfoModal = connect(null, {
  proceedSignIn: homeActions.proceedSignIn,
  logoutChat: chatActions.logoutChat,
  resetStore: userActions.resetStore,
})(InfoModalConnected)

export { InfoModal }
