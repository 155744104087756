import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { profileTypes, profileActions } = createActionsStructure('profile', [
  { name: 'GET_PROFILE', async: true },
  { name: 'GET_PROFILE_OVERVIEW', async: true },
  { name: 'CHANGE_OVERVIEW_STATUS' },
  { name: 'GET_MAJORS_OPTIONS', async: true },
  { name: 'SET_MAJORS_OPTIONS', async: true },
  { name: 'GET_HOUSING_OPTIONS', async: true },
  { name: 'SET_HOUSING_OPTIONS', async: true },
  { name: 'GET_SOCIAL_ACCOUNTS', async: true },
  { name: 'SET_SOCIAL_ACCOUNTS', async: true },
  { name: 'GET_ABOUT_ME', async: true },
  { name: 'SET_ABOUT_ME', async: true },
  { name: 'UPLOAD_PROFILE_IMAGE', async: true },
  { name: 'UPDATE_USER_DATA' }
])
