import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import path from 'ramda/es/path'
import inc from 'ramda/src/inc'
import { push } from 'connected-react-router'
import Dotdotdot from 'react-dotdotdot'
import { isFirefox } from 'react-device-detect'

import { formatDate } from 'services/helpers'
import communitySelectors from 'redux/selectors/CommunitySelectors'
import images from 'static'
import { THREAD, ROOMMATE_PROFILE } from 'constants/urls'
import i18n from 'services/i18n'

import s from './ThreadItem.module.scss'
import { Button } from 'components/Button'

const contentFirefox = isFirefox ? 3 : 2
const nameFirefox = isFirefox ? 2 : 1

class ThreadItemView extends PureComponent {
  onClick = () => {
    const { push, content } = this.props
    push(THREAD.replace(':threadId', content.post_id))
  }

  onAvatarClick = (e) => {
    const { push, content } = this.props
    e.stopPropagation()
    push(ROOMMATE_PROFILE.replace(':roommateId', path(['user', 'user_id'], content)))
  }

  render() {
    const { content } = this.props
    const count = +content.post_count - 1

    return (
      <article
        key={this.props.threadId}
        aria-setsize={this.props.setsize}
        aria-posinset={inc(this.props.idx)}
      >
        <Button
          role="link"
          onClick={this.onClick}
          className={s.container}
        >
          <img
            tabIndex={-1}
            alt="Author avatar"
            aria-hidden="true"
            className={s.avatar}
            onClick={this.onAvatarClick}
            src={path(['user', 'image_urls', 'square'], content) || images.defaultAvatar}
          />
          <div className={s.content}>
            <div className={s.top}>
              <time
                className={s.date}
                dateTime={formatDate(new Date().setTime(content.date_created))}
                aria-label={`Created at ${formatDate(new Date().setTime(content.date_created))}`}
              >
                {formatDate(new Date().setTime(content.date_created))}
              </time>
              <h3 className={s.title}>
                <Dotdotdot clamp={contentFirefox}>
                  {content.body}
                </Dotdotdot>
              </h3>
            </div>
            <div className={s.bottom}>
              <span className={s.by}>
                <Dotdotdot clamp={nameFirefox}>
                  by {<span className={s.name}>{content.user.first_name} {content.user.last_name}</span>}
                </Dotdotdot>
              </span>
              <span className={s.replies}>{count} {count > 1 ? i18n.t('thread.replies') : i18n.t('thread.reply')}</span>
            </div>
          </div>
          <img
            tabIndex={-1}
            alt="Arrow right"
            aria-hidden="true"
            className={s.arrow}
            src={images.arrowRightBlue}
          />
        </Button>
      </article>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  content: communitySelectors.threadDetailsSelector
})

const mapDispatchToProps = {
  push
}

const ThreadItem = connect(mapStateToProps, mapDispatchToProps)(ThreadItemView)

export { ThreadItem }
