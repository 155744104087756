import cn from 'classnames'
import PropTypes from 'prop-types'

import NoDataIcon from 'static/images/no-data.svg'
import NoDataIconWhite from 'static/images/no-data-white.svg'
import i18n from 'services/i18n'

import s from './NoDataPlaceholder.module.scss'

const NoDataPlaceholder = ({ type, message, children }) =>
  <section className={s.container}>
    <div className={s.wrapper}>
      <img
        alt=""
        aria-hidden="true"
        src={type === 'white' ? NoDataIconWhite : NoDataIcon}
        className={cn(type === 'white' ? s.iconWhite : s.iconBlue)}
      />
      <p className={cn(type === 'white' ? s.labelWhite : s.label)} tabIndex={0}>{message || i18n.t('matches.noRequests')}</p>
    </div>
    {children}
  </section>

NoDataPlaceholder.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
}

NoDataPlaceholder.defaultProps = {
  type: 'white',
}

export { NoDataPlaceholder }
