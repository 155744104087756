import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { chatTypes, chatActions } = createActionsStructure('chat', [
  { name: 'INITIALIZE_CHAT', async: true },
  { name: 'GET_CONVERSATIONS', async: true },
  { name: 'CREATE_MESSAGE', async: true },
  { name: 'GET_MESSAGES', async: true },
  { name: 'INITIALIZE_CHAT_LISTENERS', async: true },
  { name: 'LOGOUT_CHAT' },
  { name: 'CHANNEL_ADDED' },
  { name: 'MESSAGES_ADDED' },
  { name: 'MESSAGE_REMOVED' },
  { name: 'CHECK_USER_EXIST' },
  { name: 'MARK_AS_READ', async: true },
  { name: 'SWITCH_BLOCK_USER', async: true },
  { name: 'REMOVE_MESSAGE', async: true },
  { name: 'CHANNEL_UPDATED' },
  { name: 'SET_TOKEN' },
  { name: 'HANDLE_SOMEONE_IS_CALLING' },
  { name: 'REFRESH_TOKEN', async: true },
])
