import { Component } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { Field, reduxForm } from 'redux-form'
import FadeIn from 'react-fade-in'

import { required, maxLength82, minLength5, isEmail, logEvent } from 'services/helpers'
import { Input, RoundButton, Header } from 'components'
import i18n from 'services/i18n'

import { MainLayout } from 'layouts/MainLayout'
import { homeActions } from 'redux/actions/HomeActions'
import homeSelectors from 'redux/selectors/HomeSelectors'

import c from './ForgotLogin.module.scss'

class ForgotLogin extends Component {
  onBack = () => {
    const { history } = this.props

    history.goBack()
  }

  handleSubmit = () => {
    this.props.handleSubmit()
    logEvent('action.Home.ForgotLogin.Submit')
  }

  render() {
    const { isLoading } = this.props

    return (
      <MainLayout
        isLoading={isLoading}
        header={(
          <div className={c.header}>
            <Header label={i18n.t('home.forgotLogin')} onBack={this.onBack} />
          </div>
        )}
      >
        <FadeIn>
          <section className={c.container}>
            <div className={c.wrapper}>
              <Field
                name='email'
                label={i18n.t('home.enterAddress')}
                normalize={a => (a || '').trim()}
                autoCapitalize={false}
                autoCompleteType='email'
                validate={[required, minLength5, maxLength82, isEmail]}
                placeholder={i18n.t('home.emailPlaceholder')}
                component={Input}
              />
            </div>
            <RoundButton
              onClick={this.handleSubmit}
              className={c.button}
            >
              {i18n.t('home.submit')}
            </RoundButton>
          </section>
        </FadeIn>
      </MainLayout>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: homeSelectors.isLoginMethodLoadingSelector,
})

const mapDispatchToProps = {
  onSubmit: homeActions.getLoginMethod
}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'forgotLogin',
    destroyOnUnmount: true,
  })
)

export default enhance(ForgotLogin)
