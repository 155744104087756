import { put, call, select, take, all, race } from 'redux-saga/effects'
import { eventChannel, delay } from 'redux-saga'
import reject from 'ramda/es/reject'
import pathEq from 'ramda/es/pathEq'
import mergeDeepRight from 'ramda/es/mergeDeepRight'
import identical from 'ramda/es/identical'
import init from 'ramda/es/init'
import { show, hide } from 'redux-modal'

import pipe from 'ramda/es/pipe'
import map from 'ramda/es/map'
import pathOr from 'ramda/es/pathOr'
import flatten from 'ramda/es/flatten'
import uniq from 'ramda/es/uniq'

import { ToastsStore } from 'react-toasts'
import { Client as TwilioChatClient } from '@twilio/conversations'
import { goBack } from 'connected-react-router'

import userSelectors from 'redux/selectors/UserSelectors'
import chatSelectors from 'redux/selectors/ChatSelectors'
import modalSelectors from 'redux/selectors/ModalSelectors'
import roommateSelectors from 'redux/selectors/RoommateSelectors'

import API from 'services/api'
import { formatMessage, getChatId } from 'services/chat'
import { handleToastFocus } from 'helpers/handleToastFocus'

import { roommateActions } from 'redux/actions/RoommateActions'
import { chatActions, chatTypes } from 'redux/actions/ChatActions'
import { logEvent } from 'services/helpers'

import s from '../../layouts/SignedInLayout/Toast.module.scss'

let ChatClient = null
let LastSidChannel = null

const TwilioChannelFunction = ({ userId }) =>
  eventChannel(emitter => {
    ChatClient.on('conversationJoined', async conversation => {
      try {
        const { attributes, sid, lastMessage } = conversation

        const roommates = reject(identical(userId), attributes.members)

        emitter(roommateActions.getProfiles({
          roommates,
          membershipIds: attributes.memberships
        }))

        let hasPrevPage = false

        if (lastMessage) {
          const { items, hasPrevPage: _hasPrevPage } = await conversation.getMessages(20)
          hasPrevPage = _hasPrevPage
          const messages = items.map(message => formatMessage(message, userId))
          emitter(
            chatActions.messagesAdded({
              sid,
              messages
            })
          )
          const { state } = items[0]

          if (state.body?.includes("started a call 📞") && state.author !== userId) {
            document.title = state.body
            emitter(chatActions.handleSomeoneIsCalling({
              title: state.body,
              roommateId: state.author
            }))
          }
          if (state.body?.includes("ended a call 📞") && state.author !== userId) {
            document.title = 'RoomSync'
            emitter(chatActions.handleSomeoneIsCalling({
              dismissed: true
            }))
          }
          if (state.body?.includes("declined a call 📞")) {
            document.title = 'RoomSync'
          }
        }

        const unreadCount = lastMessage ? (lastMessage.index - (conversation.lastConsumedMessageIndex || -1)) : 0

        const chatId = getChatId({ members: attributes.members, userId })

        emitter(
          chatActions.channelAdded({
            unreadCount,
            conversation: {
              sid,
              chatId,
              hasPrevPage,
              attributes: {
                members: roommates,
                memberships: attributes.memberships,
                blocked: attributes.blocked
              }
            }
          })
        )
      } catch (err) { }
    })
    ChatClient.on('conversationUpdated', ({ conversation }) => {
      emitter(
        chatActions.channelUpdated({
          conversation: {
            sid: conversation.sid,
            attributes: {
              blocked: conversation.attributes.blocked
            }
          }
        })
      )
    })

    ChatClient.on('messageAdded', ({ state, conversation }) => {
      const isGroupChatBlocked = conversation.notificationLevel === 'muted'

      if (isGroupChatBlocked) {
        return
      }

      if (state.body?.includes("started a call 📞") && state.author !== userId) {
        document.title = state.body
        emitter(chatActions.handleSomeoneIsCalling({
          title: state.body,
          roommateId: state.author
        }))
      }
      if (state.body?.includes("ended a call 📞") && state.author !== userId) {
        document.title = 'RoomSync'
        emitter(chatActions.handleSomeoneIsCalling({
          dismissed: true
        }))
      }
      if (state.body?.includes("declined a call 📞")) {
        document.title = 'RoomSync'
      }

      emitter(chatActions.messagesAdded({
        sid: conversation.sid,
        messages: [formatMessage({ state }, userId)]
      }))
    })
    ChatClient.on('messageRemoved', ({ state, conversation }) => {
      emitter(chatActions.messageRemoved({
        sid: conversation.sid,
        messageId: state.sid
      }))
    })

    ChatClient.on('connectionStateChanged', (state) => {
      if (state !== 'denied') {
        emitter(chatActions.refreshToken())
      }
    })

    return () => {
      ChatClient.removeAllListeners('conversationJoined')
      ChatClient.removeAllListeners('conversationUpdated')
      ChatClient.removeAllListeners('messageAdded')
      ChatClient.removeAllListeners('messageRemoved')
      ChatClient.removeAllListeners('connectionStateChanged')
    }
  })

export function* initializeChatListeners(action) {
  const { responseFailure } = action

  try {
    const userId = yield select(userSelectors.userIdSelector)
    const TwilioChannel = yield call(TwilioChannelFunction, { userId })

    while (true) {
      const { action, logout } = yield race({
        action: take(TwilioChannel),
        logout: take(chatTypes.LOGOUT_CHAT_ATTEMPT)
      })

      if (action) {
        if (action.type === chatTypes.MESSAGES_ADDED_ATTEMPT) {
          const inConversation = !!LastSidChannel && pathEq(['data', 'sid'], LastSidChannel.sid, action)

          yield put(mergeDeepRight(action, { data: { inConversation } }))
        } else {
          yield put(action)
        }
      } else if (logout) {
        throw new Error()
      }
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* initializeChat(action) {
  const { responseSuccess, responseFailure } = action

  try {
    if (ChatClient) {
      yield put(responseSuccess({ setLoadingFalse: true }))
    } else {
      const userId = yield select(userSelectors.userIdSelector)
      const accessToken = yield select(userSelectors.accessTokenSelector)

      const { res, err } = yield call(API.getChatSessionToken, accessToken, userId)

      if (res) {
        const { session_token: twilioUserToken } = res
        ChatClient = yield call(TwilioChatClient.create, twilioUserToken)
        yield put(chatActions.setToken(twilioUserToken))
        yield put(chatActions.getConversations())
        yield put(responseSuccess())
      } else {
        yield put(responseFailure(err))
      }
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getConversations(action) {
  const { responseSuccess, responseFailure } = action

  try {
    const userId = yield select(userSelectors.userIdSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { items } = yield call(ChatClient.conversations.getConversations.bind(ChatClient.conversations))

    // issue where someone else was in my conversation
    const conversations = items.filter(({ attributes: { members, memberships }, sid, channelState }) => {
      const all = [...members, ...(memberships || [])]
      const memberInConversation = all.includes(userId) || all.includes(membershipId)

      !memberInConversation && logEvent('chatConversationMembersError', {
        userId,
        membershipId,
        members,
        memberships,
        sid,
        createdAt: channelState?.dateCreated ? new Date(channelState?.dateCreated).toISOString() : 'no-date'
      })

      return memberInConversation
    })

    const roommates = pipe(
      map(pathOr([], ['attributes', 'members'])),
      flatten,
      reject(identical(userId)),
      uniq
    )(conversations)

    const membershipIds = pipe(
      map(pathOr([], ['attributes', 'memberships'])),
      flatten,
      reject(identical(membershipId)),
      uniq
    )(conversations)

    if (roommates.length > 0) {
      yield put(roommateActions.getProfiles({ roommates, membershipIds }))
    }

    // for (const conversation of conversations) {
    //   conversation.delete()
    // }

    for (const conversation of conversations) {
      const { attributes, sid, lastMessage } = conversation
      const allParticipants = [...(attributes.members || []), ...(attributes.memberships || [])]

      const isGroupChatBlocked = conversation.notificationLevel === 'muted'
      const lastVisibleMessage = conversation.lastReadMessageIndex

      const { items, hasPrevPage } = yield call(
        conversation.getMessages.bind(conversation),
        20,
        isGroupChatBlocked ? lastVisibleMessage : undefined
      )

      const filteredMessages = items.filter(({ author }) => {
        const messageFromMember = allParticipants.includes(author)

        !messageFromMember && logEvent('chatMessageMembersError', { author, members: attributes.members, memberships: (attributes.memberships || []), sid })

        return messageFromMember
      })
      const messages = filteredMessages.map(message => formatMessage(message, userId))

      yield put(
        chatActions.messagesAdded({
          sid,
          messages
        })
      )

      const unreadCount = lastMessage && !isGroupChatBlocked
        ? (lastMessage.index - (conversation.lastReadMessageIndex || 0))
        : 0
      const chatId = getChatId({ members: attributes.members, userId })
      const members = reject(identical(userId), attributes.members)

      yield put(
        chatActions.channelAdded({
          unreadCount,
          conversation: {
            sid,
            chatId,
            hasPrevPage,
            attributes: {
              members,
              memberships: attributes.memberships || [],
              blocked: attributes.blocked
            }
          }
        })
      )
    }

    yield put(chatActions.initializeChatListeners())
    yield put(responseSuccess())
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* refreshToken(action) {
  const { responseSuccess } = action

  try {
    const userId = yield select(userSelectors.userIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getChatSessionToken, accessToken, userId)

    if (res && ChatClient) {
      const { session_token: twilioUserToken } = res
      yield call(ChatClient.updateToken.bind(ChatClient), twilioUserToken)
      yield put(chatActions.setToken(twilioUserToken))
    }
  } catch (err) {
    console.log(err)
  }

  yield put(responseSuccess())
}

export function* checkUserExist(action) {
  const { data: { roommateId } } = action

  if (ChatClient && ChatClient.connectionState === 'denied') {
    yield put(chatActions.refreshToken())
    yield take(chatTypes.REFRESH_TOKEN_SUCCESS)
  }

  if (ChatClient && ChatClient.connectionState === 'connected') {
    try {
      yield call(ChatClient.getUser.bind(ChatClient), roommateId)
    } catch (err) {
      yield delay(500)
      yield put(goBack())
      yield delay(200)
      ToastsStore.error('Not available for chat! ' + err, 3000, s.toast)
      handleToastFocus()
    }
  } else {
    logEvent('error', {
      roommateId,
      reason: 'ChatClient not initialized yet',
      method: 'checkUserExist',
      errorType: 'ChatClient.getUser',
    })
  }
}

export function* markAsRead(action) {
  const { responseSuccess, data: { sid, initialize, lastMessageIndex } } = action

  try {
    LastSidChannel = null

    if (sid) {
      if (!LastSidChannel) {
        LastSidChannel = yield call(ChatClient.conversations.getConversation.bind(ChatClient.conversations), sid)
      }

      yield LastSidChannel.updateLastReadMessageIndex(lastMessageIndex)
      yield put(responseSuccess({ sid }))
    }
  } catch (err) {
    LastSidChannel = null
  }

  if (!initialize) {
    LastSidChannel = null
  }
}

export function* createMessage(action) {
  const { responseSuccess, responseFailure, data: { roommatesId, message, chatId, resetChatOnSuccess } } = action
  // console.log('roommatesId, message, chatId, resetChatOnSuccess: ', roommatesId, message, chatId, resetChatOnSuccess)
  if (resetChatOnSuccess) {
    LastSidChannel = null
  }

  const roommateId = chatId || roommatesId[0]
  const userId = yield select(userSelectors.userIdSelector)
  const sid = yield select(chatSelectors.sidSelector, { chatId })

  try {
    if (!LastSidChannel) {
      if (sid) {
        LastSidChannel = yield call(ChatClient.conversations.getConversation.bind(ChatClient.conversations), sid)
      } else {
        const memberships = yield select(roommateSelectors.createMessageMembershipIdsSelector, roommatesId)

        LastSidChannel = yield call(ChatClient.conversations.addConversation.bind(ChatClient.conversations), {
          attributes: {
            members: [userId, ...roommatesId],
            memberships
          }
        })

        yield call(LastSidChannel.join.bind(LastSidChannel))
      }
    }

    // ~~~~ blocking section ~~~~
    const isBlockedByMe = pathEq(['attributes', 'blocked', userId], true, LastSidChannel)
    const isBlockedMe = pathEq(['attributes', 'blocked', roommateId], true, LastSidChannel)

    if (isBlockedByMe || isBlockedMe) {
      const isGroupChatBlocked = LastSidChannel.notificationLevel === 'muted'
      const type = isGroupChatBlocked ? 'Group' : 'User'
      const title = isBlockedByMe ? `${type} is blocked!` : 'You are blocked!'

      ToastsStore.error(title, 3000, s.toast)
      handleToastFocus()
      yield put(responseFailure({ chatId }))
      return
    }
    // ~~~~ blocking section ~~~~
    yield call(LastSidChannel.sendMessage.bind(LastSidChannel), message)
    yield put(responseSuccess({ chatId }))

    if (!sid) {
      yield all(roommatesId.map(roommateId =>
        call(LastSidChannel.add.bind(LastSidChannel), roommateId)
      ))
    }

    if (resetChatOnSuccess) {
      LastSidChannel = null
    }
  } catch (err) {
    yield put(responseFailure({ chatId }))
  }
}

export function* removeMessage(action) {
  const { data: { messageIndex } } = action

  try {
    const { items } = yield call(LastSidChannel.getMessages.bind(LastSidChannel), 1, messageIndex)

    if (items.length) {
      const message = items[0]
      yield call(message.remove.bind(message))

      ToastsStore.success('Message successfully removed!', 3000, s.toast)
      handleToastFocus()
    }
  } catch (err) {
    console.log('err: ', err)
  }
}

export function* getMessages(action) {
  const { responseFailure, responseSuccess, data: { conversationId, lastMessageIndex } } = action

  try {
    const userId = yield select(userSelectors.userIdSelector)
    const { items, hasPrevPage } = yield call(LastSidChannel.getMessages.bind(LastSidChannel), 11, lastMessageIndex)

    const messages = init(items).map(message => formatMessage(message, userId))

    yield put(
      responseSuccess({
        messages,
        hasPrevPage,
        conversationId
      })
    )
  } catch (err) {
    yield put(responseFailure({ conversationId }))
  }
}

export function* switchBlockUser(action) {
  const { data: { type } } = action

  try {
    const userId = yield select(userSelectors.userIdSelector)
    const isBlockedByMe = pathEq(['attributes', 'blocked', userId], true, LastSidChannel)

    yield call(LastSidChannel.updateAttributes.bind(LastSidChannel), mergeDeepRight(
      LastSidChannel.attributes,
      {
        blocked: {
          [userId]: !isBlockedByMe
        }
      }
    ))

    if (type === 'User') {
      ToastsStore.success(isBlockedByMe ? 'Successfully unblocked!' : 'Successfully blocked!', 3000, s.toast)
      handleToastFocus()
    } else {
      if (LastSidChannel.notificationLevel === 'default') {
        yield call(LastSidChannel.setUserNotificationLevel.bind(LastSidChannel), 'muted')

        ToastsStore.success('Successfully blocked!', 3000, s.toast)
        handleToastFocus()
      } else {
        yield call(LastSidChannel.setUserNotificationLevel.bind(LastSidChannel), 'default')

        ToastsStore.success('Successfully unblocked!', 3000, s.toast)
        handleToastFocus()
      }
    }
  } catch (err) {
    console.log('err: ', err)
  }
}

export function* logoutChat() {
  try {
    if (ChatClient) {
      ChatClient.removeAllListeners('conversationJoined')
      ChatClient.removeAllListeners('conversationUpdated')
      ChatClient.removeAllListeners('messageAdded')
      ChatClient.removeAllListeners('messageRemoved')
      ChatClient.removeAllListeners('connectionStateChanged')
      ChatClient.shutdown()
      ChatClient = null
    }
  } catch (err) {
  }
}

export function* handleSomeoneIsCalling(action) {
  const { data: { title, roommateId, dismissed } } = action
  try {
    const isVideoCallVisible = yield select(modalSelectors.isVideoCallVisibleSelector)

    if (dismissed && isVideoCallVisible) {
      yield put(hide('videoCallPromptModal'))
    } else if (!isVideoCallVisible) {
      yield put(show('videoCallPromptModal', {
        title,
        roommateId,
      }))
    }
  } catch (err) {
  }
}