import identical from 'ramda/es/identical'
import pipe from 'ramda/es/pipe'
import join from 'ramda/es/join'
import sortBy from 'ramda/es/sortBy'
import reject from 'ramda/es/reject'
import identity from 'ramda/es/identity'

export const formatMessage = ({ state, dateCreated }, userId) => ({
  _id: state.sid,
  index: state.index,
  user: {
    _id: state.author
  },
  type: state.type,
  body: state.body,
  getMediaUrl: state.type === 'media'
    ? state.media.getCachedTemporaryUrl()
    : null,
  createdAt: dateCreated || state.timestamp,
  myMessage: identical(userId, state.author)
})

export const getChatId = ({ members = [], userId }) =>
  members.length > 2
    ? pipe(
      sortBy(identity),
      join('|')
    )(members) // group chat
    : pipe(
      reject(identical(userId)),
      join('')
    )(members) // 1 : 1 chat
