import React from 'react';
import { isChrome } from "react-device-detect";

import s from './BestA11yExperienceDesc.module.scss'

export const BestA11yExperienceDesc = () => (
  <>
    {!isChrome && (
      <p className={s.desc} tabIndex={0}>
        For better accessible experience please use Google Chrome web browser
      </p>
    )}
  </>
);
