import dateFormat from 'dateformat'
import toUpper from 'ramda/es/toUpper'
import Email from 'email-validator'
import Mixpanel from 'mixpanel-browser'

const alphaNumeric = /^[a-zA-Z0-9_-]*$/

export const formatDate = date => dateFormat(date, 'mmmm d, yyyy')
export const formatDateUTC = date => dateFormat(date, 'UTC:mmmm d, yyyy')

export const isAlphaNumeric = value => !alphaNumeric.test(value)

export const required = value => !(value || typeof value === 'number')

const maxLength = max => value => value && value.length > max

export const maxLength15 = maxLength(15)
export const maxLength30 = maxLength(30)
export const maxLength35 = maxLength(35)
export const maxLength50 = maxLength(50)
export const maxLength82 = maxLength(82)
export const maxLength920 = maxLength(920)
export const maxLength2200 = maxLength(2200)

const minLength = min => value => value && value.length < min

export const minLength5 = minLength(5)
export const minLength50 = minLength(50)

export const getProfanityWords = str => {
  return str.match(/<\s*span[^>]*>(.*?)<\s*\/s*span>/g).map(val => val.replace(/<\/?span[^>]*>/g, ''))
}

export const setToUpperCase = (text) => text.replace(/^./, toUpper)
export const isEmail = value => value && !Email.validate(value)
export const phoneValidation = value => value && (value.length > 17 || value.length < 9)

export const logEvent = async (action, traits) => {
  try {
    Mixpanel.track(action, { platform: 'WEB', ...traits })
  } catch (e) {
  }
}

export const identify = async (userId, email) => {
  try {
    await Mixpanel.identify(userId)
    await Mixpanel.getPeople().set('$email', email)
  } catch (e) {
  }
}
