import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import cn from 'classnames'
import { keys, pipe, pickBy, values, head, prop, defaultTo } from 'ramda'

import i18n from 'services/i18n'
import { formatDate } from 'services/helpers'
import chatSelectors from 'redux/selectors/ChatSelectors'

import s from './ConversationItem.module.scss'

const getUserNameByUserId = (itemUserId, roommates) =>
  pipe(
    pickBy(({ userId, user_id }) => userId == itemUserId || user_id == itemUserId),
    defaultTo({}),
    values,
    head,
    prop('first_name')
  )(roommates)

const LastMessageDetailsView = ({ name, unreadCount, lastMessage, roommates }) => {
  if (!lastMessage) {
    return null
  }

  const unread = unreadCount > 0
  const prefix = lastMessage.myMessage
    ? `${i18n.t('chat.you')}: `
    : keys(roommates).length > 1
      ? `${getUserNameByUserId(lastMessage?.user?._id, roommates)}: `
      : ''

  return (
    <div className={s.infoBlock}>
      <div className={s.row}>
        <h2 className={cn(s.title, unread && s.unread)}>{name}</h2>
        <time
          className={s.date}
          dateTime={formatDate(lastMessage.createdAt)}
          aria-label={`${i18n.t('chat.last')} ${formatDate(lastMessage.createdAt)}`}
        >
          {formatDate(lastMessage.createdAt)}
        </time>
      </div>
      <p className={cn(s.message, unread && s.unread)}>
        {prefix}{lastMessage.body}
      </p>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  unreadCount: chatSelectors.unreadCountSelector,
  lastMessage: chatSelectors.lastConversationMessageSelector
})

const LastMessageDetails = connect(mapStateToProps)(LastMessageDetailsView)

export { LastMessageDetails }
