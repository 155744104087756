import { Fragment, PureComponent } from 'react'
import { GoogleLogin } from 'react-google-login'
import AppleLogin from 'react-apple-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { isIOS } from 'react-device-detect'
import compose from 'ramda/es/compose'
import { FacebookLoginButton, GoogleLoginButton, AppleLoginButton } from 'components'
import i18n from 'services/i18n'

export class SocialButtonsBase extends PureComponent {
  googleError = null

  showFacebookAlert = () =>
    this.props.showModal(
      'infoModal',
      { label: i18n.t('home.facebookUnavailable') }
    )

  showGoogleAlert = () =>
    this.props.showModal(
      'infoModal',
      { label: i18n.t('home.googleUnavailable'), description: this.googleError }
    )

  onFacebookClick = (onClick) => () => {
    window.FB
      ? onClick()
      : this.showFacebookAlert()
  }

  renderFacebook = ({ onClick, isDisabled, isProcessing }) => {
    const { isRegister, isFacebookLoading, isAppleLoading, invitationLoginMethod } = this.props

    return (
      <FacebookLoginButton
        isRegister={isRegister}
        invitationLoginMethod={invitationLoginMethod}
        disabled={isDisabled || isFacebookLoading || isAppleLoading || (invitationLoginMethod && invitationLoginMethod !== 'facebook')}
        isLoading={isFacebookLoading || isProcessing}
        onClick={this.onFacebookClick(onClick)}
      />
    )
  }

  renderGoogle = ({ onClick }) => {
    const { isGoogleLoading, isAppleLoading, googleStartAuthFlow, isFacebookLoading, isRegister, invitationLoginMethod } = this.props
    const handleClick = (e) => {
      if (this.googleError) {
        return this.showGoogleAlert()
      }

      return compose(googleStartAuthFlow, onClick)(e)
    }

    return (
      <GoogleLoginButton
        isRegister={isRegister}
        invitationLoginMethod={invitationLoginMethod}
        disabled={isFacebookLoading || isAppleLoading || (invitationLoginMethod && invitationLoginMethod !== 'google')}
        onClick={handleClick}
        isLoading={isGoogleLoading}
      />
    )
  }

  renderApple = ({ onClick }) => {
    const { isGoogleLoading, isAppleLoading, isFacebookLoading, isRegister, invitationLoginMethod } = this.props

    return (
      <AppleLoginButton
        isRegister={isRegister}
        invitationLoginMethod={invitationLoginMethod}
        disabled={isFacebookLoading || isGoogleLoading || (invitationLoginMethod && invitationLoginMethod !== 'apple')}
        onClick={onClick}
        isLoading={isAppleLoading}
      />
    )
  }

  facebookCallback = (props) => {
    const { facebookLogin, isRegister } = this.props

    facebookLogin({ ...props, isRegister })
  }

  googleCallback = (props) => {
    const { googleLogin, isRegister } = this.props

    googleLogin({ ...props, isRegister })
  }

  googleCallbackError = ({ details }) => {
    this.props.googleEndAuthFlow()
    this.googleError = details
  }

  render() {
    return (
      <Fragment>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          render={this.renderFacebook}
          fields='name,email,picture'
          callback={this.facebookCallback}
          isMobile={isIOS}
          disableMobileRedirect={isIOS}
        />

        <GoogleLogin
          onSuccess={this.googleCallback}
          onFailure={this.googleCallbackError}
          render={this.renderGoogle}
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          responseType='code'
        />

        <AppleLogin
          scope='email name'
          responseMode='form_post'
          responseType='code id_token'
          redirectURI={process.env.REACT_APP_REDIRECT}
          clientId={process.env.REACT_APP_CLIENT_ID}
          render={this.renderApple}
        />
      </Fragment>
    )
  }
}

SocialButtonsBase.defaultProps = {
  isRegister: false
}
