import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { homeTypes, homeActions } = createActionsStructure('home', [
  { name: 'FACEBOOK_LOGIN', async: true },
  { name: 'GOOGLE_LOGIN', async: true },
  { name: 'GOOGLE_START_AUTH_FLOW' },
  { name: 'GOOGLE_END_AUTH_FLOW' },
  { name: 'APPLE_AUTH', async: true },
  { name: 'PROCEED_INVITATION_LOGGED_IN', async: true },
  { name: 'PROCEED_SIGN_IN', async: true },
  { name: 'SET_INVITATION' },
  { name: 'GET_INVITATION_PREFLIGHT', async: true },
  { name: 'RESET' },
  { name: 'SET_INITIAL_ROUTE' },
  { name: 'SET_LAST_CHECKED_DATE' },
  { name: 'SET_MEMBERSHIP' },
  { name: 'GET_LOGIN_METHOD', async: true },
])
