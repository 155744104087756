import PropTypes from 'prop-types'
import cn from 'classnames'
import Dots from 'static/images/dotsFilled.svg'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './RoommateItem.module.scss'

const RoommateItem = ({ name, avatar, onClick, noBorder, id, isRoommates, isInBedroom, onDotsClick, previouslyKnown, previouslyKnownEnabled }) => (
  <li>
    <Button
      role="link"
      onClick={onClick}
      className={cn(s.container, noBorder && s.noBorder, isInBedroom && isRoommates && s.highlighted)}
    >
      <div className={s.photo_border}>
        <img
          alt=""
          src={avatar}
          rel='noreferrer'
          className={s.photo}
          aria-hidden="true"
        />
      </div>
      <div className={s.name_container}>
        <h3 className={s.name}>{name}</h3>
        {isRoommates && (
          <p className={s.info}>
            {isInBedroom
                 ? i18n.t('request.inBedroom')
                 : i18n.t('request.inCommon')}
          </p>
        )}
      </div>
      {(isRoommates || previouslyKnownEnabled) && (
        <Button
          className={s.check}
          aria-label={i18n.t('request.openOptions', { name })}
          onClick={onDotsClick({ id, isInBedroom, name, isRoommates, previouslyKnown })}
        >
          <img alt="" src={Dots} aria-hidden="false" />
        </Button>
      )}
    </Button>
  </li>
)

RoommateItem.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  noBorder: PropTypes.bool
}

RoommateItem.defaultProps = {
  noBorder: false
}

export { RoommateItem }
