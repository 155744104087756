import { Fragment, memo } from 'react'
import propEq from 'ramda/es/propEq'
import prop from 'ramda/es/prop'

import TwitterEnabled from 'static/images/blueTwitter.svg'
import InstagramEnabled from 'static/images/blueInstagram.svg'
import YoutubeEnabled from 'static/images/blueYoutube.svg'
import PinterestEnabled from 'static/images/bluePinterest.svg'
import SnapchatEnabled from 'static/images/blueSnapchat.svg'
import TiktokEnabled from 'static/images/blueTiktok.svg'
import TwitterDisabled from 'static/images/grayTwitter.svg'
import InstagramDisabled from 'static/images/grayInstagram.svg'
import YoutubeDisabled from 'static/images/grayYoutube.svg'
import PinterestDisabled from 'static/images/grayPinterest.svg'
import SnapchatDisabled from 'static/images/graySnapchat.svg'
import TiktokDisabled from 'static/images/grayTiktok.svg'

import { logEvent } from 'services/helpers'

import c from './SocialSection.module.scss'

const getUrl = prop('social_account_profile_url')

const TYPES = {
  twitter: 'Twitter',
  instagram: 'Instagram',
  youtube_channel: 'Youtube',
  pinterest: 'Pinterest',
  snapchat: 'Snapchat',
  tiktok: 'TikTok'
}

const Wrapper = ({ children, data, enabled, onPress, type, isFocusable }) => (
  <>
    {enabled && (
      <a
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onPress(prop('social_account_type', data))

          window.open(getUrl(data), '_blank')?.focus()
        }}
        href={getUrl(data)}
        target='_blank'
        aria-label={type}
        className={c.link}
        rel='noopener noreferrer'
        tabIndex={isFocusable ? 0 : -1}
        aria-hidden={String(!isFocusable)}
      >
        {children}
      </a>
    )}
    {!enabled && (
      children
    )}
  </>
)

export const SocialSection = memo(({ logType, socialAccounts = [], topDivider, bottomDivider, isFocusable }) => {
  const tiktok = socialAccounts.find(propEq('social_account_type', 'tiktok'))
  const tiktokEnabled = !!prop('social_account_user_name', tiktok)
  const twitter = socialAccounts.find(propEq('social_account_type', 'twitter'))
  const twitterEnabled = !!prop('social_account_user_name', twitter)
  const instagram = socialAccounts.find(propEq('social_account_type', 'instagram'))
  const instagramEnabled = !!prop('social_account_user_name', instagram)
  const youtube = socialAccounts.find(propEq('social_account_type', 'youtube_channel'))
  const youtubeEnabled = !!prop('social_account_user_name', youtube)
  const pinterest = socialAccounts.find(propEq('social_account_type', 'pinterest'))
  const pinterestEnabled = !!prop('social_account_user_name', pinterest)
  const snapchat = socialAccounts.find(propEq('social_account_type', 'snapchat'))
  const snapchatEnabled = !!prop('social_account_user_name', snapchat)
  const onPress = (type) => () => logEvent(`action.${logType || 'Profile'}.${TYPES[type]}`)

  return (
    <Fragment>
      {topDivider && <div className={c.divider} />}
      <div className={c.container}>
        <Wrapper isFocusable={isFocusable} type="tiktok" data={tiktok} enabled={tiktokEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={tiktokEnabled ? TiktokEnabled : TiktokDisabled}
            className={c.icon}
          />
        </Wrapper>
        <Wrapper isFocusable={isFocusable} type="twitter" data={twitter} enabled={twitterEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={twitterEnabled ? TwitterEnabled : TwitterDisabled}
            className={c.icon}
          />
        </Wrapper>
        <Wrapper isFocusable={isFocusable} type="instagram" data={instagram} enabled={instagramEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={instagramEnabled ? InstagramEnabled : InstagramDisabled}
            className={c.icon}
          />
        </Wrapper>
        <Wrapper isFocusable={isFocusable} type="youtube" data={youtube} enabled={youtubeEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={youtubeEnabled ? YoutubeEnabled : YoutubeDisabled}
            className={c.icon}
          />
        </Wrapper>
        <Wrapper isFocusable={isFocusable} type="pinterest" data={pinterest} enabled={pinterestEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={pinterestEnabled ? PinterestEnabled : PinterestDisabled}
            className={c.icon}
          />
        </Wrapper>
        <Wrapper isFocusable={isFocusable} type="snapchat" data={snapchat} enabled={snapchatEnabled} onPress={onPress}>
          <img
            alt=""
            aria-hidden="true"
            src={snapchatEnabled ? SnapchatEnabled : SnapchatDisabled}
            className={c.icon}
          />
        </Wrapper>
      </div>
      {bottomDivider && <div className={c.divider} />}
    </Fragment>
  )
})
