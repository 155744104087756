import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Slider, { Range } from 'rc-slider'

import s from './DotsSelector.module.scss'
import 'rc-slider/assets/index.css'
import './marks.module.scss'

const contains = (arr, value) => {
  return arr instanceof Array ? arr.indexOf(value) !== -1 : arr === value
}

const trackStyle = {
  height: '6px',
  background: '#048ce4'
}

const railStyle = {
  height: '6px',
  background: '#e1e1e1'
}

const handleStyle = {
  width: '48px',
  height: '48px',
  background: '#048de4',
  bottom: '-20.5px',
  border: 'none',
  boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.2)',
  ':focus': {
    boxShadow: 'none'
  }
}

const dotStyle = {
  display: 'none'
}

const activeDotStyle = {
  display: 'none'
}

const WEEKDAY_BEDTIME = 'Weekday Bedtime'

export class DotsSelector extends React.PureComponent {
  static propTypes = {
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    mode: PropTypes.oneOf([
      'single',
      'range'
    ])
  };

  static defaultProps = {
    mode: 'single',
    disabled: false,
    input: null
  };

  constructor(props) {
    super(props)
    const { input, mode, value } = props
    let stateValue = input ? input.value || input.defaultValue : value || props.min

    if (mode === 'range') {
      stateValue = stateValue ? [+stateValue[0], +stateValue[1]] : [+props.min, +props.max]
    } else {
      stateValue = stateValue != null ? +stateValue : +props.min
    }

    this.state = {
      value: stateValue
    }
    props.input.onChange(stateValue)
  }

  handleChange = (value) => {
    this.setState({ value })
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(value)
    }
  }

  getMarks = () => {
    const { input, name, max, min } = this.props

    const isLabels = input
      ? input.name !== WEEKDAY_BEDTIME
      : name !== WEEKDAY_BEDTIME

    return isLabels
      ? Array((max + 1) - min).fill(0).reduce((accum, item, idx) => {
        const label = idx + min
        accum[label] = label
        return accum
      }, {})
      : {}
  }

  render() {
    const { leftLabel = '', rightLabel = '', min, max, mode, disabled } = this.props
    const { value } = this.state

    const marks = this.getMarks()

    return (
      <div className={s.container}>
        {(rightLabel || leftLabel) && (
          <div className={s.labels}>
            <span
              className={cn({ [s.activeLabel]: contains(value, min) })}
              dangerouslySetInnerHTML={{ __html: leftLabel }}
            />
            <span
              className={cn({ [s.activeLabel]: contains(value, max) })}
              dangerouslySetInnerHTML={{ __html: rightLabel }}
            />
          </div>
        )}
        {
          mode === 'range'
            ? (
              <Range
                railStyle={railStyle}
                trackStyle={[trackStyle]}
                dotStyle={dotStyle}
                marks={marks}
                activeDotStyle={activeDotStyle}
                handleStyle={[handleStyle, handleStyle]}
                onChange={this.handleChange}
                value={value}
                allowCross={false}
                disabled={disabled}
                dots
                min={min}
                max={max}
                className='range-slider'
              />
            )
            : (
              <Slider
                railStyle={railStyle}
                trackStyle={trackStyle}
                dotStyle={dotStyle}
                activeDotStyle={activeDotStyle}
                handleStyle={handleStyle}
                disabled={disabled}
                included={false}
                value={value}
                marks={marks}
                onChange={this.handleChange}
                dots
                min={min}
                max={max}
              />
            )
        }
      </div>
    )
  }
}
