import { Fragment } from 'react'
import * as Sentry from '@sentry/react'
import { put, call, select, take } from 'redux-saga/effects'
import { show } from 'redux-modal'
import { push, replace } from 'connected-react-router'
import path from 'ramda/es/path'
import jwtDecode from 'jwt-decode'
import { ZendeskAPI } from "react-zendesk/src"

import { identify, logEvent } from 'services/helpers'
import getRedirectPath from 'services/redirectHelper'
import { setToUpperCase } from '../../services/helpers'
import communitySelectors from '../selectors/CommunitySelectors'
import homeSelectors from '../selectors/HomeSelectors'
import profileSelectors from '../selectors/ProfileSelectors'
import userSelectors from '../selectors/UserSelectors'


import {
  ABOUT_ME, 
  NO_NETWORK, 
  SWIPE, 
  WELCOME
} from 'constants/urls'
import API from 'services/api'
import i18n from 'services/i18n'
import { userActions } from '../actions/UserActions'
import { profileActions } from '../actions/ProfileActions'

import { homeActions, homeTypes } from '../actions/HomeActions'
import { communityActions, communityTypes } from '../actions/CommunityActions'

export function* proceedInvitationLoggedIn(action) {
  const { responseSuccess } = action

  try {
    const invitationData = yield select(homeSelectors.invitationDataSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const invitationResponse = yield call(API.reassociate, accessToken, invitationData)

    if (invitationData && invitationResponse?.res?.memberships?.length) {
      yield call(proceedSignInSuccess, { res: invitationResponse?.res, method: 'invitation' })
    } else {
      const description = path(['err', 'error', 'description'], invitationResponse)
      const errorCode = path(['err', 'error', 'code'], invitationResponse)

      if (description) {
        yield put(show(
          'infoModal',
          { label: setToUpperCase(errorCode), description, onHide: !accessToken }
        ))
      }
    }
  } catch (err) {
    const description = path(['error', 'description'], err)
    const errorCode = path(['error', 'code'], err)

    if (description) {
      yield put(show(
        'infoModal',
        { label: setToUpperCase(errorCode), description }
      ))
    }
  }

  yield put(responseSuccess())
}

export function* proceedSignInSuccess(data) {
  const { res: authRes, email, name, method, selectedMembershipId } = data?.data || data
  // const authRes = {"memberships":[{"membershipId":"50fd755f-ba40-4c4c-8211-c86ae47e7a71","l1Id":4,"l2Id":2,"l3Id":1,"l4Id":1,"networkName":"RoomSync Villas","subNetworkName":"Incoming Students","networkRoomName":"All Room Types","l4Name":"Master","removed":false,"next":"https://stagingapi.roomsync.com/users/50fd755f-ba40-4c4c-8211-c86ae47e7a71/potential_roommates","creationDate":[2022,12,18]},{"membershipId":"93732522-8187-489b-90a8-0c8891984158","l1Id":480,"l2Id":2,"l3Id":2,"l4Id":0,"networkName":"2125 Franklin","subNetworkName":"Signed Residents 23-24","networkRoomName":"4 Bed - 4 BAth A","l4Name":null,"removed":false,"next":"https://stagingapi.roomsync.com/users/93732522-8187-489b-90a8-0c8891984158/user_info_verification","creationDate":[2022,12,17]},{"membershipId":"a1860b29-e703-4b37-90e2-9eb05acd865c","l1Id":480,"l2Id":1,"l3Id":5,"l4Id":0,"networkName":"2125 Franklin","subNetworkName":"Signed Residents 22-23","networkRoomName":"4 Bed - 4 Bath A","l4Name":null,"removed":false,"next":"https://stagingapi.roomsync.com/users/a1860b29-e703-4b37-90e2-9eb05acd865c/potential_roommates","creationDate":[2022,12,17]},{"membershipId":"af1ff632-a9c7-4325-904a-8206ce45bf7a","l1Id":4,"l2Id":3,"l3Id":1,"l4Id":0,"networkName":"RoomSync Villas","subNetworkName":"Signed Residents","networkRoomName":"3 bedroom","l4Name":null,"removed":false,"next":"https://stagingapi.roomsync.com/users/af1ff632-a9c7-4325-904a-8206ce45bf7a/user_info_verification","creationDate":[2022,12,18]}],"auth_token":"eyJ0b2tlblR5cGUiOiJVc2VySnd0IiwidWlkIjoxMDIyMDQxNDYyNDMwMDgxNiwibGV2ZWwxIjowLCJsZXZlbDIiOjAsImxldmVsMyI6MSwibGV2ZWw0IjoxLCJzYXZlZFdpemFyZFN0YXRlIjo5OSwiZXhwaXJlcyI6MTY5MTI5MDkwNjU3Mn0.im3prqWu_3T8JhdE_FdorLEC7k_VGDIzIzE1mG7JIWg","user_id":"10220414624300816"}
  const { auth_token } = authRes
  let res = authRes

  try {
    if (!method) {
      if (auth_token) {
        yield put(
          userActions.updateAuthData({
            accessToken: auth_token
          })
        )
      }
      const accessToken = yield select(userSelectors.accessTokenSelector)
      const refreshData = yield call(API.refreshTokenSaga)
      res = refreshData?.res

      if (refreshData.code === 401) {
        yield put(
          userActions.updateAuthData({
            accessToken: null
          })
        )
        yield put(show(
          'infoModal',
          {
            logoutOnHide: true,
            label: i18n.t('profile.removedTitle'),
            description: (
              <p>
                {i18n.t('profile.removedInfo')} <a href='email:support@roomsync.com'>support@roomsync.com</a>
              </p>
            )
          }
        ))
      }
    }

    let membership = res.memberships[0]
    let userId = res.user_id
    let membershipId = membership?.membershipId

    const memberships = res.memberships.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))

    if (memberships.length > 1) {
      yield put(show('membershipSelectModal', { memberships, selectedMembershipId }))

      const { data } = yield take(homeTypes.SET_MEMBERSHIP_ATTEMPT)

      membership = data
      membershipId = data.membershipId
    } else if (memberships.length === 0) {
      yield put(
        userActions.updateRegisterData({
          networkId: null,
          subnetworkId: null,
          unitTypeId: null,
          roomTypeId: null
        })
      )
      yield put(replace(NO_NETWORK))
      return
    }

    if (!userId) {
      yield put(
        userActions.updateAuthData({
          membershipId,
          memberships
        })
      )
    }

    if (userId) {
      yield put(
        userActions.updateAuthData({
          accessToken: res.auth_token,
          userId,
          membershipId,
          memberships
        })
      )

      Sentry.configureScope(scope => {
        scope.setUser({ id: userId })
      })
      ZendeskAPI('messenger', 'prefill', {
        name: {
          value: name,
          readOnly: true // optional
        },
        email: {
          value: email,
          readOnly: true // optional
        },
        notes: {
          value: 'test notes info'
        }
      })
      yield call(identify, userId, email)
      yield call(logEvent, `action.SignIn.Method.${method}`)
    }

    yield put(
      userActions.updateRegisterData({
        networkId: membership.l1Id,
        subnetworkId: membership.l2Id,
        unitTypeId: membership.l3Id,
        roomTypeId: membership.l4Id,
        firstName: name && name.split(' ')[0],
        lastName: name && name.split(' ')[1],
        bioEnabled: membership.bioEnabled,
        questionsSaved: membership.questionsSaved,
        email
      })
    )

    yield put(communityActions.getContent())
    yield put(profileActions.getProfile()) 
    yield take(communityTypes.GET_CONTENT_SUCCESS)

    const initialRoute = yield select(homeSelectors.initialRouteSelector)

    if (initialRoute === '/saved') {
      yield put(homeActions.setInitialRoute(null))
      yield put(push(initialRoute))
    } else {
      const isNetworkClosed = yield select(communitySelectors.isNetworkClosedSelector)
      const matchingRoundStatus = yield select(communitySelectors.matchingRoundStatusSelector)
      const aboutMeHasAllAnswers = yield select(profileSelectors.aboutMeHasAllAnswersSelector)
      const areQuestionsSaved = yield select(userSelectors.areQuestionsSavedSelector)


      const redirectUrl = getRedirectPath(membership, matchingRoundStatus)

      if((!aboutMeHasAllAnswers || !areQuestionsSaved) && redirectUrl === SWIPE) {
        yield put(push(ABOUT_ME))
      }

      if(isNetworkClosed && redirectUrl === SWIPE) {
        yield put(push(WELCOME))
      } else {
        yield put(push(getRedirectPath(membership, matchingRoundStatus)))
      }

      

      
    }
  } catch (err) {
  }
}

export function* proceedSignInFailure(action) {
  const { err } = action || {}

  try {
    if (err) {
      const description = path(['error', 'description'], err)
      const errorCode = path(['error', 'code'], err)

      if (description && errorCode !== 'Unauthorized') {
        yield put(show(
          'infoModal',
          {
            description,
            label: setToUpperCase(errorCode)
          }
        ))
        return
      }
    }

    yield put(show(
      'infoModal',
      {
        showMail: true,
        label: 'Invitation Link is Required',
        description: (
          <Fragment>
            <p>
              You can only register for an account if you have a special invitation link. Depending on your community, this could be sent by them or directly by us at RoomSync.
            </p>
            <p>
              If you've previously accessed your matching group, you may be now logging with a different Facebook, Google, or Apple account that we don't recognize.
            </p>
            For help, please contact us at
            <br />
          </Fragment>
        )
      }
    ))
  } catch (err) {
  }
}

export function* getInvitationPreflight(action) {
  const { responseSuccess, data: { passcode } } = action

  try {
    const { res, err } = yield call(API.getInvitationPreflight, passcode)
    const accessToken = yield select(userSelectors.accessTokenSelector)

    if (res && res.invitationType === 'UNIQUE') {
      const method = res?.loginMethod?.charAt(0).toUpperCase() + res?.loginMethod?.slice(1).toLowerCase()
      const membershipIds = yield select(userSelectors.membershipsIdsSelector)
      const userId = yield select(userSelectors.userIdSelector)
      const claimedAndMembershipsMatched = !!method && membershipIds.includes(res.membershipId)

      if (claimedAndMembershipsMatched) {
        const memberships = yield select(userSelectors.membershipsSelector)

        yield call(proceedSignInSuccess, { res: { memberships }, method: method || 'invitation', selectedMembershipId: res.membershipId })
        yield put(responseSuccess())
        return
      }

      if (method && userId != res.userId) {
        yield put(homeActions.setInvitation({
          passcode,
          loginMethod: res.loginMethod,
        }))

        yield put(show(
          'infoModal',
          {
            description: `You have already claimed this invitation. Please sign in with the same ${method} account you used to register. If you have multiple ${method} accounts, please try signing in with each.`,
            label: `Sign in with ${method}`,
          }
        ))
        yield put(responseSuccess())
        return
      }

      yield put(show('invitationInfoModal', {
        ...res,
        passcode
      }))
    } else if (res && res.invitationType === 'GENERAL') {
      yield put(homeActions.setInvitation({ passcode }))

      if (accessToken) {
        yield put(homeActions.proceedInvitationLoggedIn())
        yield take(homeTypes.PROCEED_INVITATION_LOGGED_IN_SUCCESS)
      }
    } else if (err) {
      const description = path(['error', 'description'], err)
      const errorCode = path(['error', 'code'], err)

      yield put(show(
        'infoModal',
        { label: setToUpperCase(errorCode), description }
      ))
    }

    yield put(responseSuccess())
  } catch (err) {
    yield put(responseSuccess())
    yield put(show(
      'infoModal',
      { label: 'Something went wrong', description: 'Please try again later' }
    ))
  }
}

export function* facebookLogin(action) {
  const { responseSuccess, responseFailure, data: { accessToken, isRegister, name, email } } = action
  // yield call(proceedSignInSuccess, {
  //   res: null,
  //   email: '',
  //   name: '',
  //   method: 'facebook'
  // })
  // return
  try {
    yield call(logEvent, 'action.FacebookLogin')

    if (accessToken) {
      const invitationData = yield select(homeSelectors.invitationDataSelector)
      if (!invitationData && isRegister) {
        yield call(proceedSignInFailure)
        yield put(responseFailure())
        return
      }

      const { res, err } = yield call(API.facebookAuth, accessToken, isRegister, invitationData)

      if (res?.user_id) {
        yield call(proceedSignInSuccess, {
          res,
          email,
          name,
          method: 'facebook'
        })
        yield put(responseSuccess())
      } else {
        yield call(proceedSignInFailure, { err })
        yield put(responseFailure())
      }
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* googleLogin(action) {
  const { responseSuccess, responseFailure, data: { code, isRegister, error } } = action

  try {
    if (code) {
      yield call(logEvent, 'action.GoogleLogin')
      const invitationData = yield select(homeSelectors.invitationDataSelector)
      if (!invitationData && isRegister) {
        yield call(proceedSignInFailure)
        yield put(responseFailure())
        return
      }

      const { res, err } = yield call(API.googleAuth, code, isRegister, invitationData)

      if (res?.user_id) {
        yield call(proceedSignInSuccess, { res, method: 'google' })
        yield put(responseSuccess())
      } else {
        yield call(proceedSignInFailure, { err })
        yield put(responseFailure())
      }
    } else {
      yield put(responseFailure({ resetInvitation: !error }))
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* appleAuth(action) {
  const { responseSuccess, responseFailure, data: { code, identityToken, onError } } = action

  try {
    yield call(logEvent, 'action.AppleLogin')

    const { kid } = jwtDecode(identityToken, { header: true })
    const tokenResponse = jwtDecode(identityToken)
    const invitationData = yield select(homeSelectors.invitationDataSelector)
    const { res, err } = yield call(API.appleAuth, code, true, kid, identityToken, invitationData)

    if (err) {
      yield call(proceedSignInFailure)
      yield put(responseFailure())
      return
    }

    if (res) {
      const isEmailPrivate = (tokenResponse.email || '').includes('@privaterelay.appleid.com')
      const email = isEmailPrivate ? null : tokenResponse.email

      yield call(proceedSignInSuccess, {
        res,
        email,
        method: 'apple',
      })
      yield put(responseSuccess())
    } else {
      console.log("TOKEN RESPONSE ERROR: ", err)
      yield call(proceedSignInFailure, { err })
      yield put(responseFailure())
    }
  } catch (err) {
    console.log("TOKEN RESPONSE ERROR #1: ", err)
    onError()
    yield put(responseFailure())
  }
}

export function * getLoginMethod (action) {
  const { responseFailure, responseSuccess, data: { email } } = action

  try {
    const { res } = yield call(API.getLoginMethod, email)

    if (res && res.loginMethods?.length) {
      yield put(show(
        'infoModal',
        {
          label: i18n.t('home.forgotLogin'),
          description: i18n.t('home.methodInfo', { types: res.loginMethods.join(', ') }),
        }
      ))
      yield put(responseSuccess())
    } else {
      yield put(show(
        'infoModal',
        {
          label: i18n.t('home.forgotLogin'),
          description: i18n.t('home.methodInfoNone'),
        }
      ))
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure())
  }
}
