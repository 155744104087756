import { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Button } from 'components/Button';
import SkipIcon from 'static/images/arrow-right-red-2.svg';
import ViewIcon from 'static/images/eye.svg';
import RequestIcon from 'static/images/user-plus.svg';
import LikeIcon from 'static/images/arrow-left-green-2.svg';
import ChatIcon from 'static/images/chatBlue.svg'
import i18n from 'services/i18n';

import s from './SwipeCardActionButton.module.scss';

const buttonTypes = {
  skip: { icon: SkipIcon, className: s.skip },
  view: { icon: ViewIcon, className: s.view },
  request: { icon: RequestIcon, className: s.request },
  like: { icon: LikeIcon, className: s.like },
  chat: { icon: ChatIcon, className: s.chat },
};

const SwipeCardActionButton = ({ onClick, className, type }) => {
  const [animate, setAnimate] = useState(false);
  const { icon, className: typeClassName } = buttonTypes[type];

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    setAnimate(true);
    onClick();
  }, [onClick]);

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => setAnimate(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [animate]);

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      className={cn(
        s.container,
        typeClassName,
        className,
        animate && s.animate,
        animate && s[`animate${type.charAt(0).toUpperCase() + type.slice(1)}`]
      )}
      aria-label={i18n.t(`swipes.${type}`)}
    >
      <div className={s.content}>
        <img src={icon} alt="" aria-hidden="true" />
        <span className={s.label}>{i18n.t(`swipes.${type}`)}</span>
      </div>
    </Button>
  );
};

export { SwipeCardActionButton };