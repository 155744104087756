import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import s from './CommonItem.module.scss'

const CommonItem = ({ item, label, small }) => {
  return (
    <div className={cn(s.container, small && s.small)}>
      <img src={item.image_url} alt={item.label} className={cn(small && s.small)} />
      {label && !small && <p>{item.label}</p>}
    </div>
  )
}

CommonItem.propTypes = {
  item: PropTypes.object.isRequired,
  label: PropTypes.bool,
  small: PropTypes.bool
}

CommonItem.defaultProps = {
  label: true,
  small: false
}

export { CommonItem }
