export default {
  default: {
    selected: "Seleccionado",
    jumpNavigation: 'Ir a navegación',
    jumpContent: 'Ir al contenido principal',
    prevPage: 'Pagina anterior',
    'Enter Your Basic Information': 'Introduzca su información básica',
    'Did RoomSync have any impact on your decision to sign a lease at your community?': '¿RoomSync tuvo algún impacto en tu decisión de firmar un contrato de arrendamiento en tu comunidad?',
    yes: 'Sí',
    no: 'No',
    saved: '"¡Guardado exitosamente!',
    updated: '¡Actualización exitosa!',
    needAll: '¡Por favor, responda todas las preguntas!',
    notAllowed: 'Palabras no permitidas encontradas'
  },
  home: {
    welcome: 'Bienvenido a',
    almost: 'Casi termino!',
    almost2: 'Regístrese con una cuenta social, para poder comenzar con nosotros.',
    how: '¿Cómo RoomSync utilizarias la cuenta social?',
    howInfo1: 'La aplicación de RoomSync utilizara su cuenta social para iniciar la aplicacion de  RoomSync. Enviaremos notificaciones por correo electrónico a la cuenta que decida utilizar y no publicaremos nada personal en su cuenta social cuando se una a RoomSync.',
    howInfo2: 'Solo puede registrarse con una cuenta social y no puede cambiar la cuenta social asociada a su cuenta RoomSynca en el futuro. Si tiene preguntas, siempre puede contactarnos en support@roomsync.com',
    howInfo2NoEmail: 'Solo puede registrarse con una cuenta social y no puede cambiar la cuenta social asociada a su cuenta RoomSynca en el futuro. Si tiene preguntas, siempre puede contactarnos en',
    already: '¿Ya estás registrado este año?',
    signIn: 'Continuar con',
    register: 'Registrarse',
    noAccount: '¿No tienes cuenta con nosotros?',
    needHelp: 'Si necesitas ayuda?',
    contactUs: 'Contactenos!',
    agree: 'Al suscribirse usted acepta nuestros',
    terms: 'Términos de Servicio',
    privacy: 'Política de Privacidad',
    withFacebook: '{{label}} con Facebook',
    withGoogle: '{{label}} con Google',
    withApple: '{{label}} con Apple',
    getMobile: 'Obtén nuestra aplicación móvil, ¡es genial!',
    onlyMobile: 'El sitio web de RoomSync no puede brindarle el nivel de experiencia de usuario que nos gustaría en los dispositivos móviles.',
    facebookUnavailable: 'Inicio de sesión de Facebook no disponible. ¡Por favor revise su configuración de privacidad!',
    googleUnavailable: 'Google Login no está disponible',
    forgotLogin: '¿Olvidó su inicio de sesión?',
    enterAddress: 'Ingrese su dirección de correo electrónico',
    emailPlaceholder: 'Dirección de correo electrónico',
    submit: 'Enviar',
    methodInfo: 'Nuestros registros muestran que inició sesión con una cuenta {{types}}. Por favor, intente iniciar sesión con todas las cuentas {{types}} a las que tenga acceso, incluso si son diferentes de la dirección de correo electrónico donde recibe notificaciones de RoomSync. Si aún no puede iniciar sesión, contáctenos en support@roomsync.com para obtener ayuda.',
    methodInfoNone: 'No hay ninguna cuenta de RoomSync asociada con este correo electrónico.',
  },
  about: {
    title: 'Información Personal',
    save: 'Guardar',
    noPreferences: 'Sin preferencias',
    roommateRespond: '¿Cómo debería responder tu compañera de cuarto?',
    roommateRespond2: '¿Cómo debe responder su compañero de habitación a la pregunta anterior?',
    saveAria: 'El botón de guardar'
  },
  chat: {
    title: 'Mensajes',
    noConversations: 'No tiene mensajes.',
    unavailable: 'Mensajes no está disponible.',
    remove: 'Eliminar',
    cancel: 'Cancela',
    chat: 'Mensajes',
    last: 'Último mensaje en',
    you: 'Tu',
    removePrompt: '¿Estás seguro de que quieres eliminar el mensaje?',
    inputPlaceholder: 'Escribe un mensaje...',
    firstMessage: 'Primer mensaje de {{from}}. Creado en {{createdAt}}',
    createdAt: 'Creado en {{createdAt}}',
    messageFrom: 'Mensaje de {{from}}',
    report: 'Reporta'
  },
  community: {
    title: 'Comunidad',
    infoContent: 'El período de coincidencia en RoomSync finalizará a las 8:00 pm EST en la fecha de finalización que se muestra aquí. Si no elige a todos matches antes de que finalice el período de coincidencia, le recomendamos que se comunique con la oficina de vivienda para preguntar sobre las políticas de asignación de compañeros de habitación.',
    infoLabel: 'El Ultimo Día Para',
    matchingEnd: 'Emparejamiento de RoomSync Termina:',
    newPost: 'Publicación Nueva',
    newMessage: 'Nuevo mensaje de publicación',
    postLimitError: 'Publicacion del comunidad tiene que estar entre 1 y 1500 caracteres!',
    post: 'Envía',
    info: 'Información de Comunidad',
    posts: 'Publicaciones de la Comunidad',
    common: 'En común',
  },
  housing: {
    title: 'Preferencias de Habitación',
    searchPlaceholder: 'Describe preferencias de habitación',
    setHousing: 'Guardar',
    add: 'Agrega Preferencias de Habitación'
  },
  main: {
    community: 'Comunidad',
    chat: 'Mensajes',
    find: 'Busca',
    matches: 'Matches',
  },
  majors: {
    title: 'Especialidades Academicas',
    searchPlaceholder: 'Describe tú especialidad académica',
    setMajors: 'Guardar',
    add: 'Agrega Especialidad Academica'
  },
  matches: {
    title: 'Mis Matches',
    infoRoommate: '¡Felicitaciones por completar completamente su solicitud! Ahora que su unidad está llena, las solicitudes de matching se han desactivado. Si usted o uno de sus compañeros de habitación solicitados deja sus matches actuales, podrá volver a enviar y recibir nuevas solicitudes.',
    directions: 'Dirreciones de Comunidad:',
    leaveTitle: '¿Esta seguro que quiere dejar a sus matches actuales?',
    leaveCurrent: 'Si, dejo a mis matches actuales',
    no: 'No',
    find: 'Encuentra un Combinación',
    dontSee: '¿Por qué no veo una solicitud?',
    current: 'Compañeros de Cuarto',
    currentInfo: 'Matches actuales son solicitudes que usted envió o recibió que han sido aceptadas. La lista también puede incluir compañeros de habitación que ya se emparejaron con alguien que seleccionó, así como coincidencias que el personal de su comunidad ha hecho en su nombre.',
    compatible: 'compatible',
    likes: '{{firstName}} ¡También Le Gustas!',
    view: 'Ver {{firstName}}\'s Perfil',
    showProfiles: 'Ver Los Perfiles Que Te Gustan',
    noMatches: 'No tiene compañeros de cuarto.',
    leave: 'Salir de mis coincidencias actuales',
    unshare: '¿Está seguro que no quiere compartir su habitación con {{name}}?',
    share: '¿Está seguro que quiere compartir su habitación con {{name}}?',
    congratulations: '¡Felicidades!',
    incoming: 'Solicitud Entrante',
    outgoing: 'Solicitud Saliente',
    outgoingRequests: 'Solicitud Saliente ({{count}})',
    incomingRequests: 'Solicitud Entrante ({{count}})',
    looking: '¿Buscas más compañeros de cuarto?',
    keep: 'Siga buscando',
    or: ' o vea ',
    viewSaved: 'sus personas guardadas.',
    noRequests: 'No tienes solicitudes.',
    unshareLabel: 'No Compartir',
    openMatchesLabel: 'Abre modal de descripción de matches actuales',
    cancel: 'Cancelar',
    save: 'Ahorrar',
    shareLabel: 'Quiero compartir mi habitación con ellas',
    knowLabel: 'Las conocía antes de Roomsync',
    knowLabelStrong: 'Las <b>{{name}}</b> antes de Roomsync',
  },
  more: {
    title: 'Más',
    mobileApp: 'Aplicación Movil',
    editProfile: 'Edite Perfil',
    update: 'Edite Su Información Personal',
    switch: 'Cambiar Membresía',
    contact: 'Contactenos',
    logout: 'Cerrar Sesión',
    signedWith: 'Estás registrado con {{type}}',
    logoutTitle: '¿Estás seguro de que quieres cerrar la sesión?',
    yes: 'Sí',
    no: 'No',
    delete: 'Borrar cuenta',
    language: 'Idioma',
    notification: '{{count}} notificación',
    notifications: '{{count}} notificaciónes',
  },
  network: {
    searchPlaceholder: 'Escriba su propiedad...',
    closed: 'Esta red está cerrada',
    info1: 'Sabemos que está emocionado de encontrar un compañero de habitación, ¡nosotros también estamos emocionados por usted!',
    info2: 'Vuelva a consultar durante el período de búsqueda de compañeros de habitación desde',
    info3: '\nhasta',
    info4: ' para empezar a buscar a tu compañero de habitación.\n\n¡Por favor comuníquese con nosotros si tiene alguna otra pregunta!',
    contact: 'Contáctenos si necesita ayuda en'
  },
  noNetwork: {
    title: 'Puede registrarse en el enlace de la invitación',
    gotLink: '¿Tiene un enlace de invitación?',
    topDescription: 'Solo puede acceder a RoomSync con un enlace de invitación especial. Dependiendo de su comunidad, esto podría ser enviado por ellos o directamente por nosotros en RoomSync.',
    bottomDescription: 'Si ya accedió a RoomSync, es posible que ahora haya iniciado sesión con una cuenta diferente de Facebook, Google o Apple que no reconocemos. Si no está seguro de qué cuenta utilizó, contáctenos en',
    forHelp: 'para ayuda.',
    logOut: 'Cerrar Sesión',
    goBack: 'Regresa'
  },
  passcode: {
    label: 'Contraseña',
    continue: 'Continúa',
    invalid: 'Contraseña Inválida'
  },
  preview: {
    about: 'Sobre Mí',
    social: 'Cuentas Sociales',
    majors: 'Especialidades Academicas',
    housing: 'Preferencias de Habitación',
    roommates: 'Compañeros de Cuarto',
    questions: 'Preguntas',
    residentStatus: 'Estatus de Residente'
  },
  profile: {
    title: 'Perfil',
    matched: 'Emparejados',
    compatible: '% compatible',
    deny: 'Nega',
    accept: 'Acepta',
    cancelRequest: 'Cancela solicitar',
    sendRequest: 'Envíe una Solicitud',
    cancel: 'Cancela',
    hidden: '¡Tu perfil está oculto!',
    hiddenInfo: 'Los perfiles ocultos no pueden ver este contenido :(',
    unhideInfo: 'Puede mostrar su perfil en cualquier momento',
    byUpdating: 'actualizando su cuenta',
    here: 'aqui!',
    startChat: 'Iniciar una conversación',
    request: 'Envíe una solicitud',
    viewProfile: 'Ver perfil',
    network: 'La Red',
    subnetwork: 'La Subred',
    unit: 'Tipo de Unidad',
    room: 'Tipo de Habitación',
    selectMembership: 'Seleccione una Membresía',
    pressEnter: 'Para ver un perfil actual presione Entrar',
    preview: 'Perfil de vista previa',
    fullname: 'Tu nombre completo {{name}}',
    chatWith: 'Conversa con compañero de habitación',
    callWith: 'Llamar con compañero de habitación',
    settings: 'Configuración de Perfil',
    details: 'Perfil Detalles',
    removedTitle: 'Se eliminó el acceso a RoomSync',
    removedInfo: 'El administrador de su propiedad lo ha eliminado como usuario y ya no está autorizado para acceder a este grupo coincidente. Si tiene preguntas, comuníquese con',
    deletedTitle: 'Su cuenta de RoomSync ha sido eliminada.'
  },
  questions: {
    save: 'Guardar',
    title: 'Información Personal',
    alertTitle: 'Se Requiere Respuesta de Información Personal',
    alertDescription: 'Responda sus preguntas de Información Personal antes de continuar buscando a su compañero de habitación en RoomSync.',
  },
  request: {
    title: 'Solicitar',
    emptyError: 'El mensaje no puede estar vacío!',
    defaultError: 'Si compartes habitación con esta persona, habrá demasiadas personas en tu habitación. ¡Perdón!',
    sendRequest: 'Envíe una solicitud',
    sent: 'Solicitud Fue Enviada',
    view: 'Ver Solicitud',
    messagePlaceholder: 'Mensaje...',
    inBedroom: 'Compartiría dormitorio contigo.',
    inCommon: 'Compartiría espacios comunes contigo.',
  },
  swipes: {
    title: 'Mis Tarjetas',
    skipped: 'Omitidos',
    liked: 'Interesado',
    mutual: 'Mutual',
    oneWay: 'Interesado',
    noCards: 'No tienes tarjetas.',
    noMore: 'No hay mas tarjetas',
    loadMore: 'Carga más',
    checkLater: 'Sin Resultados de Búsqueda',
    skippedAll: 'Actualiza tus filtros de búsqueda para ver más usuarios.',
    whyDont: '¿Por qué no ves una solicitud?',
    reviewSwipes: 'Revisa tus deslizamientos',
    ohNo: '¡Oh, no! La persona que busca no está disponible.',
    wontBeAble: 'No podrás encontrar a esta persona si...',
    thisPerson: '• Este persona ',
    notRegistered: 'no ha registrado una cuenta',
    yet: ' con RoomSync todavía.',
    thisPersonHas: '• Esta persona tiene un',
    apartment: ' tipo de apartamento o tipo de habitación ',
    notCompatible: 'que no es compatible con el suyo según la configuración de su apartmento.',
    both: '• Ambos están',
    renewing: ' renovando usuarios. ',
    noHousing: 'Su oficina de vivienda puede emparejarlos si ambos están renovando usuarios y su compañero de habitación no se muestra en RoomSync.',
    thisPersonAlready: '• Este persona ya esta',
    fully: ' parte de un grupo completo ',
    noSpaces: 'sin espacios disponibles para más compañeros de habitación.',
    findMore: 'Encuentra más compañeros de habitación',
    checked: 'Si ha verificado los motivos anteriores y cree que debería poder encontrar a esta persona, ',
    letKnow: 'haznos saber.',
    like: 'TÉ GUSTA',
    skip: 'OMITE',
    next: 'Próxima',
    back: 'Regresa',
    userSwiped: 'Ya has revisado a este usuario.',
    userSwipedInfo: 'Encontramos resultados para usuarios con ese nombre en su área Mis Tarjetas',
    fullTitle: '¡Felicitaciones, tu grupo de partidos está lleno!',
    fullInfo: 'Como no le quedan espacios en su grupo, no verá ninguna tarjeta nueva para deslizar.',
    fullButton: 'Ver tu grupo de partidos',
  },
  search: {
    title: 'Buscar',
    byPreferences: 'Buscar por Preferencias',
    byName: 'Buscar por Nombre',
    firstName: 'Nombre',
    lastName: 'Apellido',
    majors: 'Especialidades Academica',
    housing: 'Preferencias de Habitación',
    group: 'Tamaño del Grupo',
    groupSize: 'Tamaño del Grupo: {{groupSize}}',
    apply: 'Aplicar Filtro de habitación',
    byApplying: 'Al aplicar el filtro de habitación, solo se mostrarán los usuarios que aún necesitan compañeros de habitación.',
    clear: 'Eliminar Filtros',
    submit: 'Aplicar Filtros',
    any: 'Alguno',
    lifestyle: 'Estilo De Vida',
    room: 'Habitación',
    results: 'Resultados de Búsqueda Para: {{name}}',
    clearSearch: 'Eliminar Búsqueda',
    clearFilters: 'Eliminar Filtros',
    editMajors: 'Editar especialidad academica',
    editHousing: 'Editar preferencias de habitación',
    filters: 'Filtros',
    registration: 'Estado de Registro'
  },
  social: {
    title: 'Cuentas Sociales',
    heading: 'Agrega sus cuentas sociales',
    add: 'Agregar',
    channel: 'canal',
    no: 'No tienes nombré de',
    invalid: 'Inválido {{label}}',
    username: 'usuario',
    invalidUsername: 'Invalid username',
    noAccount: 'No tienes nombré de usuario'
  },
  thread: {
    title: 'Envía',
    new: 'Nuevo Hilo',
    by: 'por',
    replies: 'Respuestas',
    reply: 'responde',
    noPosts: 'Aún no hay publicaciones',
    newPost: 'Publicación Nueva',
    newPostPlaceholder: 'Escribe una publicación...',
    sendAria: 'Enviar publicación',
    postAria: 'Enviar respuestas. Para navegar por las respuestas, use las teclas de flecha arriba / abajo'
  },
  verify: {
    updateTitle: 'Edite su Información Personal',
    welcome: 'Bienvenido a ',
    review: 'Ingrese Su Información Personal',
    tooltip1: 'Su comunidad no le permite modificar su información básica. Si ve algo inexacto, contáctenos en support@roomsync.com.',
    tooltip2: 'Si esta sección se deja en blanco, el primer nombre que nos envíe su comunidad se mostrará a otros usuarios.',
    tooltip3: 'Si esta sección se deja en blanco, el primer nombre que nos envíe su comunidad se mostrará a otros usuarios.',
    tooltip4: 'Si esta sección se deja en blanco, las notificaciones de RoomSync se enviarán al correo electrónico registrado en su comunidad. Si se ingresa una dirección de correo electrónico válida en esta sección, las notificaciones de RoomSync se enviarán solo a esta dirección de correo electrónico preferida. Tenga en cuenta que las direcciones de correo electrónico no son visibles para otros usuarios en RoomSync.',
    tooltip5: 'Al ocultar su perfil, ya no aparecerá en los resultados de otros usuarios. Si a alguien ya le ha gustado o le ha omitido, su tarjeta seguirá apareciendo en sus Deslizamientos guardados.\n\nOcultar su perfil no afectará a las coincidencias de sus matches actuales. Si te emparejan con alguien, seguirás emparejado. Continuarás recibiendo notificaciones de cualquier actividad nueva relacionada con tu cuenta, como nuevos compañeros de habitación, nuevos mensajes de chat o que alguien abandone tu partido.',
    tooltip6: 'Su número de teléfono será compartido con {{networkName}}',

    no: 'No',
    yes: 'Sí',
    firstName: 'Nombre',
    firstNameLimit: 'El nombre debe tener entre 1 y 50 caracteres',
    lastName: 'Apellido',
    lastNameLimit: 'El Apellido debe tener entre 1 y 50 caracteres',
    preferredTitle: 'Nombre Preferido',
    preferredInfo: '¿Le gustaría que su nombre apareciera diferente al de arriba para otros usuarios?',
    preferredFN: 'Nombre Preferido (opcional)',
    preferredLN: 'Apellido Preferido (opcional)',
    preferredFirstNameLimit: 'Preferido el nombre debe tener entre 1 y 50 caracteres',
    preferredLastNameLimit: 'Preferido el Apellido debe tener entre 1 y 50 caracteres',
    email: 'E-mail',
    preferredE: 'E-Mail Preferido (opcional)',
    emailError: 'Por favor, introduzca su dirección de correo electrónico correcta',
    phoneError: 'Proporcione el número de teléfono correcto',
    gender: 'Género',
    hide: 'Ocultar su perfil',
    phoneNumber: 'Teléfono',
    hideDescription: '¿Le gustaría ocultar su perfil?\nHacer esto lo ocultará para que no aparezca en los resultados de la pestaña\nBuscar para otros usuarios.',
    update: 'Actualizar',
    continue: 'Continúa',
    pronoun: 'Pronombre Personal',
    pronounOptional: 'Pronombre Personal (opcional)',
    pronounTooltip: 'Comparta sus pronombres personales con los demás. Puede actualizar su respuesta en cualquier momento.',
    pronounError: 'Póngase en contacto con support@roomsync.com y envíe sus pronombres personales.',
    genderRequired: 'Información Básica Requerida',
    genderDescription: 'Responda todas las preguntas en la sección de información básica de RoomSync',
    quaranteeLabel: 'Entiendo que las solicitudes de compañeros de cuarto y las coincidencias están sujetas a cambios y no pueden ser garantizadas por el equipo de arrendamiento de la comunidad.'
  },
  block: {
    title: 'Configuraciones de chat',
    block: 'Bloquear {{type}}',
    unblock: 'Desbloquear {{type}}',
  },
  confirmation: {
    title: '¿Salir sin guardar los cambios?',
    yes: 'Sí',
    no: 'No',
  },
  feedback: {
    title: 'Pregunta de la Encuesta'
  },
  gender: {
    cancel: 'Cancela',
    notSelected: 'No Seleccionado',
    pleaseSelect: 'Por favor seleccione',
    info: 'RoomSync solicita su género únicamente con el propósito de buscar compañeros de cuarto. Su género no se mostrará a otros usuarios. Elija la opción que crea que se adapta mejor a sus necesidades. Si necesita realizar un cambio en su selección de género en el futuro, comuníquese con RoomSync.',
  },
  invitation: {
    title: 'Eres {{firstName}} {{lastName}}?',
    info: 'Si continúa, se reclamará el enlace de invitación de uso único de {{firstName}} {{lastName}}. Si no eres esta persona y usas esta invitación, no se aceptarán tus coincidencias de compañeros de cuarto.',
    confirm: 'Si, Io soy {{firstName}} {{lastName}}',
    notMe1: 'No, esa es mi garante.',
    notMe2: 'No, no estoy relacionada con esta persona.',
    notMe3: 'No del todo, soy la garante.',
  },
  demo: {
    skipTitle0: '¿No está interesado?',
    skipDesc0: 'Si no está interesado en usuario, puedes "Omitirlo" por ahora.',
    skipFooter0: 'OMITE ESTA USUARIO AHORA',
    likeTitle0: '¿Cambiaste de opinión?',
    likeDesc0: 'Estos son los usuarios que se han omitido. Puede cambiar tú decisión y marcar me gusta deslizando su tarjeta hacia la derecha.',
    likeFooter0: 'LE GUSTA ESTA TARJETA AHORA',
    skipTitle1: '¿No está interesado?',
    skipDesc1: 'Si no está interesado en usuario, puedes "Omitirlo" por ahora. Siempre puedes cambiar de opinión.',
    skipFooter1: 'OMITE ESTA USUARIO AHORA',
    likeTitle1: 'Encontrar Compañeros de Cuarto',
    likeDesc1: 'Si marcas que té gusta él compañero en la aplicación en la pregunta (me gusta) y el otro compañero también marca (me gusta) como compañero, té lo haremos saber para que puedas conversar sobre ser compañeros de cuarto.',
    likeFooter1: 'LE GUSTA ESTA TARJETA AHORA',
    toSkip: 'Para omitir la tarjeta, presione la tecla de flecha izquierda del teclado',
    toLike: 'Para dar me gusta a la tarjeta, presione la tecla de flecha derecha del teclado'
  },
  languages: {
    title: 'Idioma',
    en: 'English',
    zh: '中文（简体)',
    pl: 'Polski',
    es: 'Español',
    ua: 'Українська',
    ru: 'Pусский',
    soon: '¡Idioma disponible pronto!',
  },
  notFound: {
    title: 'Página No Encontrada',
    oh: 'Oh No!',
    description: 'No se puede encontrar la página que está buscando. Póngase en contacto con support@roomsync.com o llame al 352.327.4061 ext. 2 para obtener ayuda.'
  },
  mobile: {
    title: 'RoomSync Mobile Aplicación',
    desc1: 'Puede usar RoomSync en su dispositivo iOS o Android para encontrar un compañero de cuarto. Simplemente busque la aplicación RoomSync en la App Store o en Google Play.',
    desc2: 'Asegúrese de iniciar sesión con su misma cuenta social en la aplicación móvil para asegurarse de que puede acceder a toda la información de su cuenta existente.'
  },
  video: {
    join: 'Únete ahora',
    joning: 'Unirse a la reunión',
    calling: 'Reunión inicial',
    joinTitle: 'Únete a la videollamada con',
    callTitle: 'Iniciar videollamada con',
    call: 'Llamada',
    cancel: 'Cancelar',
    reconnecting: 'Reconectando...',
    you: '(Tú)',
    disconnect: 'Desconectar'
  },
  welcome: {
    title: 'Bienvenidas',
    closed: 'RoomSync está actualmente cerrada',
    desc1: 'La fecha límite para elegir un compañero de habitación en RoomSync ha pasado',
    desc1_1: ' sin embargo, usted tiene acceso a otros ',
    desc1_2: 'membresías',
    desc1_3: ' que aún puede estar abierto para la coincidencia',
    desc1_4: '. Once matching closes, you are no longer able to see potential roommates, accept requests, or modify matches.',
    desc2: '¿No encontró un compañero de cuarto en RoomSync?',
    desc3: 'Recomendamos ponerse en contacto con su oficina de vivienda para preguntar cómo proceder con la selección de compañeros de habitación o para darles sus preferencias.',
    desc4: 'Hizo un partido, ¿qué pasa ahora?',
    desc5: 'Todas las coincidencias confirmadas que aparecieron en su pestaña Coincidencias ahora se han enviado a su oficina de vivienda como lo solicita su compañero de cuarto. Comuníquese con ellos directamente si tiene preguntas sobre los procedimientos de asignación de habitaciones.',
    desc6: '¿RoomSync cerró temprano?',
    desc7: 'A veces, las oficinas de vivienda necesitan cerrar la coincidencia antes de lo esperado. Si tiene preguntas sobre la fecha límite de RoomSync, consulte a su comunidad, ya que estas fechas son administradas por su oficina.',
    welcome: '¡Bienvenido a RoomSync!',
    welcome1: 'Complete su perfil de coincidencia de compañero de habitación y comparta sus preferencias ',
    welcome1_button: 'aquí.',
    welcome1_2: '\nSu comunidad hará todo lo posible para emparejarlo con compañeros de habitación compatibles en función de sus respuestas.',
    welcome1_3: ' También puedes acceder a tus otras membresías ',
    welcome1_4: 'aquí.',
    welcome2: 'Para obtener consejos sobre cómo completar su perfil, consulte el ',
    welcome2_button: 'Pestaña de la comunidad.',
    welcome3: 'Si tiene preguntas sobre los procedimientos de coincidencia o la asignación de habitaciones, comuníquese directamente con su comunidad. Si necesita ayuda para usar RoomSync, haga clic en ',
    welcome3_button: 'Contacta con nosotras',
    welcome3_2: ' contactar.',
  },
  delete: {
    title: 'Borrar cuenta',
    info: '¿Le gustaría eliminar su cuenta de RoomSync? Esta acción no se puede deshacer y eliminará permanentemente toda la información que proporcionó a RoomSync. En lugar de eliminar su cuenta, puede optar por ',
    hideProfile: 'ocultar su perfil aquí.',
    confirmTitle: '¿Estás segura de que quieres eliminar tu cuenta?',
    confirm: 'Sí, eliminar mi cuenta',
    nevermind: 'No importa'
  },
  ai: {
    enterKeyword: 'Ingrese palabra clave',
    keyword: 'Palabra clave',
    title: 'Asistente de Perfil de IA',
    writeForMe: 'Escríbeme esto',
    listUp: 'Enumere hasta tres palabras clave para su respuesta',
    cancel: 'Cancelar',
    next: 'Siguiente',
    pickUp: '¿Cómo eres? (Elija hasta {{count}}/3)',
    back: 'Volver',
    generate: 'Generar',
    calm: 'Calmado/a',
    ambitious: 'Ambicioso/a',
    friendly: 'Amigable',
    introverted: 'Introvertido/a',
    organized: 'Organizado/a',
    extroverted: 'Extrovertido/a',
    honest: 'Honesto/a',
    creative: 'Creativo/a',
    determined: 'Determinado/a',
    curious: 'Curioso/a',
    dependable: 'Confiable',
    empathetic: 'Empático/a',
    flexible: 'Flexible',
    independent: 'Independiente',
    kind: 'Amable',
  }
}
