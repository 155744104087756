import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { userTypes, userActions } = createActionsStructure('user', [
  { name: 'RESET_STORE' },
  { name: 'UPDATE_AUTH_DATA' },
  { name: 'UPDATE_REGISTER_DATA' },
  { name: 'DELETE_ACCOUNT', async: true },
  { name: 'SET_USER_VERIFY_INFO', async: true }
])
