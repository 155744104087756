import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { IconButton } from 'components/IconButton'

import { ProfileAvatarModal } from 'modals'
import CameraIcon from 'static/images/camera.svg'

import s from './Avatar.module.scss'

const AvatarContainer = ({ onClick, onRef, profileImage, showInfoIcon, withImageBorder }) => (
  <div className={cn(s.container, withImageBorder && s.withBorder)}>
    <img
      aria-hidden="true"
      alt="Your avatar"
      src={profileImage}
      className={s.avatar}
    />
    {
      showInfoIcon && (
        <IconButton
          onClick={onClick}
          className={s.uploadButton}
          aria-label="Upload avatar"
        >
          <img src={CameraIcon} tabIndex={-1} alt="Camera" aria-hidden="true" />
          <input type='file' accept='image/*' id='file' ref={onRef} style={{ display: 'none' }} tabIndex={-1} />
        </IconButton>
      )
    }
    <ProfileAvatarModal />
  </div>
)

AvatarContainer.propTypes = {
  profileImage: PropTypes.string.isRequired,
  withImageBorder: PropTypes.bool,
  showInfoIcon: PropTypes.bool
}

AvatarContainer.defaultProps = {
  withImageBorder: false,
  showInfoIcon: false
}

export const Avatar = AvatarContainer
