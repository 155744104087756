import { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'

import { Button } from 'components/Button'
import { KnownSwitch } from 'components/KnownSwitch'
import { roomActions } from 'redux/actions/RoomActions'
import i18n from 'services/i18n'

import s from './ShareBedroomModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class ShareBedroomModalView extends PureComponent {
  state = {
    previouslyKnown: this.props.previouslyKnown,
    isInBedroom: this.props.isInBedroom
  }

  handleConfirm = () => {
    const { handleHide, setRoommate, setKnown, id, isRoommates } = this.props

    if (this.state.previouslyKnown !== this.props.previouslyKnown) {
      setKnown({
        id,
        previouslyKnown: this.state.previouslyKnown
      })
    }

    if (isRoommates && this.state.isInBedroom !== this.props.isInBedroom) {
      setRoommate({
        id,
        isInBedroom: this.state.isInBedroom,
      })
    }
    handleHide()
  }

  handleKnownChange = () =>
    this.setState(({ previouslyKnown }) => ({ previouslyKnown: !previouslyKnown }))

  handleInBedroomChange = () =>
    this.setState(({ isInBedroom }) => ({ isInBedroom: !isInBedroom }))

  render() {
    const { handleHide, show, title, isRoommates, previouslyKnownEnabled } = this.props

    return (
      <Modal
        center
        focusTrapped
        open={show}
        onClose={handleHide}
        classNames={classNames}
      >
        <div className={s.container}>
          <h2 className={s.title}>{title}</h2>
          <div className={s.labels}>
            {
              previouslyKnownEnabled
                ? (

                  <KnownSwitch
                    value={this.state.previouslyKnown}
                    label={i18n.t('matches.knowLabel')}
                    onClick={this.handleKnownChange}
                  />
                )
                : null
            }
            {
              isRoommates
                ? (
                  <KnownSwitch
                    value={this.state.isInBedroom}
                    label={i18n.t('matches.shareLabel')}
                    onClick={this.handleInBedroomChange}
                  />
                ) : null
            }
            <div className={s.lastOption}>
              <Button className={s.optionLabelButton} onClick={handleHide}>
                {i18n.t('matches.cancel')}
              </Button>
              <Button className={s.optionLabelButton} onClick={this.handleConfirm}>
                {i18n.t('matches.save')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ShareBedroomModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  setKnown: roomActions.setKnown,
  setRoommate: roomActions.setRoommate,
}

const ConnectedModal = connect(null, mapDispatchToProps)(ShareBedroomModalView)

const ShareBedroomModal = connectModal({ name: 'shareBedroomModal' })(ConnectedModal)

export { ShareBedroomModal }
