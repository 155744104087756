import { createReducer } from 'services/reduxHelpers'
import { contentTypes } from '../actions/ContentActions'

/* ------------- Initial State ------------- */

export const initialState = {
  findYourNetwork: {
    isLoading: false
  },
  subNetwork: {
    isLoading: false,
    items: []
  },
  roomType: {
    isLoading: false,
    items: []
  },
  unitType: {
    isLoading: false,
    items: []
  },
  majorType: {
    isLoading: true,
    majors: []
  },
  passcode: {
    isLoading: false
  },
  housing: {
    isLoading: true,
    housing: []
  },
  verifyInfo: {
    isLoading: true
  }
}

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [contentTypes.GET_MAJORS_CONTENT_SUCCESS]: ({ majorType }, { data }) => ({
    majorType: {
      ...majorType,
      ...data,
      isLoading: false
    }
  }),
  [contentTypes.GET_MAJORS_CONTENT_FAILURE]: ({ majorType }) => ({
    majorType: {
      ...majorType,
      isLoading: false
    }
  }),
  [contentTypes.GET_MAJORS_CONTENT_ATTEMPT]: ({ majorType }) => ({
    majorType: {
      ...majorType,
      isLoading: true
    }
  }),

  [contentTypes.GET_HOUSING_CONTENT_ATTEMPT]: ({ housing }) => ({
    housing: {
      ...housing,
      isLoading: true
    }
  }),
  [contentTypes.GET_HOUSING_CONTENT_SUCCESS]: ({ housing }, { data }) => ({
    housing: {
      ...housing,
      ...data,
      isLoading: false
    }
  }),
  [contentTypes.GET_HOUSING_CONTENT_FAILURE]: ({ housing }) => ({
    housing: {
      ...housing,
      isLoading: false
    }
  }),

  [contentTypes.GET_VERIFY_INFO_CONTENT_ATTEMPT]: ({ verifyInfo }) => ({
    verifyInfo: {
      ...verifyInfo,
      isLoading: true
    }
  }),
  [contentTypes.GET_VERIFY_INFO_CONTENT_SUCCESS]: ({ verifyInfo }, { data }) => ({
    verifyInfo: {
      ...verifyInfo,
      ...data,
      isLoading: false
    }
  }),
  [contentTypes.GET_VERIFY_INFO_CONTENT_FAILURE]: ({ verifyInfo }) => ({
    verifyInfo: {
      ...verifyInfo,
      isLoading: false
    }
  })
})
