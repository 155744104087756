import React from 'react';
import inc from 'ramda/src/inc'
import map from 'ramda/src/map'
import range from 'ramda/src/range'
import compose from 'ramda/src/compose'
import always from 'ramda/src/always'
import identity from 'ramda/src/identity'
import applySpec from 'ramda/src/applySpec'
import Select from '@material-ui/core/Select';
import { WrappedFieldProps } from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, withStyles, makeStyles } from '@material-ui/core/styles';
import { COLOR_BLUE_PRIMARY, COLOR_SECONDARY, COLOR_WHITE } from 'styles/constants';
import i18n from 'services/i18n'

interface IProps extends WrappedFieldProps {
  maxGroupSize: number
}

const getOptions = compose(
  map(
    applySpec<Record<string, number>>({
      value: identity,
      label: identity,
    })
  ),
  range(1),
  inc
)

const CustomInput = withStyles((theme) =>
  createStyles({
    input: {
      width: 90,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 30,
      backgroundColor: COLOR_WHITE,
      border: '1px solid #ced4da',
      fontSize: 14,
      color: COLOR_BLUE_PRIMARY,
      paddingLeft: 24,
      fontWeight: 500,
      '&:focus': {
        borderRadius: 30,
        outline: `2px auto ${COLOR_SECONDARY}`,
        backgroundColor: COLOR_WHITE,
      },
      '&:before': {
        content: `"-"`,
        marginRight: 8,
        fontSize: 20,
      },
      '&:after': {
        content: `"+"`,
        marginLeft: 8,
        fontSize: 20,
      }
    },
  }),
)(InputBase);

const useFormControlStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: 90,
      marginTop: 10,
    },
  }),
);

export const GroupSizeSelectInput: React.FC<IProps> = props => {
  const formControlClasses = useFormControlStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.input.onChange(String(event.target.value))
  }

  return (
    <FormControl classes={formControlClasses}>
      <Select
        {...props.input}
        onChange={handleChange}
        input={<CustomInput />}
        IconComponent={always(null)}
      >
        <MenuItem value={0}>{i18n.t('search.any')}</MenuItem>
        {getOptions(props.maxGroupSize).map(({ value, label }) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
