import React from 'react';
import cn from 'classnames';

import { Button } from 'components/Button';

import s from './IconButton.module.scss'

interface IProps {
  onClick: VoidFunction
  className?: string
  "aria-label"?: string
}

export const IconButton: React.FC<IProps> = props => (
  <Button
    {...props}
    className={cn(
      s.container,
      props.className
    )}
  />
);
