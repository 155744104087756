import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import userSelectors from 'redux/selectors/UserSelectors'
import roommateSelectors from 'redux/selectors/RoommateSelectors'
import App from './App'
import AppStateProvider, { useAppState } from './state'
import ErrorDialog from './components/ErrorDialog/ErrorDialog'
import theme from './theme'
import { VideoProvider } from './components/VideoProvider'
import useConnectionOptions from './utils/useConnectionOptions'
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning'

const VideoApp = ({ setClassNames, roommateId, myUserId, sendMessage, match, show }) => {
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions(roommateId, myUserId)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UnsupportedBrowserWarning>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <App
            show={show}
            match={match}
            sendMessage={sendMessage}
            setClassNames={setClassNames}
          />
        </VideoProvider>
      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
  )
}

const mapStateToProps = createStructuredSelector({
  myUserId: userSelectors.userIdSelector,
  roommateId: roommateSelectors.roommateIdSagaSelector,
})

const VideoAppConnected = connect(mapStateToProps)(VideoApp)

export default ({ setClassNames, sendMessage, match, show }) => (
  <AppStateProvider>
    <VideoAppConnected
      show={show}
      match={match}
      sendMessage={sendMessage}
      setClassNames={setClassNames}
    />
  </AppStateProvider>
)
