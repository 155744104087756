import { PureComponent } from 'react'
import images from 'static'
import i18n from 'services/i18n'
import c from './RequestDialog.module.scss'
import { Button } from 'components/Button'
import { KnownSwitch } from 'components/KnownSwitch'

export class RequestDialog extends PureComponent {
  state = {
    emptyMessageError: false
  }

  onSendRequest = () => {
    const { onSendRequest } = this.props
    const value = this.input.value

    if (!value) {
      this.setState({ emptyMessageError: true })
    } else {
      onSendRequest(value)
    }
  }

  handleRef = ref => { this.input = ref }

  handleKnownChange = (user) => {
    const { setKnown } = this.props

    const id = user.membershipId || user.membership_id
    const previouslyKnown = !user.previouslyKnown

    setKnown({
      id,
      rootId: id === this.props.user.membershipId ? null : this.props.user.membershipId,
      previouslyKnown
    })
  }

  render() {
    const { emptyMessageError } = this.state
    const { avatar, fullName, disabled, user, previouslyKnownEnabled } = this.props

    return (
      <div className={c.container}>
        <img src={avatar} className={c.avatar} rel='noreferrer' alt="" aria-hidden="true" />
        <h2 className={c.name}>{fullName}</h2>
        <img src={images.bubble} className={c.bubble} alt="" aria-hidden="true" />
        <textarea
          type='text'
          maxLength={500}
          ref={this.handleRef}
          className={c.textarea}
          aria-label={emptyMessageError ? i18n.t('request.emptyError') : i18n.t('request.messagePlaceholder')}
          placeholder={emptyMessageError ? i18n.t('request.emptyError') : i18n.t('request.messagePlaceholder')}
        />

        {
          previouslyKnownEnabled
            ? (
              <div className={c.knownWrapper}>
                <KnownSwitch
                  value={!!user.previouslyKnown}
                  label={i18n.t('matches.knowLabelStrong', { name: user.first_name + ' ' + user.last_name })}
                  onClick={() => this.handleKnownChange(user)}
                />
                {
                  (user.roommates || []).map(roommate => (
                    <KnownSwitch
                      key={roommate.membership_id}
                      value={roommate.previouslyKnown}
                      label={i18n.t('matches.knowLabelStrong', { name: roommate.first_name + ' ' + roommate.last_name })}
                      onClick={() => this.handleKnownChange(roommate)}
                    />
                  ))
                }
              </div>
            ) : null
        }

        {disabled
          ? <span className={c.error}>
            {disabled || i18n.t('request.defaultError')}
          </span>
          : <Button className={c.buttonWrapper} onClick={this.onSendRequest}>
            <span className={c.label}>{i18n.t('request.sendRequest')}</span>
          </Button>
        }
      </div>
    )
  }
}
