import { put, call, select, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { ZendeskAPI } from "react-zendesk/src"
import { show } from 'redux-modal'

import { userActions } from '../actions/UserActions'
import userSelectors from '../selectors/UserSelectors'
import {
  HOME,
  PROFILE_OVERVIEW,
  VERIFY_INFO
} from 'constants/urls'
import API from 'services/api'
import { communityActions } from '../actions/CommunityActions'
import { profileTypes, profileActions } from '../actions/ProfileActions'
import contentSelectors from '../selectors/ContentSelectors'
import profileSelectors from '../selectors/ProfileSelectors'

export function* getMajorsContent(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const { res } = yield call(API.getMajorsContent, accessToken, networkId, subnetworkId)

    if (res) {
      yield put(responseSuccess(res))
    } else {
      yield put(push(PROFILE_OVERVIEW))
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getHousingContent(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const networkId = yield select(userSelectors.networkIdSelector)
    const subnetworkId = yield select(userSelectors.subnetworkIdSelector)
    const { res } = yield call(API.getHousingContent, accessToken, networkId, subnetworkId)

    if (res) {
      yield put(responseSuccess(res))
    } else {
      yield put(push(PROFILE_OVERVIEW))
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* getVerifyInfoContent(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const initialValues = yield select(userSelectors.registerVerifyInitialValuesSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { res, code } = yield call(API.getVerifyInfoContent, accessToken, membershipId)

    if (res) {
      const {
        firstName, lastName, email, gender, phoneNumber, phoneCallingCode,
        gender_options: genderOptions, hidden, genderEnabled, bioEnabled
      } = res

      const newRegisterDataSet = {
        firstName: firstName || initialValues.firstName,
        lastName: lastName || initialValues.lastName,
        email: email || initialValues.email,
        gender,
        hidden,
        bioEnabled
      }

      setTimeout(() => {
        ZendeskAPI('messenger', 'prefill', {
          name: {
            value: `${newRegisterDataSet.firstName} ${newRegisterDataSet.lastName}`,
            readOnly: true // optional
          },
          email: {
            value: newRegisterDataSet.email,
            readOnly: true // optional
          },
        })
      }, 2000)

      yield put(profileActions.getProfileOverview())
      yield take(profileTypes.GET_PROFILE_OVERVIEW_SUCCESS)
      const networkName = yield select(profileSelectors.networkNameSelector)

      yield put(userActions.updateRegisterData(newRegisterDataSet))
      yield put(profileActions.updateUserData({ first_name: firstName, last_name: lastName }))
      yield put(responseSuccess({
        ...res,
        networkName,
        genderOptions,
        phoneNumber: phoneCallingCode + phoneNumber,
        preferredFilled: !!(res.preferredFirstName || res.preferredLastName) && res.namesFromImport
      }))

      const isGenderEnabled = yield select(contentSelectors.genderEnabledSelector)
      const networkId = yield select(userSelectors.networkIdSelector)
      if (isGenderEnabled && networkId) {
        yield put(communityActions.getContent())
      }

      yield take(profileTypes.GET_PROFILE_SUCCESS)
      // const aboutMeHasAllAnswers = yield select(profileSelectors.aboutMeHasAllAnswersSelector)
      // const areQuestionsSaved = yield select(profileSelectors.areQuestionsSavedSelector)
      // const Q_SAVED_MOCK = false;
      // const ALL_QUESTIONS_ALL_ANSWERS_MOCK = false;
      if (!gender && firstName && genderEnabled) {
        yield put(push(VERIFY_INFO, { genderUpdateMode: true }))
      }
    } else if (code === 403 || code === 404) {
      const memberships = yield select(userSelectors.membershipsSelector)
      const newMemberships = memberships.filter(membership => membership.membershipId !== membershipId)

      yield put(
        userActions.updateAuthData({
          memberships: newMemberships
        })
      )
      yield put(show(
        'infoModal',
        {
          memberships: newMemberships,
          label: 'Membership cancelled',
          description: (
            <p>
              Membership is no longer accessible and will be switched.{'\n\n'}If you have questions, please contact <a href='email:support@roomsync.com'>support@roomsync.com</a>
            </p>
          )
        }
      ))
    } else {
      yield put(responseFailure())
      yield put(push(HOME))
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}
