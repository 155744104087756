import { createSelector } from 'reselect'
import propOr from 'ramda/es/propOr'
import values from 'ramda/es/values'
import sum from 'ramda/es/sum'
import pipe from 'ramda/es/pipe'
import prop from 'ramda/es/prop'
import last from 'ramda/es/last'
import path from 'ramda/es/path'
import sortBy from 'ramda/es/sortBy'
import __ from 'ramda/es/__'
import roommateSelectors from 'redux/selectors/RoommateSelectors'

const getState = state => state.chat
const isLoadingSelector = state => getState(state).isLoading
const unreadCount = state => getState(state).unreadCount
const twilioTokenSelector = state => getState(state).token
const conversationsIds = state => getState(state).conversationsIds
const conversationsStateSelector = state => getState(state).conversations
const messagesStateSelector = state => getState(state).messages
const chatIdSelector = (_, props) => path(['match', 'params', 'chatId'], props)
const sendingStatesSelector = state => getState(state).sendingStates
const successStatesSelector = state => getState(state).successStates
const initializationErrorSelector = state => getState(state).initializationError
const sidsSelector = state => getState(state).sids
const conversationIdSelector = (state, props) => {
  const chatId = path(['match', 'params', 'chatId'], props)

  return props.conversationId || sidsSelector(state)[chatId || roommateSelectors.roommateUserIdSelector(state, props)]
}

const loadingStates = state => getState(state).loadingStates

const sidSelector = createSelector(
  (_, { chatId }) => chatId,
  sidsSelector,
  prop
)

const conversationSelector = createSelector(
  conversationIdSelector,
  conversationsStateSelector,
  prop
)

const conversationMessagesSelector = createSelector(
  conversationIdSelector,
  messagesStateSelector,
  propOr([])
)

const lastConversationMessageSelector = createSelector(
  conversationMessagesSelector,
  last
)

const isLastMessageDeclinedSelector = createSelector(
  lastConversationMessageSelector,
  message => message?.body?.includes('declined a call 📞')
)

const isLastMessageStartedSelector = createSelector(
  lastConversationMessageSelector,
  message => message?.body?.includes('started a call 📞')
)

const isLastMessageJoinedSelector = createSelector(
  lastConversationMessageSelector,
  message => message?.body?.includes('joined a call 📞')
)

const isLastMessageEndedSelector = createSelector(
  lastConversationMessageSelector,
  message => message?.body?.includes('ended a call 📞')
)

const isChatSendingSelector = createSelector(
  chatIdSelector,
  sendingStatesSelector,
  prop
)

const isChatSuccessSelector = createSelector(
  chatIdSelector,
  successStatesSelector,
  prop
)

const unreadCountSelector = createSelector(
  conversationIdSelector,
  unreadCount,
  prop
)

const isLoadingEarlierSelector = createSelector(
  conversationIdSelector,
  loadingStates,
  prop
)

const totalUnreadCountSelector = createSelector(
  unreadCount,
  pipe(
    values,
    sum
  )
)

const conversationsIdsSelector = createSelector(
  conversationsIds,
  messagesStateSelector,
  (sids, messages) => sortBy(
    pipe(
      propOr([], __, messages),
      last,
      prop('createdAt')
    ), sids)
    .reverse()
)

export default {
  sidSelector,
  conversationsIds: conversationsIdsSelector,
  isLoadingSelector,
  twilioTokenSelector,
  isLastMessageDeclinedSelector,
  isLastMessageStartedSelector,
  isLastMessageJoinedSelector,
  isLastMessageEndedSelector,
  unreadCountSelector,
  conversationSelector,
  isChatSendingSelector,
  isChatSuccessSelector,
  totalUnreadCountSelector,
  isLoadingEarlierSelector,
  initializationErrorSelector,
  conversationMessagesSelector,
  lastConversationMessageSelector
}
