import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'

import { Button } from 'components/Button'

import s from './DialogModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class DialogModalView extends PureComponent {
  onConfirmPress = () => {
    const { handleHide, onConfirm } = this.props
    onConfirm()
    handleHide()
  }

  onSecondPress = () => {
    const { handleHide, onSecond } = this.props
    onSecond()
    handleHide()
  }


  onCancel = () => {
    const { handleHide, onCancel } = this.props

    if (onCancel) onCancel()
    handleHide()
  }

  onDismiss = () => {
    const { handleHide, onDismiss } = this.props

    if (onDismiss) onDismiss()
    handleHide()
  }

  render() {
    const { show, title, confirmOption, cancelOption, secondOption, hideConfirmButton } = this.props

    return (
      <Modal
        center
        open={show}
        focusTrapped
        classNames={classNames}
        onClose={this.onDismiss}
      >
        <div className={s.container} tabIndex={0}>
          <h2 className={s.title}>{title}</h2>
          {!hideConfirmButton && <Button className={s.optionLabel} onClick={this.onConfirmPress}>{confirmOption}</Button>}
          {secondOption ? <Button className={s.optionLabel} onClick={this.onSecondPress}>{secondOption}</Button> : null}
          <Button className={s.optionLabel} onClick={this.onCancel}>{cancelOption}</Button>
        </div>
      </Modal>
    )
  }
}

DialogModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

const DialogModal = connectModal({ name: 'dialogModal' })(DialogModalView)

export { DialogModal }
