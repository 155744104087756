import { createSelector } from 'reselect'
import path from 'ramda/es/path'
import propOr from 'ramda/es/propOr'
import pick from 'ramda/es/pick'
import pipe from 'ramda/es/pipe'
import keys from 'ramda/es/keys'
import defaultTo from 'ramda/es/defaultTo'
import split from 'ramda/es/split'
import reject from 'ramda/es/reject'
import nthArg from 'ramda/es/nthArg'
import identical from 'ramda/es/identical'
import includes from 'ramda/es/includes'
import pickBy from 'ramda/es/pickBy'
import uniqBy from 'ramda/es/uniqBy'
import reduce from 'ramda/es/reduce'
import prop from 'ramda/es/prop'
import values from 'ramda/es/values'

const getState = state => state.roommate

const loadingsSelector = state => getState(state).loadings
const reqquestLoadingsSelector = state => getState(state).requestLoadings
const roommatesSelector = state => getState(state).roommates
const errorsSelector = state => getState(state).requestErrors
const roommateIdSelector = (_, props) => path(['match', 'params', 'roommateId'], props)
const roommatesIdSelector = (state, props) => {
  const userId = path(['user', 'auth', 'userId'], state)
  const roommateMembershipId = path(['match', 'params', 'roommateId'], props)
  const roommate = roommatesSelector(state)[roommateMembershipId]
  const roommateChatId = roommate?.userId || roommate?.user_id

  return pipe(
    args => path(['match', 'params', 'chatId'], args) || roommateChatId || '',
    split('|'),
    defaultTo([]),
    reject(identical(userId))
  )(props)
}
const roommateIdSagaSelector = (state) => {
  const pathname = path(['router', 'location', 'pathname'], state)
  const roommateMembershipId = pathname.split('/')[2]
  const roommate = roommatesSelector(state)[roommateMembershipId]
  const roommateChatId = roommate?.userId || roommate?.user_id

  return roommateChatId
  // const pathname = path(['router', 'location', 'pathname'], state)
  // try {
  //   return pathname.split('/')[2]
  // } catch (e) {
  //   return null
  // }
}

const isLoadingSelector = createSelector(
  loadingsSelector,
  roommateIdSelector,
  (loadings, roommateId) => loadings[roommateId]
)

const isRequestLoadingSelector = createSelector(
  reqquestLoadingsSelector,
  roommateIdSelector,
  (loadings, roommateId) => loadings[roommateId]
)

const roommateSelector = createSelector(
  roommatesSelector,
  roommateIdSelector,
  (roommates, roommateId) => roommates[roommateId] || {}
)

const roommatesDataSelector = createSelector(
  roommatesIdSelector,
  roommatesSelector,
  (roommatesId, roommates) =>
    pipe(
      pickBy(({ user_id, userId }) => includes(user_id || userId, roommatesId)),
      values,
      uniqBy(item => prop('user_id', item) || prop('userId', item)),
      reduce((pv, cv) => ({
        ...pv,
        [cv.membership_id || cv.membershipId || cv.roommateId]: cv,
      }), {})
    )(roommates)
)

const roommateSagaSelector = createSelector(
  roommatesSelector,
  roommateIdSagaSelector,
  (roommates, roommateId) => roommates[roommateId]
)

const requestErrorSelector = createSelector(
  errorsSelector,
  roommateIdSelector,
  (errors, roommateId) => errors[roommateId]
)

const chatRoommatesSelector = createSelector(
  (_, props) => propOr([], 'memberships', props),
  roommatesSelector,
  pick
)

const createMessageMembershipIdsSelector = createSelector(
  nthArg(1),
  roommatesSelector,
  (roommatesId, roommates) =>
    keys(pickBy(({ user_id, userId }) => includes(user_id || userId, roommatesId), roommates))
)

const genderSelector = createSelector(
  roommateSelector,
  path(['genderSelectionTransport', 'name'])
)

const roommateNameSelector = createSelector(
  roommateSagaSelector,
  roommate => roommate ? (roommate.first_name + " " + roommate.last_name) : null
)

const userSelector = createSelector(
  roommatesSelector,
  nthArg(1),
  (roommates, roommateId) => roommates[roommateId] || {}
)

const userModalSelector = createSelector(
  roommatesSelector,
  pipe(
    nthArg(1),
    prop('membershipId')
  ),
  (roommates, roommateId) => roommates[roommateId] || {}
)

const roommateFullNameSelector = createSelector(
  userSelector,
  ({ first_name, last_name }) => first_name + " " + last_name
)

const usersByUserIdSelector = createSelector(
  (_, props) => propOr([], 'memberships', props),
  roommatesSelector,
  (roommatesId, roommates) =>
    pipe(
      pickBy(({ userId, user_id }) => includes(userId || user_id, roommatesId)),
      values,
      uniqBy(item => prop('user_id', item) || prop('userId', item)),
      reduce((pv, cv) => ({
        ...pv,
        [cv.membership_id || cv.membershipId]: cv
      }), {})
    )(roommates)
)

const roommateUserIdSelector = createSelector(
  roommateSelector,
  user => prop('user_id', user) || prop('userId', user)
)

export default {
  userModalSelector,
  genderSelector,
  roommateSelector,
  roommatesIdSelector,
  isLoadingSelector,
  roommateIdSelector,
  roommateNameSelector,
  roommateFullNameSelector,
  roommateSagaSelector,
  roommatesDataSelector,
  roommateUserIdSelector,
  requestErrorSelector,
  chatRoommatesSelector,
  usersByUserIdSelector,
  roommateIdSagaSelector,
  isRequestLoadingSelector,
  createMessageMembershipIdsSelector
}
