import { useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import MenuContainer from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog'
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog'
import SettingsIcon from '../../../../icons/SettingsIcon'
import { useAppState } from '../../../../state'

const useStyles = makeStyles({
  settingsButton: {
    flex: 1,
    alignSelf: 'stretch',
    border: '2px solid #aaa',
    margin: '0 0.5em'
  }
})

export default function SettingsMenu() {
  const classes = useStyles()
  const { roomType } = useAppState()
  const [menuOpen, setMenuOpen] = useState(false)
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false)
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false)

  const anchorRef = useRef(null)

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setMenuOpen(true)}
        startIcon={<SettingsIcon />}
        className={classes.settingsButton}
      >
        Settings
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => setDeviceSettingsOpen(true)}>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem>
        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem onClick={() => setConnectionSettingsOpen(true)}>
            <Typography variant="body1">Connection Settings</Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false)
          setMenuOpen(false)
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false)
          setMenuOpen(false)
        }}
      />
    </>
  )
}
