import { createReducer } from 'services/reduxHelpers'
import { profileTypes } from '../actions/ProfileActions'

/* ------------- Initial State ------------- */

export const initialState = {
  housing: [],
  social_accounts: [],
  majors: [],
  overview: {
    sections: [],
    title: '',
    unitType: null,
    roomType: null,
    networkName: null,
    subnetworkName: null,
    l3ForcedClosed: null,
  },
  aboutMe: {
    questions: [],
    title: ''
  },
  loadingStates: {
    majors: false,
    housing: false,
    profile: false,
    aboutMe: false,
    overview: true,
    socialAccounts: false,
    uploadProfile: false
  }
}

/* ------------- Hookup Reducers To Types ------------- */
const aboutMeAttempt = ({ loadingStates, lifestyleQuestionsWithAnswers }) => ({
  loadingStates: {
    ...loadingStates,
    aboutMe: !lifestyleQuestionsWithAnswers
  }
})

const aboutMeRequestAttempt = ({ loadingStates }) => ({
  loadingStates: {
    ...loadingStates,
    aboutMe: true
  }
})

const aboutMeSuccess = ({ loadingStates, lifestyleQuestionsWithAnswers }, { data }) => ({
  loadingStates: {
    ...loadingStates,
    aboutMe: false
  },
  lifestyleQuestionsWithAnswers: {
    ...lifestyleQuestionsWithAnswers,
    ...data
  }
})

const aboutMeFailure = ({ loadingStates }) => ({
  loadingStates: {
    ...loadingStates,
    aboutMe: false
  }
})

export default createReducer(initialState, {
  [profileTypes.GET_PROFILE_ATTEMPT]: ({ loadingStates, title }) => ({
    loadingStates: {
      ...loadingStates,
      profile: !title
    }
  }),
  [profileTypes.GET_PROFILE_SUCCESS]:
    ({ loadingStates, social_accounts: socialAccounts },
      { data }
    ) => ({
      ...data,
      social_accounts: (socialAccounts && socialAccounts.length) ? socialAccounts : (data.social_accounts || []),
      loadingStates: {
        ...loadingStates,
        profile: false
      }
    }),
  [profileTypes.GET_PROFILE_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      profile: false
    }
  }),

  [profileTypes.GET_MAJORS_OPTIONS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      majors: false
    },
    ...data
  }),

  [profileTypes.SET_MAJORS_OPTIONS_ATTEMPT]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      majors: true
    }
  }),
  [profileTypes.SET_MAJORS_OPTIONS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      majors: false
    },
    ...data
  }),
  [profileTypes.SET_MAJORS_OPTIONS_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      majors: false
    }
  }),

  [profileTypes.GET_SOCIAL_ACCOUNTS_ATTEMPT]: ({ loadingStates, socialAccountsContent }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: !socialAccountsContent
    }
  }),
  [profileTypes.GET_SOCIAL_ACCOUNTS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: false
    },
    ...data
  }),
  [profileTypes.GET_SOCIAL_ACCOUNTS_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: false
    }
  }),

  [profileTypes.SET_SOCIAL_ACCOUNTS_ATTEMPT]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: true
    }
  }),
  [profileTypes.SET_SOCIAL_ACCOUNTS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: false
    },
    ...data
  }),
  [profileTypes.SET_SOCIAL_ACCOUNTS_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      socialAccounts: false
    }
  }),

  [profileTypes.GET_HOUSING_OPTIONS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      housing: false
    },
    ...data
  }),

  [profileTypes.SET_HOUSING_OPTIONS_ATTEMPT]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      housing: true
    }
  }),
  [profileTypes.SET_HOUSING_OPTIONS_SUCCESS]: ({ loadingStates }, { data }) => ({
    loadingStates: {
      ...loadingStates,
      housing: false
    },
    ...data
  }),
  [profileTypes.SET_HOUSING_OPTIONS_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      housing: false
    }
  }),

  [profileTypes.GET_PROFILE_OVERVIEW_ATTEMPT]: ({ overview, loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      overview: !overview.sections.length
    }
  }),
  [profileTypes.GET_PROFILE_OVERVIEW_SUCCESS]: ({ overview, loadingStates }, { data = {} }) => ({
    loadingStates: {
      ...loadingStates,
      overview: false,
      profile: false
    },
    overview: {
      ...overview,
      ...data
    }
  }),
  [profileTypes.GET_PROFILE_OVERVIEW_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      overview: false
    }
  }),
  [profileTypes.CHANGE_OVERVIEW_STATUS_ATTEMPT]: ({ overview }, { data }) => ({
    overview: {
      ...overview,
      sections: overview.sections.map(item => ({
        ...item,
        status: item.url.includes(data) ? 'Complete' : item.status
      }))
    }
  }),
  [profileTypes.UPDATE_USER_DATA_ATTEMPT]: (_, { data }) => ({
    ...data
  }),

  [profileTypes.GET_ABOUT_ME_ATTEMPT]: aboutMeAttempt,
  [profileTypes.GET_ABOUT_ME_SUCCESS]: aboutMeSuccess,
  [profileTypes.GET_ABOUT_ME_FAILURE]: aboutMeFailure,

  [profileTypes.SET_ABOUT_ME_ATTEMPT]: aboutMeRequestAttempt,
  [profileTypes.SET_ABOUT_ME_SUCCESS]: aboutMeSuccess,
  [profileTypes.SET_ABOUT_ME_FAILURE]: aboutMeFailure,

  [profileTypes.UPLOAD_PROFILE_IMAGE_ATTEMPT]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      uploadProfile: true
    }
  }),
  [profileTypes.UPLOAD_PROFILE_IMAGE_SUCCESS]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      uploadProfile: false
    }
  }),
  [profileTypes.UPLOAD_PROFILE_IMAGE_FAILURE]: ({ loadingStates }) => ({
    loadingStates: {
      ...loadingStates,
      uploadProfile: false
    }
  })
})
