import { useEffect } from 'react'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { show } from 'redux-modal'

import { chatActions } from 'redux/actions/ChatActions'
import chatSelectors from 'redux/selectors/ChatSelectors'
import profileSelectors from 'redux/selectors/ProfileSelectors'
import { Button } from 'components/Button'
import i18n from 'services/i18n'
import { CONVERSATION } from 'constants/urls'
import { usePrevious } from 'hooks/usePrevious'

import s from './VideoCallPromptModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

const VideoCallPromptModalView = ({
  handleHide, pushTo, showModal, roommateId, show, title,
  isLastMessageEnded, createMessage, myName, 
}) => {
  const prevIsLastMessageEnded = usePrevious(isLastMessageEnded)

  const onJoin = () => {
    document.title = 'RoomSync'
    handleHide()
    pushTo(CONVERSATION.replace(':chatId', roommateId))
    setTimeout(() => showModal('videoRoomModal'), 300)
  }

  const onDismiss = () => {
    handleHide()
  }

  const onCancel = () => {
    createMessage({
      resetChatOnSuccess: true,
      chatId: roommateId,
      roommatesId: [roommateId],
      message: `${myName} declined a call 📞`
    })
    handleHide()
  }

  useEffect(() => {
    if (show && isLastMessageEnded && prevIsLastMessageEnded === false) {
      handleHide()
    }
  }, [isLastMessageEnded])

  return (
    <Modal
      center
      open={show}
      focusTrapped
      closeOnOverlayClick={false}
      classNames={classNames}
      onClose={onDismiss}
    >
      <div className={s.container} tabIndex={0}>
        <h2 className={s.title}>{title}</h2>
        <h2 className={s.title}>{i18n.t('chat.wantJoin')}</h2>
        <Button
          className={s.optionLabel}
          onClick={onJoin}
        >
          {i18n.t('chat.join')}
        </Button>
        <Button
          className={s.optionLabel}
          onClick={onCancel}
        >
          {i18n.t('chat.cancel')}
        </Button>
      </div>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  myName: profileSelectors.name,
  isLastMessageEnded: chatSelectors.isLastMessageEndedSelector,
})

const mapDispatchToProps = {
  pushTo: push,
  showModal: show,
  createMessage: chatActions.createMessage
}

const Connected = connect(mapStateToProps, mapDispatchToProps)(VideoCallPromptModalView)

const VideoCallPromptModal = connectModal({ name: 'videoCallPromptModal' })(Connected)

export { VideoCallPromptModal }
