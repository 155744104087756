import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { roomTypes, roomActions } = createActionsStructure('room', [
  { name: 'GET_ROOM_CONTENT', async: true },
  { name: 'LEAVE_ROOM', async: true },
  { name: 'SET_ROOMMATE', async: true },
  { name: 'SET_KNOWN' },
  { name: 'APPEND_OUTCOMING_REQUEST' }
])
