import { useEffect, useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './MenuItem.module.scss'

const getButtonRole = (type) => type === "email"
  ? "button"
  : "link"

const MenuItem = ({ index, type, Icon, size, selected, onClick, badge, sideMenu }) => {
  const [key, setKey] = useState(null)

  useEffect(() => {
    if (selected) {
      setKey(Math.random())
    }
  }, [selected])

  const unread = badge <= 0 || !badge
    ? ""
    : badge === 1
      ? i18n.t('more.notification', { badge })
      : i18n.t('more.notifications', { badge })

  /**
  * Several reasons why we used aria-label to indicate current page:
  * 1. Some screen readers don't recognize aria-current attr
  * 2. We use button instead of Link component because of several reasons, and its refactor would be very time consuming
  */
   const labels = {
    swipe: i18n.t('main.find'),
    group: i18n.t('main.community'),
    chats: i18n.t('main.chat'),
    home: i18n.t('main.matches'),
    welcome: i18n.t('main.welcome'),
    arrow: i18n.t('more.logout'),
    dots: i18n.t('more.title'),
    email: i18n.t('more.contact'),
    person: i18n.t('more.editProfile'),
    settings: i18n.t('more.update'),
    membership: i18n.t('more.switch'),
    mobile: i18n.t('more.mobileApp'),
    earth: i18n.t('more.language'),
    delete: i18n.t('more.delete'),
  }
  const label = selected
    ? `${labels[type]} - selected. ${unread}`
    : `${labels[type]}. ${unread}`

  return (
    <li className={cn(s.wrapper, sideMenu && s.sideMenu)}>
      {index === 0 && <div className={cn(s.divider, size === 'small' && s.small, sideMenu && s.sideMenuDivider)} />}
      <Button
        role={getButtonRole(type)}
        onClick={() => onClick(index)}
        aria-haspopup={String(type === "email")}
        className={cn(
          s.container,
          size === 'small' && s.small,
          selected && s.selected,
          sideMenu && s.sideMenu
        )}
        aria-label={label.trim()}
      >
        <img
          alt=""
          src={Icon}
          key={key}
          tabIndex={-1}
          aria-hidden="true"
          className={cn(s.image, key && s.animate, type === 'email' && s.email)}
        />
        <span className={cn(s.label, size === 'small' && s.small)}>{labels[type]}</span>
        {
          badge > 0 &&
          <span className={s.unread}>
            {badge}
          </span>
        }
      </Button>
      <div className={cn(s.divider, size === 'small' && s.small, sideMenu && s.sideMenuDivider)} />
    </li>
  )
}

MenuItem.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  unreadMessagesCount: PropTypes.number
}

MenuItem.defaultProps = {
  size: 'large',
  selected: false,
  unreadMessagesCount: 0
}

export { MenuItem }
