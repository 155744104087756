
export const translate3d = (x, y, i) => {
  const translate = `translate3d(${x}px, ${y}px, ${-i * i || 0}px)`
  const opacity = ((250 - Math.abs(x)) / 100)

  const rotate = `rotate(${x / 33}deg)`
  const perspective = 'perspective(38px)'

  const opacityLike = x
  const opacitySkip = -x

  const style = {
    msTransform: [perspective, translate, rotate].join(' '),
    WebkitTransform: [perspective, translate, rotate].join(' '),
    transform: [perspective, translate, rotate].join(' '),
    opacity
  }
  const likeStyle = {
    display: x > 0 ? 'flex' : 'none',
    opacity: opacityLike / 100
  }
  const skipStyle = {
    display: x < 0 ? 'flex' : 'none',
    opacity: opacitySkip / 100
  }

  return [style, likeStyle, skipStyle]
}

export const DIRECTIONS = [ 'Right', 'Left', 'Top', 'Bottom' ]
