import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form'
import toUpper from 'ramda/es/toUpper'
import pathOr from 'ramda/es/pathOr'
import path from 'ramda/es/path'
import prop from 'ramda/es/prop'

const getState = state => state.content
const networkContentSelector = state => getState(state).findYourNetwork
const subnetworkContentSelector = state => getState(state).subNetwork
const unitTypeContentSelector = state => getState(state).unitType
const roomTypeContentSelector = state => getState(state).roomType
const majorsContentSelector = state => getState(state).majorType
const housingContentSelector = state => getState(state).housing
const verifyInfoContentSelector = state => getState(state).verifyInfo

const getTransformedGendersSelector = createSelector(
  verifyInfoContentSelector,
  ({ genderOptions }) => {
    if (genderOptions) {
      return Object.keys(genderOptions).map(key => ({ value: genderOptions[key], name: key.replace(/^./, toUpper) }))
    } else {
      return []
    }
  }
)

const genderSelector = createSelector(
  verifyInfoContentSelector,
  path(['gender', 'name'])
)

const feedbackQuestionSelector = createSelector(
  verifyInfoContentSelector,
  content => pathOr(null, ['questions', 0], content)
)

const genderEnabledSelector = createSelector(
  verifyInfoContentSelector,
  pathOr(true, ["genderEnabled"])
)

const personalPronounSelector = createSelector(
  verifyInfoContentSelector,
  prop('personalPronoun')
)

const preferredFilledSelector = state => {
  const value = prop('preferredFilled', getFormValues('verifyInfo')(state) || {})

  return value === 'true' || value === true
}

export default {
  genderSelector,
  personalPronounSelector,
  genderEnabledSelector,
  majorsContentSelector,
  networkContentSelector,
  housingContentSelector,
  unitTypeContentSelector,
  roomTypeContentSelector,
  feedbackQuestionSelector,
  subnetworkContentSelector,
  verifyInfoContentSelector,
  preferredFilledSelector,
  getTransformedGendersSelector
}
