import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import Lottie from 'react-lottie'
import { withRouter } from 'react-router-dom'

import { Button } from 'components/Button'
import { ROOM_OUTGOING_REQUETS } from 'constants/urls'
import i18n from 'services/i18n'

import confetti from './confetti.json'
import s from './RequestSentModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class RequestSentModalView extends PureComponent {
  handleView = () => {
    const { handleHide, history } = this.props

    handleHide()
    history.push(ROOM_OUTGOING_REQUETS)
  }

  render() {
    const { handleHide, show } = this.props

    return (
      <Modal
        center
        focusTrapped
        open={show}
        onClose={handleHide}
        classNames={classNames}
      >
        <div className={s.container}>
          <div className={s.animation}>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: confetti
              }}
              isPaused={false}
              isStopped={false}
            />
          </div>
          <h1 className={s.title}>{i18n.t('request.sent')}</h1>
          <div className={s.labels}>
            <Button
              isRounded
              variant='contained'
              className={s.blue}
              onClick={this.handleView}
            >
              {i18n.t('request.view')}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

RequestSentModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

RequestSentModalView.defaultProps = {
  title: 'Are you sure you want to logout?',
  confirmOption: 'Yes',
  cancelOption: 'No'
}

const RequestSentModal = connectModal({ name: 'requestSentModal' })(withRouter(RequestSentModalView))

export { RequestSentModal }
