import cn from "classnames";
import React, { FC } from "react";

import { NavLink } from "react-router-dom";

import { IProps } from "./types";

import s from './RouterLink.module.scss'

export const RouterLink: FC<IProps> = props => (
  <NavLink
    exact
    {...props}
    className={cn(s.link, props.className)}
  >
    {props.children}
  </NavLink>
);
