import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'

import { PROFILE_OVERVIEW } from 'constants/urls'
import { profilePageTitleSelector } from 'redux/selectors/ProfileSelectors'

export const usePageTitle = () => {
  // For demo purposes we set title inly for profile page page
  const isProfileOverviewPage = useRouteMatch(PROFILE_OVERVIEW)
  const profilePageTitle = useSelector(profilePageTitleSelector)

  return isProfileOverviewPage?.isExact && profilePageTitle
}
