import { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import i18n from 'services/i18n'

import s from './Switch.module.scss'

class Switch extends PureComponent {
  state = {
    selected: this.props.input.value || this.props.defaultValue,
    anim: false
  }

  static getDerivedStateFromProps (props, prevState) {
    if (props.input.value !== prevState.selected) return { selected: props.input.value, anim: true }

    return prevState
  }

  onChange = () => {
    const { input: { onChange } } = this.props
    const { selected } = this.state
    this.setState({ selected: !selected, anim: true })
    onChange && onChange(!selected)
  }

  render () {
    const { selected, anim } = this.state
    const { label, input: { disabled }, borderBottom, labelOn, labelOff, className } = this.props
    const props = disabled ? {} : { onClick: this.onChange }

    return (
      <div className={cn(s.container, borderBottom && s.borderBottom, disabled && s.disabled, className)} {...props}>
        <span className={s.label}>{label}</span>
        <div className={s.switchWrapper}>
          <div className={cn(
            s.oval,
            anim && s.withAnim,
            selected ? s.selected : s.unselected
          )}>
            {selected ? labelOn : labelOff}
          </div>
        </div>
      </div>
    )
  }
}

Switch.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func
  }).isRequired,
  label: PropTypes.string.isRequired
}

Switch.defaultProps = {
  borderBottom: true,
  labelOn: i18n.t('verify.yes'),
  labelOff: i18n.t('verify.no')
}

export { Switch }
