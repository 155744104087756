import React from 'react';
import cn from 'classnames';

import s from './Button.module.scss'
import { ActivityIndicator } from 'components/ActivityIndicator';

interface IProps {
  onClick: VoidFunction
  className?: string
  "aria-label"?: string
  variant?: "contained" | "text" | "outlined"
  isLoading?: boolean
  isRounded?: boolean
  isDisabled?: boolean
  isPreview?: boolean
}

export const Button: React.FC<IProps> = ({ isLoading, isRounded, isDisabled, isPreview, ...props }) => (
  <button
    {...props}
    className={cn(
      s.container,
      props.variant && s[props.variant],
      isRounded && s.rounded,
      isPreview && s.preview,
      props.className
    )}
  >
    {isLoading && (
      <ActivityIndicator blue={props.variant === "contained"} />
    )}
    {!isLoading && (
      props.children
    )}
  </button>
);

Button.defaultProps = {
  variant: "text",
  isLoading: false,
  isRounded: false,
  isDisabled: false
}