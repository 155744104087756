import PropTypes from 'prop-types'
import cn from 'classnames'

import SearchIcon from 'static/images/search.svg'
import i18n from 'services/i18n'

import s from './SearchInput.module.scss'

export const SearchInput = (props) => (
  <div className={cn(s.inputGroup, props.small && s.small)}>
    {!props.small &&
      <span className={s.icon}>
        <img
          alt=""
          src={SearchIcon}
          aria-hidden="true"
        />
      </span>
    }
    <input
      type='text'
      aria-label={i18n.t('search.title')}
      onChange={props.onChange}
      className={s.searchInput}
      placeholder={props.placeholder}
    />
  </div>
)

SearchInput.propTypes = {
  onChange: PropTypes.func,
  small: PropTypes.bool,
  placeholder: PropTypes.string
}

SearchInput.defaultProps = {
  onChange: () => { },
  small: false,
  placeholder: i18n.t('majors.searchPlaceholder')
}
