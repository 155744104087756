import React from "react";
import MUITooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import { useStyles } from "./styles";

/**
 * We need this custom wrapper that hover would work for our custom components
 * 
 * Check more info https://material-ui.com/components/tooltips/#custom-child-element
 */
const ChildrenWrapper: React.FC<any> = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} />
));

const Tooltip: React.FC<TooltipProps> = props => {
  const classes = useStyles();

  return (
    <MUITooltip
      {...props}
      arrow
      title={props.title}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <ChildrenWrapper className={classes.wrapper}>
        {props.children}
      </ChildrenWrapper>
    </MUITooltip>
  );
};

export {
  Tooltip,
};
