import { createSelector } from 'reselect'
import { GROUPED_LOGINED_ROUTES, GROUPED_CLOSED_ROUTES, registrationPathnames, closedPathnames } from 'constants/arrays'
import { FILTERS_MAJORS, FILTERS_HOUSING } from 'constants/urls'

const getState = state => state.router
const currentPathnameSelector = state => getState(state).location.pathname
const currentStateSelector = state => getState(state)?.location?.state

const compareExistingRoute = state => {
  const route = currentPathnameSelector(state)

  return GROUPED_LOGINED_ROUTES.findIndex(group => group.some(routeConst => route.includes(routeConst)))
}

const getRouteIndex = createSelector(
  compareExistingRoute,
  (index) => index
)

const compareExistingClosedRoute = state => {
  const route = currentPathnameSelector(state)

  return GROUPED_CLOSED_ROUTES.findIndex(group => group.some(routeConst => route.includes(routeConst)))
}

const getRouteIndexClosed = createSelector(
  compareExistingClosedRoute,
  (index) => index
)

const isRegistrationFlow = createSelector(
  currentPathnameSelector,
  (pathname) => registrationPathnames.some(route => pathname.includes(route))
)

const isSearchFlow = createSelector(
  currentPathnameSelector,
  (pathname) => pathname.includes(FILTERS_MAJORS) || pathname.includes(FILTERS_HOUSING)
)

const isClosedFlowSelector = createSelector(
  currentPathnameSelector,
  (pathname) => closedPathnames.some(route => pathname.includes(route))
)

export default {
  isClosedFlowSelector,
  isSearchFlow,
  getRouteIndex,
  getRouteIndexClosed,
  isRegistrationFlow,
  currentStateSelector,
  currentPathnameSelector
}
