import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'

import i18n from 'services/i18n'
import s from './SocialUseModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class SocialUseModalView extends PureComponent {
  render() {
    const { handleHide, show, label } = this.props

    return (
      <Modal center open={show} onClose={handleHide} classNames={classNames}>
        <div className={s.container}>
          <span className={s.title}>{label}</span>
          <p>
            {i18n.t('home.howInfo1')}
          </p>
          <p>
            {i18n.t('home.howInfo2NoEmail')}
            <a href='mailto:support@roomsync.com'> support@roomsync.com</a>.
          </p>
          <span className={s.optionLabel} onClick={handleHide}>Ok</span>
        </div>
      </Modal>
    )
  }
}

SocialUseModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

SocialUseModalView.defaultProps = {
  label: ''
}

const SocialUseModal = connectModal({ name: 'socialUseModal' })(SocialUseModalView)

export { SocialUseModal }
