import { put, call, select, all } from 'redux-saga/effects'
import { goBack } from 'connected-react-router'
import { show } from 'redux-modal'
import path from 'ramda/es/path'
import pathOr from 'ramda/es/pathOr'
import { ToastsStore } from 'react-toasts'
import { replace } from 'connected-react-router'

import { ROOMMATE_PROFILE } from 'constants/urls'
import { roomActions } from '../actions/RoomActions'
import { swipeActions } from '../actions/SwipeActions'
import { chatActions } from 'redux/actions/ChatActions'
import { profileActions } from '../actions/ProfileActions'
import userSelectors from 'redux/selectors/UserSelectors'
import roommateSelectors from 'redux/selectors/RoommateSelectors'
import profileSelectors from 'redux/selectors/ProfileSelectors'
import API from 'services/api'

import s from '../../layouts/SignedInLayout/Toast.module.scss'
import { handleToastFocus } from 'helpers/handleToastFocus'

export function* getRoommateProfile(action) {
  const { responseSuccess, responseFailure, data: { roommateId, conversationId } } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const sections = yield select(profileSelectors.overviewSectionsSelector)

    const responses = yield all([
      call(API.getProfile, accessToken, membershipId, roommateId),
      !conversationId && call(API.getProfileOverview, accessToken, membershipId, roommateId),
      !sections && put(profileActions.getProfileOverview())
    ])

    if (responses[0].res) {
      yield put(responseSuccess({
        ...pathOr(null, [0, 'res'], responses),
        ...pathOr(null, [1, 'res'], responses),
        roommateId,
        conversationId
      }))
    } else {
      yield put(responseFailure({ roommateId }))
      yield put(goBack())
    }
  } catch (err) {
    yield put(responseFailure({ roommateId }))
  }
}

export function* getRoommatesProfile(action) {
  const { responseSuccess, data: { roommates, membershipIds = [] } } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const response = yield call(API.getProfiles, accessToken, membershipId, roommates, membershipIds)

    if (response.res) {
      yield put(responseSuccess(response.res))
    }
  } catch (err) {
  }
}

export function* denyRequest(action) {
  const { responseSuccess, responseFailure, data: { roommateId } } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { res } = yield call(API.denyRoommateRequest, accessToken, membershipId, roommateId)

    if (res) {
      yield put(responseSuccess({
        ...res,
        status: 'not_in_room',
        roommateId
      }))
      ToastsStore.success('Request denied!', 3000, s.toast)
      handleToastFocus()
    } else {
      yield put(responseFailure({ roommateId }))
    }
  } catch (err) {
    yield put(responseFailure({ roommateId }))
  }
}

export function* acceptRequest(action) {
  const { responseSuccess, responseFailure, data: { roommateId } } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const { res, err } = yield call(API.acceptRoommateRequest, accessToken, membershipId, roommateId)
    const validationMessage = path(['validation', '0', 'error_text'], err)

    if (res) {
      yield put(responseSuccess({
        status: 'in_room',
        roommateId
      }))

      const roommate = yield select(roommateSelectors.roommateSelector, { match: { params: { roommateId } } })
      const myAvatar = yield select(profileSelectors.myAvatarSelector)

      yield put(roomActions.getRoomContent())
      yield put(show('roommateSuccessModal', {
        myAvatar,
        membershipId: roommateId,
        userAvatar: path(['profile_picture_urls', 'large'], roommate),
        description: res.confirmed_match_text
      }))
    } else if (validationMessage) {
      yield put(show('infoModal', { label: validationMessage }))

      yield put(responseFailure({ roommateId }))
    } else {

    }
  } catch (err) {
    yield put(responseFailure({ roommateId }))
  }
}

export function* sendRequest(action) {
  const { responseSuccess, responseFailure, data: { roommateId, message } } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)

    const { res, err } = yield call(API.sendRoommateRequest, accessToken, membershipId, roommateId, message)

    if (res) {
      yield put(responseSuccess({
        status: 'outgoing_request',
        roommateId
      }))
      yield put(swipeActions.removeCard({ roommateId }))

      const roommate = yield select(roommateSelectors.roommateSelector, { match: { params: { roommateId } } })
      const outgoingRequestObject = {
        membership_id: roommateId,
        image_urls: roommate.profile_picture_urls,
        request_date: new Date().getTime(),
        first_name: roommate.first_name,
        last_name: roommate.last_name
      }

      yield put(roomActions.appendOutcomingRequest(outgoingRequestObject))
      yield put(replace(ROOMMATE_PROFILE.replace(':roommateId', roommateId)))
      yield put(show('requestSentModal'))
      yield put(
        chatActions.createMessage({
          message,
          resetChatOnSuccess: true,
          chatId: roommate.userId || roommate.user_id,
          roommatesId: [roommate.userId || roommate.user_id]
        })
      )
    } else {
      yield put(responseFailure({
        roommateId,
        error: path(['validation', '0', 'error_text'], err)
      }))
    }
  } catch (err) {
    yield put(responseFailure({ roommateId }))
  }
}

export function* cancelRequest(action) {
  const { responseSuccess, responseFailure, data: { roommateId } } = action
  try {
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const membershipId = yield select(userSelectors.membershipIdSelector)

    const { res } = yield call(API.cancelRoommateRequest, accessToken, membershipId, roommateId)

    if (res) {
      yield put(responseSuccess({
        ...res,
        status: 'not_in_room',
        roommateId
      }))
      ToastsStore.success('Request canceled!', 3000, s.toast)
      handleToastFocus()
    } else {
      yield put(responseFailure({ roommateId }))
    }
  } catch (err) {
    yield put(responseFailure({ roommateId }))
  }
}
