import React from 'react';
import cn from 'classnames';

import { Tooltip } from 'components/Tooltip';
import InfoIcon from 'static/images/infoIcon.svg'

import s from './TooltipWithIcon.module.scss'

interface IProps {
  id: string
  text: string
  icon?: string
  size?: "large"
}

export const TooltipWithIcon: React.FC<IProps> = props => (
  <Tooltip title={props.text} id={props.id}>
    <div
      tabIndex={0}
      className={s.wrapper}
      aria-label={props.text}
    >
      <img
        src={props.icon}
        aria-hidden="true"
        alt="Tooltip icon"
        className={cn(props.size === "large" && s.svgLarge)}
      />
    </div>
  </Tooltip>
);

TooltipWithIcon.defaultProps = {
  icon: InfoIcon
}
