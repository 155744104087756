import { PureComponent } from 'react'
import identity from 'ramda/es/identity'
import i18n from 'services/i18n'

import s from './CommunityProfileInfo.module.scss'

export default class CommunityProfileInfo extends PureComponent {
  getAriaCommunityInfoLabel = () => {
    const {
      network_name,
      subnetwork_name,
      unit_type,
      room_type
    } = this.props

    return [
      network_name && `${i18n.t('profile.network')}: ${network_name}`,
      subnetwork_name && `${i18n.t('profile.subnetwork')}: ${subnetwork_name}`,
      unit_type && `${i18n.t('profile.unit')}: ${unit_type}`,
      room_type && `${i18n.t('profile.room')}: ${room_type}`
    ]
      .filter(identity)
      .join(' - ')
  }

  render() {
    const {
      isOverview,
      networkName,
      subnetworkName,
      unitType,
      roomType
    } = this.props

    const hideAtAll = !isOverview && !roomType

    if (hideAtAll) {
      return null
    }

    return (
      <div className={s.container} aria-label={this.getAriaCommunityInfoLabel()}>
        {
          networkName && isOverview
            ? (
              <div className={s.section}>
                <span className={s.title}>{i18n.t('profile.network')}</span>
                <span className={s.label}>{networkName}</span>
              </div>
            ) : null
        }
        {
          subnetworkName && isOverview
            ? (
              <div className={s.section}>
                <span className={s.title}>{i18n.t('profile.subnetwork')}</span>
                <span className={s.label}>{subnetworkName}</span>
              </div>
            )
            : null
        }
        {
          unitType && (isOverview || !isOverview && roomType)
            ? (
              <div className={s.section}>
                <span className={s.title}>{i18n.t('profile.unit')}</span>
                <span className={s.label}>{unitType}</span>
              </div>
            )
            : null
        }
        {
          roomType && (isOverview || !isOverview && roomType)
            ? (
              <div className={s.section}>
                <span className={s.title}>{i18n.t('profile.room')}</span>
                <span className={s.label}>{roomType}</span>
              </div>
            )
            : null
        }
      </div>
    )
  }
}
