import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import SkipIcon from 'static/images/minusSkip.svg'
import LikeIcon from 'static/images/heartLike.svg'

import s from './styles.module.scss'

class Card extends Component {
  state = { initialPosition: { x: 0 } }

  setInitialPosition = () => {
    const card = ReactDOM.findDOMNode(this)
    const initialPosition = {
      x: Math.round((this.props.containerSize.x - card.offsetWidth) / 2)
    }

    this.setState({ initialPosition })
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.visibleIndex > 4) return false

    return true
  }

  componentDidMount() {
    this.setInitialPosition()
    window.addEventListener('resize', this.setInitialPosition)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setInitialPosition)
  }

  renderChildren = () => {
    const { active, childCount, children } = this.props

    return React.Children.map(children, c => React.cloneElement(c, {
      active: active.toString(),
      cardcount: childCount
    }))
  }

  renderLikeOverlay = () => {
    const { likeStyle } = this.props

    return (
      <div className={s.likeOverlay} style={likeStyle}>
        <img src={LikeIcon} alt='heart like icon' aria-hidden='true' rel='noreferrer' />
        <span>Like</span>
      </div>
    )
  }

  renderSkipOverlay = () => {
    const { skipStyle } = this.props

    return (
      <div className={s.skipOverlay} style={skipStyle}>
        <img src={SkipIcon} alt='minus skip icon' aria-hidden='true' rel='noreferrer' />
        <span>Skip</span>
      </div>
    )
  }

  handleRef = ref => {
    if (ref && this.props.active) {
      ref.focus()
    }
  }

  render() {
    const { style: propStyles, index, visibleIndex, active, className, onClick } = this.props

    const style = {
      zIndex: index,
      visibility: visibleIndex > 3 ? 'hidden' : 'initial',
      ...propStyles
    }

    return (
      <article
        style={style}
        onClick={onClick}
        ref={this.handleRef}
        tabIndex={active ? 0 : -1}
        onKeyDown={this.props.onKeyDown}
        className={`${className} ${active ? s.topCard : s.inactive} card`}
      >
        {this.renderChildren()}
        {active ? this.renderSkipOverlay() : null}
        {active ? this.renderLikeOverlay() : null}
      </article>
    )
  }
}

Card.defaultProps = {
  className: 'inactive'
}

export default Card
