import { createActionsStructure } from 'services/reduxHelpers'

/* ------------- Action Creators ------------- */

export const { roommateTypes, roommateActions } = createActionsStructure('roommate', [
  { name: 'GET_PROFILE', async: true },
  { name: 'GET_PROFILES', async: true },
  { name: 'DENY_REQUEST', async: true },
  { name: 'ACCEPT_REQUEST', async: true },
  { name: 'CANCEL_REQUEST', async: true },
  { name: 'SEND_REQUEST', async: true },
  { name: 'PUT_ROOMMATE_DATA' },
  { name: 'RESET_REQUEST_ERROR' }
])
