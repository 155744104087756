import { withStyles } from "@material-ui/core";

// Because of the library we need overwrite some styles 
export const GlobalCss = withStyles({
  "@global": {
    ".MuiInput-underline::before, .MuiInput-underline:hover:not(.Mui-disabled):before": {
      content: "none"
    },
    ".MuiInput-underline::after": {
      content: "none"
    },
  }
})(() => null);