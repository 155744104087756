import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import chatSelectors from 'redux/selectors/ChatSelectors'
import { Button } from 'components/Button'

import images from 'static'

import s from './ConversationItem.module.scss'
import Content from './Content'

const ConversationItemView = ({ onClick, conversation }) => {
  const { sid, chatId, attributes: { members } } = conversation

  return (
    <Button
      role="link"
      className={s.container}
      onClick={() => onClick(chatId)}
    >
      <div className={s.content}>
        <Content
          sid={sid}
          memberships={members}
        />
        <img alt="" aria-hidden="true" src={images.arrowRightBlue} className={s.arrow} />
      </div>
      <div className={s.divider} />
    </Button>
  )
}

const mapStateToProps = createStructuredSelector({
  conversation: chatSelectors.conversationSelector
})

const ConversationItem = connect(mapStateToProps)(ConversationItemView)

export { ConversationItem }
