import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from 'redux-modal'
import { connectRouter } from 'connected-react-router'

import home from './HomeReducer'
import user from './UserReducer'
import swipe from './SwipeReducer'
import profile from './ProfileReducer'
import content from './ContentReducer'
import room from './RoomReducer'
import roommate from './RoommateReducer'
import chat from './ChatReducer'
import community from './CommunityReducer'
import settings from './SettingsReducer'

const rootReducer = (history) => combineReducers({
  form: formReducer,
  modal: modalReducer,
  home,
  settings,
  community,
  swipe,
  user,
  room,
  chat,
  roommate,
  profile,
  content,
  router: connectRouter(history),
})

export default rootReducer
