import React from 'react';
import { Helmet as ReactHelmet } from "react-helmet";

import { usePageTitle } from 'hooks/usePageTitle';

export const Helmet = () => {
  const pageTitle = usePageTitle()

  return (
    <ReactHelmet>
      {!pageTitle && (
        <title>RoomSync</title>
      )}
      {pageTitle && (
        <title>{pageTitle} | RoomSync</title>
      )}
    </ReactHelmet>
  );
};
