import { createStyles, makeStyles } from '@material-ui/core/styles'

import EndCallButton from '../Buttons/EndCallButton/EndCallButton'
import Menu from './Menu/Menu'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import { Grid } from '@material-ui/core'
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10
    }
  })
);

export default function MenuBar({ sendMessage, match }) {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              <Menu />
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <EndCallButton sendMessage={sendMessage} match={match} />
            </Grid>
          </Grid>
        </Grid>
      </footer>
  );
}
