import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import { createStructuredSelector } from 'reselect'

import { RoundButton } from 'components/RoundButton'
import { homeActions } from 'redux/actions/HomeActions'
import userSelectors from 'redux/selectors/UserSelectors'
import i18n from 'services/i18n'

import s from './InvitationInfoModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class InvitationInfoModalView extends PureComponent {
  handleConfirm = () => {
    const {
      handleHide, setInvitation, proceedInvitationLoggedIn,
      passcode, isAuthenticated
    } = this.props

    setInvitation({ passcode })
    handleHide()

    if (isAuthenticated) {
      proceedInvitationLoggedIn()
    }
  }

  handleCancel = (url) => {
    const { handleHide, setInvitation } = this.props

    setInvitation({})
    handleHide()
    window.open(url, '_blank')?.focus()
  }

  handleCancel1 = () => {
    this.handleCancel('https://roomsync.zendesk.com/hc/en-us/articles/9176636902292')
  }

  handleCancel2 = () => {
    this.handleCancel('https://roomsync.zendesk.com/hc/en-us/articles/9177016451988')
  }

  handleCancel3 = () => {
    this.handleCancel('https://roomsync.zendesk.com/hc/en-us/articles/9176884312212')
  }

  render() {
    const { handleHide, show, firstName, lastName } = this.props

    return (
      <Modal center open={show} onClose={handleHide} classNames={classNames}>
        <div className={s.container}>
          <span className={s.title}>{i18n.t('invitation.title', { firstName, lastName })}</span>
          <span className={s.description}>
            {i18n.t('invitation.info', { firstName, lastName })}
          </span>
          <div className={s.labels}>
            <RoundButton className={s.confirmButton} onClick={this.handleConfirm}>
              <span className={s.text}>{i18n.t('invitation.confirm', { firstName, lastName })}</span>
            </RoundButton>
            <span className={s.optionLabel} onClick={this.handleCancel1}>{i18n.t('invitation.notMe1')}</span>
            <span className={s.optionLabel} onClick={this.handleCancel2}>{i18n.t('invitation.notMe2')}</span>
            <span className={s.optionLabel} onClick={this.handleCancel3}>{i18n.t('invitation.notMe3')}</span>
          </div>
        </div>
      </Modal>
    )
  }
}

InvitationInfoModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

InvitationInfoModalView.defaultProps = {
  title: 'Are you sure you want to logout?',
  confirmOption: 'Yes',
  cancelOption: 'No'
}

const mapStateToProps = createStructuredSelector({
  isAuthenticated: userSelectors.isAuthenticatedSelector
})

const mapDispatchToProps = {
  proceedInvitationLoggedIn: homeActions.proceedInvitationLoggedIn,
  setInvitation: homeActions.setInvitation
}

const InvitationInfoModalConnected = connect(mapStateToProps, mapDispatchToProps)(InvitationInfoModalView)

const InvitationInfoModal = connectModal({ name: 'invitationInfoModal' })(InvitationInfoModalConnected)

export { InvitationInfoModal }
