import React from 'react';
import MUITabs from '@material-ui/core/Tabs'

import { NoDataPlaceholder } from 'components/NoDataPlaceholder';

import { Tab } from './Tab';
import { TabPanel } from './TabPanel';

import { useTabsStyles } from './styles';

interface IProps {
  rightLabel?: string;
  leftLabel: string;
  middleLabel?: string;
  isLeftChildrenContent?: boolean;
  isRightChildrenContent?: boolean;
  isMiddleChildrenContent?: boolean;
  largeLabels?: boolean;
  bottomDivider?: boolean;
  leftChildren: React.ReactNode;
  rightChildren?: React.ReactNode;
  middleChildren?: React.ReactNode;
  NoDataPlaceholderChildren?: React.ReactNode;
  leftIcon?: string;
  middleIcon?: string;
  rightIcon?: string;
  initialIndex?: number;
  onLabelClick?: (idx: number) => void
}

const a11yProps = (index: any) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
})

export const Tabs: React.FC<IProps> = props => {
  const classes = useTabsStyles()
  const [tabIndex, setTabIndex] = React.useState(props.initialIndex || 0)

  const handleChange = React.useCallback((e: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue)

    if (props.onLabelClick) {
      props.onLabelClick(newValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onLabelClick])

  return (
    <>
      <MUITabs
        centered
        value={tabIndex}
        classes={classes}
        onChange={handleChange}
      >
        <Tab
          label={props.leftLabel}
          icon={props.leftIcon}
          {...a11yProps(0)}
        />
        {!!props.middleLabel && (
          <Tab
            label={props.middleLabel}
            icon={props.middleIcon}
            {...a11yProps(1)}
          />
        )}
        {!!props.rightLabel && (
          <Tab
            label={props.rightLabel}
            icon={props.rightIcon}
            {...a11yProps(props.middleLabel ? 2 : 1)}
          />
        )}
      </MUITabs>
      {props.isLeftChildrenContent && (
        <TabPanel value={tabIndex} index={0}>
          {props.leftChildren}
        </TabPanel>
      )}
      {props.isMiddleChildrenContent && (
        <TabPanel value={tabIndex} index={1}>
          {props.middleChildren}
        </TabPanel>
      )}
      {props.isRightChildrenContent && (
        <TabPanel value={tabIndex} index={props.isMiddleChildrenContent ? 2 : 1}>
          {props.rightChildren}
        </TabPanel>
      )}
      {!props.isLeftChildrenContent && (
        <TabPanel value={tabIndex} index={0}>
          <NoDataPlaceholder>
            {props.NoDataPlaceholderChildren}
          </NoDataPlaceholder>
        </TabPanel>
      )}
      {!props.isRightChildrenContent && (
        <TabPanel value={tabIndex} index={1}>
          <NoDataPlaceholder>
            {props.NoDataPlaceholderChildren}
          </NoDataPlaceholder>
        </TabPanel>
      )}
    </>
  );
};

Tabs.defaultProps = {
  initialIndex: 0,
  isLeftChildrenContent: false,
  isMiddleChildrenContent: false,
  isRightChildrenContent: false,
  largeLabels: false,
  bottomDivider: true
}