import i18n from 'i18n-js'
import includes from 'ramda/es/includes'

import en from './en'
import es from './es'
import zh from './zh'
// import pl from './pl'

i18n.translations = { en, es, zh, /* pl, ru, ua */ }
i18n.fallbacks = false

const fallback = 'en'

const getBaseLanguage = () => {
  try {
    return window?.localStorage?.getItem('lang') || i18n.currentLocale()
  } catch (e) {
    return i18n.currentLocale()
  }
}
const baseLanguage = getBaseLanguage()

const locale = includes(baseLanguage, Object.keys(i18n.translations)) ? baseLanguage : fallback

i18n.locale = locale

export default i18n

export { baseLanguage, getBaseLanguage }
