import {
  put,
  call,
  select,
  all
} from 'redux-saga/effects'
import { show } from 'redux-modal'
import { goBack } from 'connected-react-router'
import { initialize } from 'redux-form'
import isEmpty from 'ramda/es/isEmpty'

import userSelectors from '../selectors/UserSelectors'
import swipeSelectors from '../selectors/SwipeSelectors'
import routeSelectors from '../selectors/RouteSelectors'
import settingsSelectors from '../selectors/SettingsSelectors'
import { swipeActions } from '../actions/SwipeActions'
import { settingsActions } from '../actions/SettingsActions'
import { roommateActions } from '../actions/RoommateActions'
import { getDemoCards } from 'constants/arrays'

import { SEARCH, SWIPE, SAVED_SWIPES } from 'constants/urls'

import API from 'services/api'

export function* getSwipeData(action) {
  const { responseSuccess, responseFailure, data } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const searchValues = yield select(swipeSelectors.sagaNameSelector)
    const isAnyPendingCard = yield select(swipeSelectors.isAnyPendingCardSelector)

    if (isAnyPendingCard) {
      return
    }

    const { res, err } = yield call(API.getPotentialRoommates, accessToken, membershipId, data, searchValues)

    if (res) {
      const isSwipeFirstLook = data.status === 'queued'
        ? yield select(settingsSelectors.isSwipeFirstLookSelector)
        : false

      yield all([
        put(roommateActions.putRoommateData(res.user_profile_cards)),
        put(responseSuccess({
          cards: [...isSwipeFirstLook ? getDemoCards() : [], ...res.user_profile_cards],
          status: data.status || 'queued',
          page: res.page
        }))
      ])
    } else {
      yield put(responseFailure({ status: data.status || 'queued', err }))
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* skipRoommate(action) {
  const { responseSuccess, responseFailure, data } = action
  const responseData = {
    ...data,
    prevStatus: data.status
  }

  if (!data.item.demo) {
    try {
      const membershipId = yield select(userSelectors.membershipIdSelector)
      const accessToken = yield select(userSelectors.accessTokenSelector)

      const { res } = yield call(API.changeRoommateStatus, accessToken, membershipId, data, 'skipped')

      if (res) {
        yield put(responseSuccess(responseData))
      } else {
        yield put(responseFailure(responseData))
      }
      if (data.status === 'queued') {
        yield put(swipeActions.tickBadge({ type: 'skip' }))

        const queuedSwipes = yield select(swipeSelectors.swipeDataSelector, { status: 'queued' })
        if (queuedSwipes.cards.length === 0) {
          yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
        }
      }
    } catch (err) {
      yield put(responseFailure(responseData))
    }
  }
}

export function* likeRoommate(action) {
  const { responseSuccess, responseFailure, data } = action
  if (data.item.demo) {
    yield put(settingsActions.changeSwipeFirstLook())
  } else {
    try {
      const membershipId = yield select(userSelectors.membershipIdSelector)
      const accessToken = yield select(userSelectors.accessTokenSelector)

      const { res } = yield call(API.changeRoommateStatus, accessToken, membershipId, data, 'interested')

      if (res) {
        if (res.status === 'Mutual') {
          yield put(responseSuccess({
            status: 'isMutualInterested',
            item: data.item,
            prevStatus: data.status
          }))

          const {
            percent_match: matchValue,
            profile_picture_urls: avatars,
            membership_id: roommateId,
            first_name: firstName
          } = data.item

          yield put(show('matchModal', {
            avatar: avatars && avatars.large,
            firstName,
            matchValue,
            roommateId
          }))
        } else {
          data.callback && data.callback()
          yield put(responseSuccess({
            status: 'isOneWayInterested',
            item: data.item,
            prevStatus: data.status
          }))
        }
      } else {
        yield put(responseFailure(data))
      }

      if (data.status === 'queued') {
        yield put(swipeActions.tickBadge({ type: 'like' }))

        const queuedSwipes = yield select(swipeSelectors.swipeDataSelector, { status: 'queued' })
        if (queuedSwipes.cards.length === 0) {
          yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
        }
      }
    } catch (err) {
      yield put(responseFailure(data))
    }
  }
}

export function* getSearchValues(action) {
  const { responseSuccess, responseFailure } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getSearchValues, accessToken, membershipId)

    if (res) {
      yield put(responseSuccess({
        sex: res.sex,
        majors: res.majors,
        housing: res.housing,
        sexOptions: res.sex_options,
        majorOptions: res.major_options,
        housingOptions: res.housing_options,
        groupSizeFilter: res.groupSizeFilter,
        maxGroupSize: res.maxNumberOfRoommates || 0,
        enableRoomSizeFilter: res.enableRoomSizeFilter,
        lifestylePreferences: res.lifestyle_preferences,
      }))
    } else {
      yield put(responseFailure())
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* setSearchValues(action) {
  const { responseSuccess, responseFailure, data } = action

  try {
    if (data.fromSearchName) {
      const newSettingsObject = {
        lastName: data.lastName,
        firstName: data.firstName
      }
      yield put(responseSuccess(newSettingsObject))
      yield put(swipeActions.resetSwipeData())
      yield put(initialize('nameSearch', newSettingsObject))
      yield put(goBack())
    } else {
      const initialValues = yield select(swipeSelectors.searchValuesSelector)

      const newSettingsObject = {
        registrationStatus: data.registrationStatus || 'REGISTERED_ONLY',
        groupSizeFilter: data.groupSizeFilter || 0,
        enableRoomSizeFilter: data.enableRoomSizeFilter,
        lifestylePreferences: data.lifestylePreferences || false,
        majors: initialValues.majorOptions ? (data.majors || []).sort((a, b) => a - b) : undefined,
        housing: initialValues.housingOptions ? (data.housing || []).sort((a, b) => a - b) : undefined,
        genderId: initialValues.sexOptions ? (data.sex || 0) : undefined,
        firstName: isEmpty(data) ? '' : undefined,
        lastName: isEmpty(data) ? '' : undefined
      }

      const pathname = yield select(routeSelectors.currentPathnameSelector)
      const membershipId = yield select(userSelectors.membershipIdSelector)
      const accessToken = yield select(userSelectors.accessTokenSelector)
      const { res, code } = yield call(API.setSearchValues, accessToken, membershipId, newSettingsObject)

      if (res && code === 204) {
        yield put(responseSuccess({ ...newSettingsObject, sex: newSettingsObject.genderId }))

        if (pathname.includes(SEARCH) && !data?.goBackDisabled) {
          yield put(swipeActions.resetSwipeData())
          yield put(goBack())
          return
        } else if (pathname.includes(SAVED_SWIPES)) { // reset actions
          yield put(swipeActions.getSwipeData({ status: 'skipped' }))
          yield put(swipeActions.getSwipeData({ status: 'isOneWayInterested' }))
          yield put(swipeActions.getSwipeData({ status: 'isMutualInterested' }))
        } else if (pathname.includes(SWIPE)) {
          yield put(swipeActions.getSwipeData({ status: 'queued', page: 0 }))
        }

        yield put(initialize('preferencesSearch', { ...initialValues, ...newSettingsObject, sex: newSettingsObject.genderId }))

        if (!data?.goBackDisabled) {
          yield put(goBack())
        }
      } else {
        yield put(responseFailure())
      }
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}
