import React, { Fragment, PureComponent } from 'react'

import cn from 'classnames'

import { CommonItem } from 'components'
import Arrow from 'static/images/triangle-arrow.svg'

import s from './Commons.module.scss'

class Commons extends PureComponent {
  state = {
    arrow: null,
    side: 'left'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data.length > 4 && !prevState.arrow) return { arrow: 'right' }

    return prevState
  }

  onArrowClick = side => e => {
    e.stopPropagation()
    this.setState({
      side,
      arrow: side === 'right' ? 'left' : 'right'
    })
  }

  renderItem = item => <CommonItem key={item.id} item={item} small={this.props.smallItems} />

  render() {
    const { data } = this.props
    const { arrow, side } = this.state
    const containerWidth = side === 'left' ? 0 : data.length === 6 ? -200 : -100

    return (
      <Fragment>
        {arrow === 'left' && <img
          src={Arrow}
          className={cn(s.arrow, s.arrowLeft)}
          onClick={this.onArrowClick('left')}
        />}
        <div className={s.root} style={{
          right: data.length < 4 ? 0 : 'initial',
          transform: `translateX(${containerWidth}px)`
        }}>
          <section className={s.container}>
            {data.map(this.renderItem)}
          </section>
        </div>
        {arrow === 'right' && <img
          src={Arrow}
          className={cn(s.arrow, s.arrowRight)}
          onClick={this.onArrowClick('right')}
        />}
      </Fragment>
    )
  }
}

export { Commons }
