import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import path from 'ramda/es/path'
import keys from 'ramda/es/keys'

import { RoommateItem } from 'components'
import images from 'static'

import s from './GroupUsersInfoModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class GroupUsersInfoModalView extends PureComponent {
  render() {
    const { handleHide, show, roommatesData, onRoommateClick } = this.props

    return (
      <Modal center open={show} onClose={handleHide} classNames={classNames}>
        <div className={s.container}>
          <span className={s.title}>Chat</span>
          <div className={s.roommatesWrapper}>
            <div className={s.divider} />
            <ul className={s.list}>
              {keys(roommatesData).map((roommateId, index) => {
                const roommate = roommatesData[roommateId]

                return (
                  <RoommateItem
                    key={String(index)}
                    avatar={path(['profile_picture_urls', 'large'], roommate) || images.defaultAvatar}
                    name={`${roommate.first_name} ${roommate.last_name}`}
                    onClick={() => {
                      handleHide()
                      onRoommateClick(roommateId)
                    }}
                  />
                )
              })}
            </ul>
            <div className={s.dividerBottom} />
          </div>
          <span className={s.optionLabel} onClick={handleHide}>Ok</span>
        </div>
      </Modal>
    )
  }
}

GroupUsersInfoModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

const GroupUsersInfoModal = connectModal({ name: 'groupUsersInfoModal' })(GroupUsersInfoModalView)

export { GroupUsersInfoModal }
