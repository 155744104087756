import { Fragment } from 'react'
import { put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { show } from 'redux-modal'
import pathOr from 'ramda/es/pathOr'

import roomSelectors from '../selectors/RoomSelectors'
import userSelectors from '../selectors/UserSelectors'
import { ToastsStore } from 'react-toasts'
import { roomActions } from '../actions/RoomActions'
import { roommateActions } from '../actions/RoommateActions'

import API from 'services/api'
import { CONVERSATION } from 'constants/urls'
import s from '../../layouts/SignedInLayout/Toast.module.scss'
import { handleToastFocus } from 'helpers/handleToastFocus'

export function* getRoomContent(action) {
  const { responseSuccess, responseFailure, data } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)

    if (!membershipId) {
      return
    }

    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res } = yield call(API.getRoomContent, accessToken, membershipId)

    if (res) {
      yield put(responseSuccess({
        full: res.full,
        title: res.title,
        inSameRoomEnabled: res.inSameRoomEnabled,
        confirmedMatchText: res.confirmed_match_text,
        isInBedroomText: res.is_in_bedroom_text,
        isInSuiteText: res.is_in_suite_text,
        incomingRequests: res.incoming_requests,
        outgoingRequests: res.my_requests,
        myRoommtates: res.my_roommates
      }))
      if (res.my_roommates) {
        yield put(roommateActions.putRoommateData(res.my_roommates))
      }

      if (data && data.openChatOnFinish) {
        const roommatesId = yield select(roomSelectors.roommatesUserIdsSelector)

        if (roommatesId.length === 1) {
          // still 1-1 chat
          const roommateId = roommatesId[0]
          yield put(push(CONVERSATION.replace(':chatId', roommateId)))
        } else {
          // group chat
          const chatId = yield select(roomSelectors.groupChatIdSelector)

          yield put(push(CONVERSATION.replace(':chatId', chatId)))
        }
      }
    } else {
      yield put(responseSuccess({
        title: 'My Room'
      }))
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* leaveRoom(action) {
  const { responseSuccess, responseFailure } = action
  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const { res, err } = yield call(API.leaveRoom, accessToken, membershipId)

    if (res) {
      yield put(roomActions.getRoomContent())
      yield put(responseSuccess())
    } else {
      yield put(responseFailure())

      if (pathOr('', ['error', 'code'], err).includes('locked')) {
        yield put(show(
          'infoModal',
          {
            label: 'Your Match is Locked',
            description: (
              <Fragment>
                <p>
                  This match has been locked by your community’s administrator. Users cannot leave a locked match.
                </p>
                <br />
                <p>
                  If you need further assistance with leaving your current matches, please contact your community.
                </p>
              </Fragment>
            )
          }
        ))
      }
    }
  } catch (err) {
    yield put(responseFailure(err))
  }
}

export function* setRoommate(action) {
  const { responseSuccess, data: { id, isInBedroom } } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)
    const roommate = yield select(roomSelectors.roommateSelector)

    if (roommate && roommate.membership_id === id) {
      // case where you update current roommate
      if (isInBedroom) {
        const { res } = yield call(API.setRoommate, accessToken, membershipId, id)

        if (res) {
          yield put(responseSuccess(res.my_roommates))
        } else {
          ToastsStore.error('An error occurred while selecting the roommate!', 3000, s.toast)
          handleToastFocus()
        }
      } else {
        const { res } = yield call(API.deleteRoommate, accessToken, membershipId, id)

        if (res) {
          yield put(responseSuccess(res.my_roommates))
        } else {
          ToastsStore.error('An error occurred while selecting the roommate!', 3000, s.toast)
          handleToastFocus()
        }
      }
    } else if (roommate) {
      // case where you update another roommate
      if (isInBedroom) {
        yield call(API.deleteRoommate, accessToken, membershipId, roommate.membership_id)

        const { res } = yield call(API.setRoommate, accessToken, membershipId, id)

        if (res) {
          yield put(responseSuccess(res.my_roommates))
        } else {
          ToastsStore.error('An error occurred while selecting the roommate!', 3000, s.toast)
          handleToastFocus()
        }
      }
    } else {
      // case where no roommate yet
      const { res } = yield call(API.setRoommate, accessToken, membershipId, id)

      if (res) {
        yield put(responseSuccess(res.my_roommates))
      } else {
        ToastsStore.error('An error occurred while selecting the roommate!', 3000, s.toast)
        handleToastFocus()
      }
    }
  } catch (err) {
    ToastsStore.error('An error occurred while selecting the roommate!', 3000, s.toast)
    handleToastFocus()
  }
}

export function* setKnown(action) {
  const { data: { id, previouslyKnown } } = action

  try {
    const membershipId = yield select(userSelectors.membershipIdSelector)
    const accessToken = yield select(userSelectors.accessTokenSelector)

    if (previouslyKnown) {
      const { res } = yield call(API.setKnown, accessToken, membershipId, id)

      if (!res) {
        ToastsStore.error('An error occurred while setting the roommate!', 3000, s.toast)
        handleToastFocus()
      }
    } else {
      const { res } = yield call(API.deleteKnown, accessToken, membershipId, id)

      if (!res) {
        ToastsStore.error('An error occurred while setting the roommate!', 3000, s.toast)
        handleToastFocus()
      }
    }
  } catch (err) {
    ToastsStore.error('An error occurred while setting the roommate!', 3000, s.toast)
    handleToastFocus()
  }
}
