import React from 'react'

import Edit from 'static/images/edit.svg'

import { IconButton } from 'components/IconButton'

import s from './EditIcon.module.scss'

const EditIcon = ({ onClick, label }) => (
  <IconButton className={s.container} onClick={onClick} aria-label={label}>
    <img alt="" src={Edit} className={s.editIcon} aria-hidden="true" />
  </IconButton>
)

export { EditIcon }
