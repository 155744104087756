export const COLOR_WHITE = "#ffffff"
export const COLOR_COAL = "#000000"
export const COLOR_GREY = "#dddddd"
export const COLOR_BLUE_LIGHT = "#7594da"
export const COLOR_WEB_ORANGE = "#ffa500"
export const COLOR_RED = "#f00f0f"
export const COLOR_GREY_DARK = "#acacac"
export const COLOR_GREY_DARKER = "#555555"
export const COLOR_GREY_PRIMARY = "#bfbfbf"
export const COLOR_BLUE_DARKER = "#0290e6"
export const COLOR_BITTERSWEET = "#fe6464"
export const COLOR_TRANSPARENT = "transparent"
export const COLOR_BLUE_PRIMARY = "#048de4"
export const COLOR_RED_DARKEST = "#9e0b0e"
export const COLOR_GREEN = "#249b00"
export const COLOR_PURPLE = '#5243aa'

export const COLOR_PRIMARY = COLOR_BLUE_LIGHT
export const COLOR_SECONDARY = COLOR_BITTERSWEET