import cn from "classnames"
import React from 'react';
import FadeIn from 'react-fade-in'

import { PAGE_CONTENT_ID } from 'constants/strings';
import { ActivityIndicator } from 'components/ActivityIndicator'

import s from './MainLayout.module.scss'

interface IProps {
  containerClassName: string;
  isLoading?: boolean;
  blueActivityIndicator?: boolean;
  header?: React.ReactNode;
}

export const MainLayout: React.FC<IProps> = props => (
  <div className={cn(s.container, props.containerClassName)}>
    {!!props.header && (
      <>
        {props.header}
      </>
    )}
    <main
      role="main"
      tabIndex={-1}
      id={PAGE_CONTENT_ID}
      aria-busy={props.isLoading}
    >
      {!!props.isLoading && (
        <ActivityIndicator className={s.indicator} blue={props.blueActivityIndicator} />
      )}
      {!props.isLoading && (
        <FadeIn>
          {props.children}
        </FadeIn>
      )}
    </main>
  </div>
);

MainLayout.defaultProps = {
  isLoading: false,
  blueActivityIndicator: false
}
