import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import Modal from 'react-responsive-modal'
import cn from 'classnames'
import { connect } from 'react-redux'

import { Button } from 'components/Button'
import i18n from 'services/i18n'

import s from './MembershipSelectModal.module.scss'
import { homeActions } from 'redux/actions/HomeActions'

const classNames = { closeButton: s.closeButton, modal: s.modal }

const selectMembershipByStatus = (memberships) => {
  const membership_matching = memberships.filter(membership => membership.matchingRoundStatus === 'MATCHING')
  const membership_active = memberships.filter(membership => membership.matchingRoundStatus === 'ACTIVE')
  const membership_closed = memberships.filter(membership => membership.matchingRoundStatus === 'CLOSED')
  if(membership_matching.length > 0) { return membership_matching[0] }
  if(membership_active.length > 0) { return membership_active[0] }
  if(membership_closed.length > 0) { return membership_closed[0] }
  return memberships[0]
}

class MembershipSelectModalView extends PureComponent {
  state = this.props.selectedMembershipId
    ? this.props.memberships.find(membership => membership.membershipId === this.props.selectedMembershipId)
    : selectMembershipByStatus(this.props.memberships)

  onConfirm = () => {
    const { handleHide, setMembership } = this.props

    setMembership(this.state)
    handleHide()
  }

  onDismiss = () => {
    const { selectedMembershipId, handleHide } = this.props

    if (selectedMembershipId) {
      handleHide()
    }
  }

  render() {
    const { show, title, memberships } = this.props

    return (
      <Modal
        center
        open={show}
        focusTrapped
        classNames={classNames}
        onClose={this.onDismiss}
      >
        <div className={s.container} tabIndex={0}>
          <h2 className={s.title}>{title}</h2>
          {((memberships || [])).map(this.renderMembership)}
          <div className={s.space} />
          <Button className={s.optionLabel} onClick={this.onConfirm}>Ok</Button>
        </div>
      </Modal>
    )
  }

  renderMembership = (membership, index) => {
    const { networkName, subNetworkName, l3Id, networkRoomName, l4Id, l4Name, membershipId } = membership
    const onClick = () => this.setState(membership)

    return (
      <div
        className={
          cn(
            s.item,
            this.state.membershipId === membershipId && s.selected
          )}
        key={index}
        onClick={onClick}
      >
        <div className={s.row}>
          <span className={s.span1}>{i18n.t('profile.network')}</span>
          <span className={s.span2}>{networkName}</span>
        </div>
        <div className={s.divider} />
        <div className={s.row}>
          <span className={s.span1}>{i18n.t('profile.subnetwork')}</span>
          <span className={s.span2}>{subNetworkName}</span>
        </div>
        {
          l3Id !== null
            ? (
              <>
                <div className={s.divider} />
                <div className={s.row}>
                  <span className={s.span1}>{i18n.t('profile.unit')}</span>
                  <span className={s.span2}>{networkRoomName}</span>
                </div>
              </>
            )
            : null
        }
        {
          l4Id !== null && l4Name !== null
            ? (
              <>
                <div className={s.divider} />
                <div className={s.row}>
                  <span className={s.span1}>{i18n.t('profile.room')}</span>
                  <span className={s.span2}>{l4Name}</span>
                </div>
              </>
            ) : null
        }
      </div>
    )
  }
}

MembershipSelectModalView.propTypes = {
  handleHide: PropTypes.func.isRequired
}

MembershipSelectModalView.defaultProps = {
  title: i18n.t('profile.selectMembership'),
}

const mapDispatchToProps = {
  setMembership: homeActions.setMembership
}

const ConnectedModal = connect(null, mapDispatchToProps)(MembershipSelectModalView)

const MembershipSelectModal = connectModal({ name: 'membershipSelectModal' })(ConnectedModal)

export { MembershipSelectModal }
