import { useCallback } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { TooltipWithIcon } from 'components/TooltipWithIcon'
import i18n from 'services/i18n'

import { useSelectStyles, useStyles } from './styles'
import s from './GenderInputSelect.module.scss'

const TOOLTIP_ID = "gender-tooltip-id"
const TOOLTIP_TEXT = i18n.t('gender.info')
const DISABLED_TITLE = i18n.t('gender.lockedSubtitle');
const DISABLED_TOOLTIP_TEXT = i18n.t('gender.lockedInfo');


const GenderInputSelect = ({
  rightAdornment,
  disabled,
  label,
  options,
  input,
  genderExplainer,
  placeholder,
  meta: { touched, error },
  errorMessage
}) => {
  const classes = useStyles()
  const selectClasses = useSelectStyles()
  const isResError = typeof error === 'string'
  const isError = touched && error
  const onChange = useCallback(({ target }) => {
    const item = options.find(({ value }) => value === target.value)

    input.onChange({
      id: item.value,
      name: item.name
    })

    return true
  }, [options])

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <label className={cn(s.label, isError && s.errorLabel)} htmlFor={input.name}>
          {label}
        </label>
        {!disabled &&<TooltipWithIcon
          id={TOOLTIP_ID}
          text={genderExplainer || TOOLTIP_TEXT}
        />}
      </div>
      {rightAdornment && (
        <div className={s.rightAdornment}>
          {rightAdornment}
        </div>
      )}
      <Select
        fullWidth
        disabled={disabled}
        labelId={input.name}
        classes={selectClasses}
        value={input?.value?.id}
        placeholder={placeholder}
        onChange={onChange}
        IconComponent={ExpandMoreIcon}
        inputProps={{ id: input.name, className: cn(isError && classes.error) }}
      >
        {options.map(({ value, name }) => (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        ))}
      </Select>
      {isError && <p className={s.error}>{isResError ? error : errorMessage}</p>}
      {
        disabled && <div className={s.wrapper}>
        <label className={s.disabledLabel}>
          {DISABLED_TITLE}
        </label>
        <TooltipWithIcon
          id={TOOLTIP_ID}
          text={DISABLED_TOOLTIP_TEXT}
        />
      </div>
      }
      
    </div>
  )
}

GenderInputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ).isRequired
}

export { GenderInputSelect }
