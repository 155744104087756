import { createSelector } from 'reselect'
import length from 'ramda/es/length'
import pipe from 'ramda/es/pipe'
import append from 'ramda/es/append'
import sortBy from 'ramda/es/sortBy'
import identity from 'ramda/es/identity'
import join from 'ramda/es/join'
import prop from 'ramda/es/prop'
import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import userSelectors from 'redux/selectors/UserSelectors'

const getState = state => state.room
const isFullSelector = state => getState(state).full
const isLoadingSelector = state => getState(state).isLoading
const isRoomLeaveLoadingSelector = state => getState(state).isRoomLeaveLoading
const isRoommateLoadingSelector = state => getState(state).isRoommateLoading
const confirmedMatchTextSelector = state => getState(state).confirmedMatchText

const roommatesSelector = createSelector(
  getState,
  ({ myRoommtates }) => myRoommtates || []
)

const roommatesIdsSelector = createSelector(
  roommatesSelector,
  map(prop('membership_id'))
)

const roommatesUserIdsSelector = createSelector(
  roommatesSelector,
  pipe(map(prop('user_id')), filter(identity))
)

const incomingRequestsSelector = createSelector(
  getState,
  roommatesIdsSelector,
  ({ incomingRequests }, idsToExlude) => (incomingRequests || []).filter(({ membership_id }) => !idsToExlude.includes(membership_id))
)

const outgoingRequestsSelector = createSelector(
  getState,
  roommatesIdsSelector,
  ({ outgoingRequests }, idsToExlude) => (outgoingRequests || []).filter(({ membership_id }) => !idsToExlude.includes(membership_id))
)

const incomingRequestsCountSelector = createSelector(
  incomingRequestsSelector,
  length
)

const titleSelector = createSelector(
  getState,
  ({ title }) => title
)

const isRoommatesSelector = createSelector(
  getState,
  ({ inSameRoomEnabled }) => !!inSameRoomEnabled
)

const roommateSelector = createSelector(
  getState,
  ({ myRoommtates }) => myRoommtates.find(roommate => roommate.is_in_bedroom === true)
)

const isRoomEmptySelector = createSelector(
  roommatesSelector,
  roommates => !roommates.length
)

const groupChatIdSelector = createSelector(
  userSelectors.userIdSelector,
  roommatesUserIdsSelector,
  pipe(
    append,
    sortBy(identity),
    join('|')
  )
)

export default {
  titleSelector,
  isFullSelector,
  isLoadingSelector,
  roommatesSelector,
  roommatesUserIdsSelector,
  isRoomEmptySelector,
  isRoommatesSelector,
  incomingRequestsSelector,
  outgoingRequestsSelector,
  isRoomLeaveLoadingSelector,
  roommateSelector,
  roommatesIdsSelector,
  isRoommateLoadingSelector,
  incomingRequestsCountSelector,
  confirmedMatchTextSelector,
  groupChatIdSelector
}
