import i18n from 'services/i18n'

import {
  SWIPE,
  MORE,
  HOUSING,
  ABOUT_ME,
  VERIFY_INFO,
  COMMUNITY,
  CHATS,
  QUESTIONS,
  CONVERSATION_ROUTE,
  SET_SOCIAL_ACCOUNTS,
  ROOMMATE_PROFILE_ROUTE,
  SOCIAL_ACCOUNTS,
  REQUEST_ROUTE,
  PROFILE_OVERVIEW,
  SAVED_SWIPES,
  SET_MAJORS,
  PROFILE_PREVIEW,
  UPDATE_BASIC_INFO,
  SEARCH,
  THREAD_ROUTE,
  NEW_POST,
  NO_NETWORK,
  WELCOME,
  DELETE_ACCOUNT,
  LANGUAGES,
  ROOM_INCOMING_REQUETS
} from 'constants/urls'

export const GROUPED_LOGINED_ROUTES = [
  [
    COMMUNITY,
    THREAD_ROUTE,
    NEW_POST
  ],
  [
    CHATS,
    CONVERSATION_ROUTE
  ],
  [
    SWIPE,
    SAVED_SWIPES,
    ROOMMATE_PROFILE_ROUTE,
    SEARCH
  ],
  [
    ROOM_INCOMING_REQUETS
  ],
  [
    MORE,
    HOUSING,
    ABOUT_ME,
    SET_MAJORS,
    DELETE_ACCOUNT,
    SOCIAL_ACCOUNTS,
    PROFILE_PREVIEW,
    PROFILE_OVERVIEW,
    UPDATE_BASIC_INFO
  ]
]

export const GROUPED_CLOSED_ROUTES = [
  [
    COMMUNITY,
    THREAD_ROUTE,
    NEW_POST
  ],
  [
    WELCOME
  ],
  [
    MORE,
    HOUSING,
    ABOUT_ME,
    SET_MAJORS,
    DELETE_ACCOUNT,
    SOCIAL_ACCOUNTS,
    PROFILE_PREVIEW,
    PROFILE_OVERVIEW,
    UPDATE_BASIC_INFO
  ]
]

export const registrationPathnames = [
  QUESTIONS,
  VERIFY_INFO,
  NO_NETWORK
]

export const signedInPathnames = [
  MORE,
  ROOM_INCOMING_REQUETS,
  SWIPE,
  CHATS,
  HOUSING,
  ABOUT_ME,
  COMMUNITY,
  SET_MAJORS,
  SAVED_SWIPES,
  REQUEST_ROUTE,
  DELETE_ACCOUNT,
  PROFILE_PREVIEW,
  SOCIAL_ACCOUNTS,
  UPDATE_BASIC_INFO,
  SET_SOCIAL_ACCOUNTS,
  ROOMMATE_PROFILE_ROUTE
]

export const closedPathnames = [
  MORE,
  CHATS,
  WELCOME,
  HOUSING,
  NEW_POST,
  ABOUT_ME,
  LANGUAGES,
  COMMUNITY,
  SET_MAJORS,
  THREAD_ROUTE,
  DELETE_ACCOUNT,
  SOCIAL_ACCOUNTS,
  PROFILE_PREVIEW,
  PROFILE_OVERVIEW,
  UPDATE_BASIC_INFO,
  CONVERSATION_ROUTE
]

export const getDemoCards = () => [
  {
    demo: true,
    direction: 'right',
    membership_id: 'right_demo',
    title: i18n.t('demo.likeTitle1'),
    desc: i18n.t('demo.likeDesc1'),
    percent_match: '80',
    footerText: i18n.t('demo.likeFooter1'),
  },
  {
    demo: true,
    direction: 'left',
    membership_id: 'left_demo',
    title: i18n.t('demo.skipTitle1'),
    desc: i18n.t('demo.skipDesc1'),
    percent_match: '28',
    footerText: i18n.t('demo.skipFooter1')
  }
]
