import { Card } from 'lib/react-swipe-card'
import cn from 'classnames'
import Lottie from "lottie-react"
import ArrowLeft from 'static/images/arrow-right.svg'
import ArrowRight from 'static/images/arrow-left.svg'
import DemoRightAvatarIcon from 'static/images/demoGirl.svg'
import DemoLeftAvatarIcon from 'static/images/demoBoy.svg'
import skipLottie from './skip.json'
import likeLottie from './like.json'

import always from 'ramda/es/always'
import i18n from 'services/i18n'

import c from './DemoSwipeCard.module.scss'

const DATA = {
  left: {
    avatar: DemoLeftAvatarIcon,
    arrow: ArrowLeft,
    textClassname: c.textRed,
    lottie: skipLottie
  },
  right: {
    avatar: DemoRightAvatarIcon,
    arrow: ArrowRight,
    textClassname: c.textGreen,
    lottie: likeLottie
  }
}

const getDirectionHelp = props =>
  props.data.direction === "left"
    ? i18n.t('demo.toSkip')
    : i18n.t('demo.toLike')

const DemoSwipeCard = (props) => {
  const { data, onSwipeLeft, onSwipeRight, ...other } = props

  return (
    <Card
      className={c.container}
      onSwipeLeft={data.direction === 'left' && onSwipeLeft}
      onSwipeRight={data.direction === 'right' && onSwipeRight}
      onClick={always}
      {...other}
    >
      <h2 className={c.title} aria-hidden={String(!props.active)}>
        {data.title}
      </h2>
      <div className={c.circleWrapper}>
        <div className={c.avatar}>
          <img src={DATA[data.direction].avatar} alt='Avatar placeholder' aria-hidden='true' rel='noreferrer' />
        </div>
        <svg viewBox='0 0 36 36' className={c.circularChart}>
          <path
            className={c.circleGray}
            strokeDasharray={'100, 100'}
            d='M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831'
          />
          <path
            className={cn(c.circle, data.direction === 'left' ? c.red : c.green)}
            strokeDasharray={`${data.percent_match}, 100`}
            d='M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831'
          />
        </svg>
      </div>
      <span className={c.desc} aria-hidden={String(!props.active)}>{data.desc}</span>
      <footer className={c.bottomContentWrapper}>
        <Lottie className={c.lottieAnimation} animationData={DATA[data.direction].lottie} />
        <p
          className={DATA[data.direction].textClassname}
          aria-label={`${data.footerText}. ${getDirectionHelp(props)}`}
          aria-hidden={String(!props.active)}
        >
          {data.footerText}
        </p>
      </footer>
    </Card>
  )
}

export { DemoSwipeCard }
