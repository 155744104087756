import { PureComponent } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { NoDataPlaceholder } from 'components'
import i18n from 'services/i18n'

import s from './RoundedTabs.module.scss'

class RoundedTabs extends PureComponent {
  state = {
    selected: this.props.defaultSelected || 0
  }

  onClick = idx => () => {
    const { onLabelClick } = this.props
    const { selected } = this.state
    if (idx !== selected) {
      this.setState({ selected: idx })
    }
    onLabelClick && onLabelClick(idx)
  }

  render () {
    const { leftLabel, rightLabel, leftChildren, rightChildren } = this.props
    const { selected } = this.state

    return (
      <div className={s.container}>
        <div className={s.tabContainer}>
          <div className={cn(s.tab, selected === 0 && s.selected)} onClick={this.onClick(0)}>
            <span className={cn(s.label, selected === 0 && s.labelSelected)}>{leftLabel}</span>
          </div>
          <div className={cn(s.tab, selected === 1 && s.selected)} onClick={this.onClick(1)}>
            <span className={cn(s.label, selected === 1 && s.labelSelected)}>{rightLabel}</span>
          </div>
        </div>
        <div className={s.contentWrapper}>
          {selected === 0 && leftChildren
            ? leftChildren
            : selected === 1 && rightChildren
              ? rightChildren
              : <NoDataPlaceholder />
          }
        </div>
      </div>
    )
  }
}

RoundedTabs.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  rightChildren: PropTypes.node,
  leftChildren: PropTypes.node
}

RoundedTabs.defaultProps = {
  leftLabel: i18n.t('swipes.oneWay'),
  rightLabel: i18n.t('swipes.mutual'),
  leftChildren: null,
  rightChildren: null
}

export { RoundedTabs }
