
import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form'
import prop from 'ramda/es/prop'

const getState = state => state.swipe

const isLoadingSelector = state => getState(state).isLoading
const searchValuesSelector = state => getState(state).search
const pendingCardsSelector = state => getState(state).pendingCards

const firstNameSelector = state => searchValuesSelector(state).firstName
const lastNameSelector = state => searchValuesSelector(state).lastName
const isSearchLoadingSelector = state => searchValuesSelector(state).isLoading
const isSearchRequestLoadingSelector = state => searchValuesSelector(state).isRequestLoading
const isSearchFetchedLoadingSelector = state => searchValuesSelector(state).isSearchFetched
const formPreferencesValuesSelector = state => getFormValues('preferencesSearch')(state) || {}
const lifestyleSelector = state => searchValuesSelector(state).lifestylePreferences
const enableRoomSizeFilterSelector = state => searchValuesSelector(state).enableRoomSizeFilter
const groupSizeSelector = state => searchValuesSelector(state).groupSizeFilter
const registrationStatusSelector = state => searchValuesSelector(state).registrationStatus
const groupMaxSizeSelector = state => searchValuesSelector(state).maxGroupSize
const skipTickBadgeSelector = state => getState(state).skipTickBadge
const likeTickBadgeSelector = state => getState(state).likeTickBadge

const hasSkipped = state => getState(state).skipped?.cards?.filter(({ demo }) => !demo).length > 0
const hasLiked = state => getState(state).isOneWayInterested?.cards?.filter(({ demo }) => !demo).length > 0
const hasMutual = state => getState(state).isMutualInterested?.cards?.filter(({ demo }) => !demo).length > 0

const isAnyPendingCardSelector = createSelector(
  pendingCardsSelector,
  (pendingCards) => !!pendingCards.length
)

const swipeDataSelector = createSelector(
  (state, { status }) => getState(state)[status],
  content => content
)

const isErrorSelector = state => getState(state).error

const searchMajorsSelector = createSelector(
  searchValuesSelector,
  ({ majors, majorOptions }) => (majors || []).map(majorId => ({
    id: +majorId,
    name: majorOptions.find(({ id }) => String(id) === String(majorId)).name
  }))
)

const sexSelector = createSelector(
  searchValuesSelector,
  ({ sex, sexOptions }) => {
    if (!sexOptions) return null

    if (sex) {
      return Object.keys(sexOptions).find(key => sexOptions[key] === sex)
    }

    return null
  }
)

const searchHousingSelector = createSelector(
  searchValuesSelector,
  ({ housing, housingOptions }) => (housing || [])
    .map(housingId => housingOptions.find(({ id }) => String(id) === String(housingId)))
)

const searchNameSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (firstName, lastName) => (firstName || lastName) && ` ${firstName || ''} ${lastName || ''}`
)

const searchNameValuesSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (firstName, lastName) => ({
    firstName,
    lastName
  })
)

const sagaNameSelector = createSelector(
  firstNameSelector,
  lastNameSelector,
  (firstName, lastName) => {
    const firstNameValue = firstName ? { first_name: firstName } : {}
    const lastNameValue = lastName ? { last_name: lastName } : {}

    return {
      ...firstNameValue,
      ...lastNameValue
    }
  }
)

const isAnySearchSelector = createSelector(
  searchNameSelector,
  searchMajorsSelector,
  searchHousingSelector,
  lifestyleSelector,
  sexSelector,
  enableRoomSizeFilterSelector,
  groupSizeSelector,
  registrationStatusSelector,
  (name, majors, housing, lifestyle, sex, enableRoomSizeFilter, groupSizeSelector, registrationStatus) =>
    housing.length || name || majors.length || lifestyle || sex || enableRoomSizeFilter || groupSizeSelector || registrationStatus !== 'REGISTERED_ONLY'
)

const searchMajorsValuesSelector = createSelector(
  searchValuesSelector,
  formPreferencesValuesSelector,
  ({ majorOptions }, { majors }) => ({
    selectedMajors: majors || [],
    majorOptions
  })
)

const searchHousingValuesSelector = createSelector(
  searchValuesSelector,
  formPreferencesValuesSelector,
  ({ housingOptions }, { housing }) => ({
    selectedHousing: housing || [],
    housingOptions
  })
)

const sexOptionsSelector = createSelector(
  searchValuesSelector,
  prop('sexOptions')
)

const isAnyMySwipesSelector = createSelector(
  searchNameSelector,
  hasSkipped,
  hasLiked,
  hasMutual,
  (isSearchActive, skipped, liked, mutual) => !!isSearchActive && (
    (skipped && 'skipped') ||
    (liked && 'liked') ||
    (mutual && 'mutual')
  )
)

export default {
  sexSelector,
  isErrorSelector,
  sagaNameSelector,
  isLoadingSelector,
  swipeDataSelector,
  lifestyleSelector,
  groupSizeSelector,
  sexOptionsSelector,
  searchNameSelector,
  isAnySearchSelector,
  searchValuesSelector,
  searchMajorsSelector,
  groupMaxSizeSelector,
  pendingCardsSelector,
  skipTickBadgeSelector,
  likeTickBadgeSelector,
  searchHousingSelector,
  isAnyMySwipesSelector,
  isSearchLoadingSelector,
  isAnyPendingCardSelector,
  searchNameValuesSelector,
  registrationStatusSelector,
  searchMajorsValuesSelector,
  searchHousingValuesSelector,
  enableRoomSizeFilterSelector,
  isSearchRequestLoadingSelector,
  isSearchFetchedLoadingSelector
}
