import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connectModal } from 'redux-modal'
import { createStructuredSelector } from 'reselect'
import Modal from 'react-responsive-modal'
import cn from 'classnames'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { roomActions } from 'redux/actions/RoomActions'
import roommateSelectors from 'redux/selectors/RoommateSelectors'
import communitySelectors from 'redux/selectors/CommunitySelectors'
import images from 'static'
import i18n from 'services/i18n'
import { KnownSwitch } from 'components/KnownSwitch'
import { ROOM_INCOMING_REQUETS } from 'constants/urls'

import s from './RoommateSuccessModal.module.scss'

const classNames = { closeButton: s.closeButton, modal: s.modal }

class RoommateSuccessModalView extends PureComponent {
  handleKnownChange = (user) => {
    const { setKnown } = this.props

    const id = user.membershipId || user.membership_id
    const previouslyKnown = !user.previouslyKnown

    setKnown({
      id,
      rootId: id === this.props.user.membershipId ? null : this.props.user.membershipId,
      previouslyKnown
    })
  }

  handleClick = () => {
    const { handleHide, history } = this.props

    handleHide()
    history.push(ROOM_INCOMING_REQUETS)
  }

  render() {
    const { show, myAvatar, userAvatar, description, user, previouslyKnownEnabled } = this.props

    return (
      <Modal center open={show} onClose={this.handleClick} classNames={classNames}>
        <div className={s.container}>
          <img src={images.backgroundSquares} className={s.imageBackground} />
          <img src={userAvatar || images.defaultAvatar} className={s.avatar} rel='noreferrer' />
          <img src={myAvatar || images.defaultAvatar} className={cn(s.avatar, s.right)} rel='noreferrer' />
          <span className={s.title}>{i18n.t('matches.congratulations')}</span>
          <div className={s.info} dangerouslySetInnerHTML={{ __html: description }} />
          {
            previouslyKnownEnabled
              ? (

                <div className={s.knownWrapper}>
                  <KnownSwitch
                    value={!!user.previouslyKnown}
                    label={i18n.t('matches.knowLabelStrong', { name: user.first_name + ' ' + user.last_name })}
                    onClick={() => this.handleKnownChange(user)}
                  />
                  {
                    (user.roommates || []).map(roommate => (
                      <KnownSwitch
                        key={roommate.membership_id}
                        value={roommate.previouslyKnown}
                        label={i18n.t('matches.knowLabelStrong', { name: roommate.first_name + ' ' + roommate.last_name })}
                        onClick={() => this.handleKnownChange(roommate)}
                      />
                    ))
                  }
                </div>
              ) : null
          }
          <div className={s.buttonWrapper} onClick={this.handleClick}>
            <span className={s.label}>Ok</span>
          </div>
        </div>
      </Modal>
    )
  }
}

RoommateSuccessModalView.propTypes = {
  handleHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

const mapStateToProps = createStructuredSelector({
  user: roommateSelectors.userModalSelector,
  previouslyKnownEnabled: communitySelectors.previouslyKnownEnabledSelector
})

const mapDispatchToProps = {
  setKnown: roomActions.setKnown,
}

const ConnectedModal = connect(mapStateToProps, mapDispatchToProps)(withRouter(RoommateSuccessModalView))

const RoommateSuccessModal = connectModal({ name: 'roommateSuccessModal' })(ConnectedModal)

export { RoommateSuccessModal }
