import classnames from 'classnames'
import PropTypes from 'prop-types'
import is from 'ramda/es/is'
import cn from 'classnames'

import { Button } from 'components/Button'
import { Tooltip } from 'components/Tooltip'
import { IconButton } from 'components/IconButton'
import i18n from 'services/i18n'

import LeftArrow from 'static/images/navigation-left-arrow.svg'
import { PAGE_TITLE_ID } from 'constants/strings'

import s from './Header.module.scss'

export const Header = ({
  labelChildren, labelChildrenEnd,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
  label, onBack, onRight, className, onTitleClick,
  leftButtonAriaLabel, rightButtonAriaLabel, rightButtonDisabled,
  RightComponentExtra,
  
}) => (
  <header className={classnames(s.container, onBack && s.active, className)}>
    {onBack && (
      <Tooltip title={leftButtonAriaLabel}>
        <IconButton
          role="link"
          onClick={onBack}
          className={s.button}
          aria-label={leftButtonAriaLabel}
        >
          {is(Function, LeftIconComponent)
            ? <LeftIconComponent />
            : (
              <img
                src={LeftIconComponent}
                className={s.iconBack}
                width={32}
                height={25}
                tabIndex={-1}
                aria-hidden="true"
                alt={leftButtonAriaLabel}
              />
            )}
        </IconButton>
      </Tooltip>
    )}
    <Button
      onClick={onTitleClick}
      disabled={!onTitleClick}
      className={cn(s.labelWrapper, !onTitleClick && s.labelWrapperDisabled)}
      tabIndex={onTitleClick ? 0 : -1}
    >
      {labelChildren}
      <h1 className={s.label} id={PAGE_TITLE_ID}>
        {label}
      </h1>
      {labelChildrenEnd}
    </Button>
    {RightIconComponent && (
      <Tooltip title={rightButtonAriaLabel}>
        <IconButton
          role="link"
          onClick={onRight}
          className={s.button}
          disabled={rightButtonDisabled}
          aria-label={rightButtonAriaLabel}
        >
          {is(Function, RightIconComponent)
            ? <RightIconComponent />
            : (
              <img
                src={RightIconComponent}
                className={s.iconBack}
                width={32}
                height={25}
                tabIndex={-1}
                aria-hidden="true"
                alt={rightButtonAriaLabel}
              />
            )}
        </IconButton>
      </Tooltip>
    )}
    {!!RightComponentExtra && <RightComponentExtra />}
  </header>
)

Header.propTypes = {
  onBack: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
}

Header.defaultProps = {
  label: '',
  onBack: null,
  className: null,
  leftIcon: LeftArrow,
  rightButtonDisabled: false,
  leftButtonAriaLabel: i18n.t('default.prevPage'),
  rightButtonAriaLabel: "",
}
